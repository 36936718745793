import React from "react";
import {FormattedDateOnly} from "./Base/Formatters";
import {Button} from "react-bootstrap";
import LocationStatusBadge from "./LocationStatusBadge";

export class LocationListItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
    }

    edit() {
        this.props.edit(this.props.model.id);
    }

    delete() {
        this.props.delete(this.props.model.id);
    }

    render() {
        const model = this.props.model;
        const referenceData = this.props.referenceData;

        return (
            <div className="order-list-item">
                <div className="location-list-value name" onClick={this.edit}>{model.name}</div>
                <div className="location-list-value status"><LocationStatusBadge
                    referenceData={referenceData} status={model.statusCode}/></div>
                <div className="location-list-value edited">
                    <div>Updated:</div>
                    <div><FormattedDateOnly value={model.dateEdited}/></div>
                </div>
                <div className="location-list-value actions">
                    <div>
                        <Button variant="primary" className="mr-2" onClick={this.edit}>
                            <i className="far fa-edit"/><span className="ml-1">Edit</span>
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                            <i className="far fa-trash-alt"/><span className="ml-1">Delete</span>
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}