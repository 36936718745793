import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../store/Profile";
import {Alert, Button, Card, Container} from "react-bootstrap";
import Loading from "./Base/Loading";
import {UserListItem} from "./UserListItem";
import {LocationNav} from "./LocationNav";

class LocationUsers extends React.Component {
    constructor(props) {
        super(props);

        this.addUser = this.addUser.bind(this);
        this.viewUser = this.viewUser.bind(this);
        this.removeUser = this.removeUser.bind(this);
    }

    componentDidMount() {
        this.props.requestUsers(this.props.getLocationIdFromParams(this.props.match.params));
    }

    addUser() {
        this.props.addUser(this.props.getLocationIdFromParams(this.props.match.params));
    }

    removeUser(id) {
        this.props.removeUser(id, this.props.getLocationIdFromParams(this.props.match.params));
    }

    viewUser(id) {
        this.props.viewUser(id, this.props.getLocationIdFromParams(this.props.match.params));
    }

    render() {
        return (
            <Container className="order-list-container registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    {
                        !this.props.isLoading && this.props.globalErrors && this.props.globalErrors.length > 0 ?
                    <Alert variant="danger">
                        <Alert.Heading>You've got an error!</Alert.Heading>
                        <hr/>
                        {this.props.globalErrors?.map(err => `${err}\n`)}
                    </Alert>
                            : (!this.props.isLoading && this.props.successMessage ?
                    <Alert variant="success">{this.props.successMessage}</Alert>
                            : '')
                    }

                    <Card className="registration-form-wrapper">
                        <Card.Header className="p-0">
                            <LocationNav idLocation={this.props.getLocationIdFromParams(this.props.match.params)}/>
                        </Card.Header>
                        <Card.Body className="registration-form-action-buttons">
                            <div><Button type="submit" variant="primary" onClick={this.addUser}>
                                Add New Location Manager</Button></div>
                        </Card.Body>
                        <Card.Body className="registration-form">

                            <div className="registration-form-separator">
                                <b/>
                                <div className="registration-form-separator-text"><h2>Location Managers</h2></div>
                                <b/>
                            </div>

                            <div className="order-list-items">
                                { this.props.users?.map((user, index) =>
                                    <UserListItem model={user} key={index} referenceData={this.props.referenceData}
                                                  delete={this.removeUser} edit={this.viewUser} />
                                )}

                                {/*<Card className="order-list-item border-0 rounded-0 p-0 m-0"*/}
                                {/*      key={this.props.orderList?.length ?? 0} hidden={!this.props.hasMore}>*/}
                                {/*    <Button variant="secondary" className="btn-load-more"*/}
                                {/*            onClick={this.props.requestOrderListNextPage}><span*/}
                                {/*        className="baseline-text">Load More Users</span><i*/}
                                {/*        className="far fa-plus-square fa-2x"/></Button>*/}
                                {/*</Card>*/}

                            </div>
                        </Card.Body>
                        {/*<Card.Footer className="registration-form-action-buttons">*/}
                        {/*    <div><Button type="submit" variant="primary" onClick={this.addUser}>*/}
                        {/*        Add New Location Manager</Button></div>*/}
                        {/*</Card.Footer>*/}
                    </Card>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(api, dispatch)
)(LocationUsers);