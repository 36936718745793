import {AppValidationException} from "../../api/exceptions";
import {parseErrors, processServerException} from "../apiExceptionFilter";
import {MessageInfo} from "../../api/clients";
import {ActionCreatorAcceptingErrors, BaseErrorsContainer, DispatchableAction} from "./BaseInterfaces";

export interface IResult<T> {
    data?: T;
    success?: boolean;
    Success?: boolean;
    command?: string | undefined;
    redirectUrl?: string | undefined;
    messages?: MessageInfo[] | undefined;
    Messages?: MessageInfo[] | undefined;
}

export type BackendCall<T1,T2> = (model?: T1) => Promise<IResult<T2>>;
export type SuccessCall<T> = (data: IResult<T>) => any;
export type ErrorCall<T> = (messages?: MessageInfo[] | undefined, data?: IResult<T> | undefined) => any;

export const processBackendCall = async <T1, T2>(
    action: BackendCall<T1, T2>, successAction: SuccessCall<T2>, errorAction: ErrorCall<T2>, model?: T1
): Promise<any> => {
    try {
        const data = await action(model);

        if (data.success ?? data.Success) {
            return successAction(data);
        } else {
            return errorAction(data.messages ?? data.Messages, data);
        }
    } catch (e) {
        const appValidationException: AppValidationException = processServerException(e);

        return errorAction(appValidationException.messages)
    }
}

export const processErrors = <T extends BaseErrorsContainer>(action: DispatchableAction<T>, messages?: MessageInfo[] | undefined):
    ActionCreatorAcceptingErrors<T> => {
    const errors: any = {};
    const globalErrors = parseErrors(messages, errors);

    Object.keys(errors).forEach(f => errors[f.charAt(0).toLowerCase() + f.slice(1)] = errors[f]);

    return action({errors, globalErrors, success: false} as T);
}

export const undefinedIfZero = (value: number | undefined): number | undefined => {
    if (value === 0) {
        return undefined;
    }

    return value;
};