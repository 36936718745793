import React from 'react';
import {Route} from 'react-router';
import Layout from './components/Layout';
import Home from './components/Home';
import Catalog from './components/Catalog';
import Order from "./components/Order";
import Content from "./components/Content";
import Store from "./components/Store";
import Users from "./components/Users";
import User from "./components/User";
import Locations from "./components/Locations";
import Location from "./components/Location";
import LocationUsers from "./components/LocationUsers";
import LocationUser from "./components/LocationUser";
import LocationDistributors from "./components/LocationDistributors";
import LocationDistributor from "./components/LocationDistributor";
import LocationFiles from "./components/LocationFiles";
import LocationFile from "./components/LocationFile";
import OrderList from "./components/OrderList";
import OrderDetail from "./components/OrderDetail";
import ActivationForm from "./components/Buyer/ActivationForm";
import RegistrationForm from "./components/Buyer/RegistrationForm";
import ResetPasswordForm from "./components/ResetPasswordForm";
import SwiperCore, {Navigation, Scrollbar, Zoom, Lazy, Keyboard, Pagination} from 'swiper';

SwiperCore.use([Navigation, Scrollbar, Zoom, Lazy, Keyboard, Pagination]);

const app = () => (
    <Layout>
        <Route exact path='/' component={Home}/>
        <Route path='/catalog' component={Catalog}/>
        <Route path='/order' component={Order}/>
        <Route path='/store' component={Store}/>
        <Route exact path='/users' component={Users}/>
        <Route exact path='/users/:id' component={User}/>
        <Route exact path='/locations' component={Locations}/>
        <Route exact path='/locations/:id' component={Location}/>
        <Route exact path='/locations/:id/users' component={LocationUsers}/>
        <Route exact path='/locations/:id/users/:idUser' component={LocationUser}/>
        <Route exact path='/locations/:id/distributors' component={LocationDistributors}/>
        <Route exact path='/locations/:id/distributors/add' component={LocationDistributor}/>
        <Route exact path='/locations/:id/files' component={LocationFiles}/>
        <Route exact path='/locations/:id/files/add' component={LocationFile}/>
        <Route path='/orders' component={OrderList}/>
        <Route path='/order-detail/:id' component={OrderDetail}/>
        <Route path='/content/:url' component={Content}/>
        <Route path='/registration' component={RegistrationForm}/>
        <Route path='/activation' component={ActivationForm}/>
        <Route path='/reset-password' component={ResetPasswordForm}/>
    </Layout>
);

export default app;