import {routerMiddleware, connectRouter} from 'connected-react-router';
import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {reducer as searchReducer} from "./SearchFilter";
import {reducer as searchPopupReducer} from "./SearchPopup";
import {reducer as accountReducer} from "./Account";
import {reducer as catalogReducer} from "./Catalog";
import {reducer as homeReducer} from "./Home";
import {reducer as orderReducer} from "./Orders";
import {reducer as productReducer} from "./ProductDetails";
import {reducer as contentReducer} from "./Content";
import {reducer as profileReducer} from "./Profile";
import {reducer as registrationReducer} from "./Registration";
import createThunkErrorHandlerMiddleware from 'redux-thunk-error-handler';
import {apiExceptionFilter} from "./apiExceptionFilter";

const configure = (history, initialState) => {
    const errorHandler = createThunkErrorHandlerMiddleware({onError: (e) => apiExceptionFilter(e, history)});

    const isDevelopment = process.env.NODE_ENV === 'development';
    
    const middleware = [
        errorHandler,
        ...getDefaultMiddleware({thunk: true, immutableCheck: isDevelopment, serializableCheck: false}),
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
        enhancers.push(window.devToolsExtension());
    }

    return configureStore({
        reducer: {
            profile: profileReducer,
            searchFilter: searchReducer,
            searchPopup: searchPopupReducer,
            account: accountReducer,
            catalog: catalogReducer,
            home: homeReducer,
            order: orderReducer,
            product: productReducer,
            content: contentReducer,
            registration: registrationReducer,
            router: connectRouter(history)
        },
        middleware,
        devTools: isDevelopment,
        enhancers: enhancers,
        preloadedState: initialState
    })
};

export default configure;