import React from "react";
import {Form} from "react-bootstrap";
import {withFormsy} from "formsy-react";
import PropTypes from 'prop-types';
import nextId from "react-id-generator";

class ValidatedFormGroupControl extends React.Component {
    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        
        this.inputId = nextId();
    }

    changeValue(event) {
        this.props.setValue(event.currentTarget.value);

        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    render() {
        return (
            <Form.Group as={this.props.as}>
                <Form.Label htmlFor={this.props.id ?? this.inputId}>{this.props.label}</Form.Label>
                <Form.Control type={this.props.type} id={this.props.id ?? this.inputId} bsPrefix={this.props.bsPrefix}
                              custom={this.props.custom} disabled={this.props.disabled} plaintext={this.props.plaintext}
                              readOnly={this.props.readOnly} size={this.props.size} onChange={this.changeValue}
                              isInvalid={this.props.showErrors && (this.props.showError || this.props.showRequired)}
                              value={this.props.value || ''} autoComplete={this.props.autoComplete} checked={this.props.checked} />
                <Form.Control.Feedback type="invalid">{this.props.errorMessage}</Form.Control.Feedback>
            </Form.Group>
        );
    }
}

ValidatedFormGroupControl.propTypes = {
    ...Form.Control.propTypes,
    label: PropTypes.string,
    showErrors: PropTypes.bool
};

export default withFormsy(ValidatedFormGroupControl);