import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {
    ProductsClient,
    ProductGridItemModel, ProductDetailsCalculateModel,
} from "../api/clients";
import {BaseErrorsContainer} from "./Base/BaseInterfaces";
import {processBackendCall, processErrors} from "./Base/Backend";
import * as Enumerable from "linq-es2015";

interface ProductMap {
    [idSku: number]: ProductGridItemModel;
}

interface state extends BaseErrorsContainer {
    orderProducts: ProductMap | undefined,
    orderHistoryProducts: ProductMap | undefined,
    isLoading: boolean
}

const initialState: state = {
    errors: null,
    success: true,
    globalErrors: undefined,
    orderProducts: undefined,
    orderHistoryProducts: undefined,
    isLoading: false
};

type ProductsViewEnum = "order" | "orderHistory";

export const productsView : {
    order: ProductsViewEnum,
    orderHistory: ProductsViewEnum
} = {
    order: "order",
    orderHistory: "orderHistory"
};

const catalogSlice = createSlice({
    name: "product",
    initialState: initialState,
    reducers: {
        requestProduct: (state: Draft<state>) => {
            state.isLoading = true;
        },
        receiveProduct: (state: Draft<state>, action: PayloadAction<[ProductGridItemModel[] | undefined, ProductsViewEnum]>) => {
            const [products, view] = action.payload;
            const productMap = {} as ProductMap;

            Enumerable.From(products ?? []).ToArray().map(p => productMap[p.skuId ?? 0] = p);

            switch (view) {
                case productsView.order: state.orderProducts = productMap; break;
                case productsView.orderHistory: state.orderHistoryProducts = productMap; break;
            }

            state.isLoading = false;
        },
        receiveErrors: (state: Draft<state>, action) => {
            state.errors = action.payload.errors;
            state.globalErrors = action.payload.globalErrors;
            state.isLoading = false;
        },
        clearErrors: (state: Draft<state>) => {
            state.globalErrors = undefined;
        }
    }
});

export const {reducer} = catalogSlice;

const {actions} = catalogSlice;

export const api = {
    ...actions,
    requestProducts: (idOrder: number | undefined, idLocation: number | undefined, skuIds: number[], view: ProductsViewEnum) => async (dispatch: any) => {
        dispatch(actions.requestProduct());

        const client = new ProductsClient();
        const filter = new ProductDetailsCalculateModel();
        filter.idOrder = idOrder;
        filter.idLocation = idLocation;
        filter.skuIds = skuIds;

        return await processBackendCall(
            () => client.getProductDetails(filter),
            productResult => dispatch(actions.receiveProduct([productResult.data, view])),
            messages => dispatch(processErrors(error => actions.receiveErrors(error), messages))
        );
    }
};