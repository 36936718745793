import React from "react";
import * as B from "react-bootstrap";
import {NavLink as RRNavLink} from "react-router-dom";
import PropTypes from "prop-types";

class Footer extends React.Component {
    render() {
        return (
            <footer className={this.props.className}>
                <B.Container>
                    <B.Navbar className="flex-column" bg="light">
                        <p className="text-center">Product information on Turnpike is provided by the manufacturers of
                            the products on the site. Furthermore, iLevel Brands does not guarantee the accuracy of
                            information provided on Turnpike. Product descriptions, ingredients, and prices are subject
                            to change without notice and without the change being reflected on the site. This website
                            contains information from third party entities, and iLevel Brands is not responsible for the
                            actions or failures of those third parties.</p>
                        <B.Nav className="flex-row">
                            <B.Nav.Item>
                                <B.Nav.Link as={RRNavLink} activeClassName="active"
                                            to="/content/about">ABOUT</B.Nav.Link>
                            </B.Nav.Item>
                            <B.Nav.Item>
                                <B.Nav.Link as={RRNavLink} activeClassName="active"
                                            to="/content/contact">CONTACT</B.Nav.Link>
                            </B.Nav.Item>
                        </B.Nav>
                        <div>Copyright 2020 © <strong>iLevel Brands</strong></div>
                    </B.Navbar>
                </B.Container>
            </footer>
        );
    }
}

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;