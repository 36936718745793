import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {BaseSupplierListItemOfBaseWarehouseListItem, BaseWarehouseListItem} from "../api/clients";
import {api as searchFilterApi, SearchStateStored, selectedDistributorWarehousesArrayResolver} from './SearchFilter';

interface SearchPopupState {
    showPopup: boolean,
    selectedDistributor: number,
    selectedWarehouse: number,
    distributors: BaseSupplierListItemOfBaseWarehouseListItem[],
    warehouses: BaseWarehouseListItem[]
}

const initialState: SearchPopupState = {
    showPopup: false,
    selectedDistributor: 0,
    selectedWarehouse: 0,
    distributors: [],
    warehouses: []
}

const updateState = {
    changeDistributor: (state: SearchPopupState, selectedDistributor: number | undefined) => {
        if (state.selectedDistributor !== selectedDistributor) {
            state.selectedWarehouse = 0;
        }

        [
            state.selectedDistributor,
            state.selectedWarehouse,
            state.distributors,
            state.warehouses
        ] = selectedDistributorWarehousesArrayResolver(
            [selectedDistributor, state.selectedWarehouse, state.distributors]);
    },
    changeWarehouse: (state: SearchPopupState, selectedWarehouse: number) => {
        state.selectedWarehouse = selectedWarehouse;
    }
};

const searchPopupSlice = createSlice({
    name: "searchPopup",
    initialState: initialState,
    reducers: {
        hidePopup: (state: Draft<SearchPopupState>) => {
            state.showPopup = false;
        },
        receivePopupData: (state: Draft<SearchPopupState>, action: PayloadAction<[SearchStateStored | undefined,
            BaseSupplierListItemOfBaseWarehouseListItem[] | undefined
        ]>) => {

            const [popupState, distributors] = action.payload;

            if (distributors !== undefined) {
                state.distributors = distributors;
            }

            if (popupState) {
                updateState.changeDistributor(state, popupState.selectedDistributor);

                if (state.selectedDistributor > 0 && popupState.selectedWarehouse) {
                    updateState.changeWarehouse(state, popupState.selectedWarehouse);
                }
            } else {
                state.showPopup = true;
            }
        },
        changeDistributor: (state: Draft<SearchPopupState>, action: PayloadAction<number>) => {
            updateState.changeDistributor(state, action.payload);
        },
        changeWarehouse: (state: Draft<SearchPopupState>, action: PayloadAction<number>) => {
            updateState.changeWarehouse(state, action.payload);
        }
    }
});

const {actions} = searchPopupSlice;

export const {reducer} = searchPopupSlice;

export const api = {
    ...actions,
    sendPopupResultData: (selectedDistributor: number, selectedWarehouse: number) => (dispatch: any) => {
        return dispatch(searchFilterApi.receivePopupData({selectedDistributor, selectedWarehouse} as SearchStateStored));
    },
    sendPopupResultDataThenRunSearch: (selectedDistributor: number, selectedWarehouse: number) => (dispatch: any) => {
        return dispatch(searchFilterApi.receivePopupDataThenRunSearch({selectedDistributor, selectedWarehouse} as SearchStateStored));
    },
};
