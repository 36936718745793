import React from 'react';
import {
    Button,
    Card,
    Container,
    Form, 
    Modal,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import DateInput from "./Base/DatePicker";
import {FormattedCurrency, FormattedDateOnly, FormattedPercent} from "./Base/Formatters";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api as ordersApi} from "../store/Orders";
import PropTypes from "prop-types";
import {OrderViewModel} from "../api/clients";
import ProductDetails from "./Order/ProductDetails";
import Loading from "./Base/Loading";
import {defaultSearchFilter} from "../store/SearchFilter";
import {scroller, Element} from 'react-scroll';
import * as Enumerable from "linq-es2015";

function ModalCard(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide ?? (() => {})}
            size={props.size ?? 'xl'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
        >
            {props.children}
        </Modal>
    );
}

function ConfirmationPopup(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide ?? (() => {})}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
        >
            {props.children}
        </Modal>
    );
}

function ProductItem(props) {
    const {order, productView, products, updateQuantity, updateNewPlacement, deleteItem, errors} = props;

    let product = undefined;
    let quantity = undefined;
    let acceptedNp = undefined;
    let price = undefined;
    let skuMessages = undefined;
    
    if (productView?.idSku && products) {
        product = products[productView?.idSku];
        price = productView?.extCost / (productView.quantity ?? 1)
    }
    if (order.positions) {
        const positions = order.positions.filter(p => p.idSku === productView?.idSku);
        if (positions && positions.length > 0) {
            quantity = positions[0].quantity;
            acceptedNp = positions[0].acceptedNewPlacementDeal;
            skuMessages = positions[0].messages;
        }
    }
    
    quantity = quantity ?? '';

    acceptedNp = acceptedNp ?? false;

    skuMessages = skuMessages ?? [];
    let skuMessage = '';
    
    if (skuMessages.length > 0) {
        skuMessage = Enumerable.From(skuMessages).Select(x => x.message).ToArray().join("\n");
    }

    product = product ?? {};

    return (
        <div className="order-product-item">
            <div className="product-heading">
                <div>
                    <div className="order-brand-name text-muted">
                        {productView?.brandName}
                    </div>
                    <div className="order-product-name">
                        <button type="button" onClick={() => props.onShow(productView?.idSku)}
                                className="button-reset button-as-link">{productView?.productName}</button>
                    </div>
                </div>
            </div>
            <div className="order-product-item-line">
                <ModalCard
                    show={props.show.get(productView?.idSku)}
                    onHide={() => props.onHide(productView?.idSku)}
                >
                    <ProductDetails product={product} onHide={() => props.onHide(productView?.idSku)}/>
                </ModalCard>
                <div className="product-image" onClick={() => props.onShow(productView?.idSku)}>
                    <div className="image-container">
                        <img alt="" src={product?.thumbnail}/>
                    </div>
                </div>
                <div className="order-product-details order-field-font">
                    <div className="font-weight-bold">{productView?.skuCode}</div>
                    <div className="font-weight-bold">{productView?.supplierProductFieldValue}</div>
                    <div><span className="font-weight-bold">Size:</span> {productView?.size}</div>
                    <div><span className="font-weight-bold">UOM:</span> {productView?.uOMName}</div>
                    <div><span className="font-weight-bold">Unit Type:</span> {productView?.unitTypeName}</div>
                    <div><span className="font-weight-bold">Pack Size:</span> {productView?.packSize}</div>
                    <div><span className="font-weight-bold">SRP:</span> <FormattedCurrency
                        value={productView?.retailPrice}/></div>
                    <div><span className="font-weight-bold">WHS:</span> <FormattedCurrency
                        value={productView?.wholesalePrice}/></div>
                </div>
                <div className="order-product-line-wrapper">
                    <div className="discount-buttons">
                        {product?.hasCaseStack ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-CS">{product?.details?.csDescriptions?.map((item, index) => {
                                    return (
                                        <p key={index}>{item}</p>
                                    );
                                })}</Tooltip>}>
                                <Button variant="primary" size="sm"
                                        className="card-CS discount-button">CS</Button>
                            </OverlayTrigger>
                            : null}
                        {product?.hasNp ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-NP">{product?.details?.npDescription}</Tooltip>}>
                                <Button variant="dark" size="sm"
                                        className="card-NP discount-button">NP</Button>
                            </OverlayTrigger>
                            : null}
                        {productView?.resultOIPercent > 0 ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-OI">{product?.details?.oiDescription}</Tooltip>}>
                                <Button variant="success" size="sm"
                                        className="card-OI discount-button">OI:<FormattedPercent
                                    value={productView?.resultOIPercent / 100}/></Button>
                            </OverlayTrigger>
                            : null}
                        {productView?.resultMCBPercent > 0 ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-MCB">{productView?.hasNpOverridenMcb ? product?.details?.npDescription : product?.details?.mcbDescription}</Tooltip>}>
                                <Button variant="danger" size="sm"
                                        className="card-MCB discount-button">MCB:<FormattedPercent
                                    value={productView?.resultMCBPercent / 100}/></Button>
                            </OverlayTrigger>
                            : null}
                        {productView?.resultRBPPercent > 0 ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-RBP">{product?.details?.rbpDescription}</Tooltip>}>
                                <Button variant="info" size="sm"
                                        className="card-RBP discount-button">RBP:<FormattedPercent
                                    value={productView?.resultRBPPercent / 100}/></Button>
                            </OverlayTrigger>
                            : null}
                    </div>
                    <div className="order-product-price-wrapper order-field-font">
                        <div className="order-product-price">
                            <Form.Group>
                                <Form.Label>Price</Form.Label>
                                <Form.Text as="span"><FormattedCurrency value={price}/></Form.Text>
                            </Form.Group>
                        </div>
                        <div className="order-product-qty">
                            <Form.Group>
                                <Form.Label>Quantity</Form.Label>
                                <Element as="span" name={`scroll_orderForm.k${productView?.orderIndex}.Quantity`} />
                                <Form.Control type="number" className="form-control-sm"
                                              value={quantity}
                                              onChange={event => updateQuantity(productView?.idSku, event.currentTarget.value)}
                                        isInvalid={!!(errors ? errors[`orderForm.k${productView?.orderIndex}.Quantity`] : undefined)}/>
                                <Form.Control.Feedback type="invalid">{errors ? errors[`orderForm.k${productView?.orderIndex}.Quantity`] : undefined}</Form.Control.Feedback>
                            </Form.Group>
                        </div>
                        <div className="order-product-extprice">
                            <Form.Group>
                                <Form.Label>ExtCost</Form.Label>
                                <Form.Text as="span"><FormattedCurrency
                                    value={productView?.extCost}/></Form.Text>
                            </Form.Group>
                        </div>
                        <div className="order-product-remove">
                            <Button type="button" variant="danger" onClick={() => deleteItem(productView?.idSku)}>
                                <i className="fas fa-trash"/></Button>
                        </div>
                    </div>
                    <div className="order-product-new-placement order-field-font">
                        {product?.hasNp ?
                            <Form.Label>
                                <Form.Check id={`acceptNp_${productView?.idSku}`} name="acceptNp" checked={acceptedNp} onChange={event => updateNewPlacement(productView?.idSku, event.currentTarget.checked)}
                                            isInvalid={skuMessages.length > 0} label="Accept New Placement Deal" feedback={skuMessage} />
                            </Form.Label>
                            : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

function hasSupplierError(errors, fieldName, index) {
    return !!(errors ? errors[`orderForm.i${index}.${fieldName}`] : undefined);
}

function getSupplierError(errors, fieldName, index) {
    return errors ? errors[`orderForm.i${index}.${fieldName}`] : undefined;
}

function WarehouseSection(props) {
    const {group, supplier, order, products, orderViewModel, updateSupplierRep, updateAccountNumber, updatePurchaseOrderNumber, updateCustomerNotes, updateQuantity, updateNewPlacement, deleteItem, supplierIndex} = props;

    return (
        <div className="order-warehouse-section">
            <div className="order-separator">
                <b/>
                <div className="order-separator-text"><h2>Start Order #{props.orderIndex}</h2></div>
                <b/>
            </div>
            <div className="order-warehouse-header">
                <div className="order-warehouse-name">
                    <div className="font-weight-bold">Distributor: <span
                        className="text-primary">{supplier.supplierName}</span></div>
                    <div className="font-weight-bold">Warehouse: <span
                        className="text-primary">{group.warehouseName}</span></div>
                </div>
                <div className="order-subtotals font-weight-bold">
                    Order Total: <FormattedCurrency value={group.totalDiscounted}/>
                </div>
            </div>
            <div className="order-notice">*Totals reflect MCB and OI discounts. <wbr/>Totals may not include
                volume discounts offered by a Distributor. <wbr/>Discounts are subject to change without
                notice.
            </div>
            <div className="order-warehouse-fields">
                <Form.Group>
                    <Form.Label>
                        Distributor Rep
                    </Form.Label>
                    <Element as="span" name={`scroll_orderForm.i${supplierIndex}.SupplierRep`} />
                    <Form.Control type="text" value={supplier.supplierRep ?? ''}
                                  onChange={event => updateSupplierRep(supplier?.idSupplier, event.currentTarget.value)}
                                  isInvalid={hasSupplierError(props.errors, 'SupplierRep', supplierIndex)}/>
                    <Form.Control.Feedback type="invalid">{getSupplierError(props.errors, 'SupplierRep', supplierIndex)}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        Account <span className="text-danger">*</span>
                    </Form.Label>
                    <Element as="span" name={`scroll_orderForm.i${supplierIndex}.AccountNumber`} />
                    <Form.Control autoFocus={true} type="text" value={supplier.accountNumber ?? ''}
                                  onChange={event => updateAccountNumber(supplier?.idSupplier, event.currentTarget.value)}
                                  isInvalid={hasSupplierError(props.errors, 'AccountNumber', supplierIndex)}/>
                    <Form.Control.Feedback type="invalid">{getSupplierError(props.errors, 'AccountNumber', supplierIndex)}</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                    <Form.Label>
                        PO Number
                    </Form.Label>
                    <Element as="span" name={`scroll_orderForm.i${supplierIndex}.PurchaseOrderNumber`} />
                    <Form.Control type="text" value={group.purchaseOrderNumber ?? ''}
                                  onChange={event => updatePurchaseOrderNumber(group?.idWarehouse, event.currentTarget.value)}
                                  isInvalid={hasSupplierError(props.errors, 'PurchaseOrderNumber', supplierIndex)}/>
                    <Form.Control.Feedback type="invalid">{getSupplierError(props.errors, 'PurchaseOrderNumber', supplierIndex)}</Form.Control.Feedback>
                </Form.Group>
            </div>
            <div className="order-warehouse-fields">
                <Form.Group>
                    <Form.Label>
                        Order Notes
                    </Form.Label>
                    <Element as="span" name={`scroll_orderForm.i${supplierIndex}.CustomerNotes`} />
                    <Form.Control as="textarea" rows="3" value={group.customerNotes ?? ''}
                                  onChange={event => updateCustomerNotes(group?.idWarehouse, event.currentTarget.value)}
                                  isInvalid={hasSupplierError(props.errors, 'CustomerNotes', supplierIndex)}/>
                    <Form.Control.Feedback type="invalid">{getSupplierError(props.errors, 'CustomerNotes', supplierIndex)}</Form.Control.Feedback>
                </Form.Group>
            </div>
            <div className="order-product-list">
                {group.positions?.map((productView, index) => {
                    return (
                        <ProductItem key={index} products={products} productView={productView} order={order}
                                     orderViewModel={orderViewModel} supplier={supplier}
                                     group={group} show={props.show} onHide={props.onHide} onShow={props.onShow}
                                     deleteItem={deleteItem} updateQuantity={updateQuantity} updateNewPlacement={updateNewPlacement} errors={props.errors}/>
                    );
                })}
            </div>
        </div>
    );
}

class Order extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productModalMap: new Map(),
            showSubmitConfirmationPopup: false,
            showCancelConfirmationPopup: false
        };
        
        this.showProductDetails = this.showProductDetails.bind(this);
        this.setModalHide = this.setModalHide.bind(this);
        this.setStartDate = this.setStartDate.bind(this);
        this.updateCustomAuthorizedBy = this.updateCustomAuthorizedBy.bind(this);
        this.updateSupplierRep = this.updateSupplierRep.bind(this);
        this.updateAccountNumber = this.updateAccountNumber.bind(this);
        this.updatePurchaseOrderNumber = this.updatePurchaseOrderNumber.bind(this);
        this.updateCustomerNotes = this.updateCustomerNotes.bind(this);
        this.updateQuantity = this.updateQuantity.bind(this);
        this.updateNewPlacement = this.updateNewPlacement.bind(this);
        this.deleteItem = this.deleteItem.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.submitOrder = this.submitOrder.bind(this);
        this.cancelOrder = this.cancelOrder.bind(this);
        this.closeSubmitPopup = this.closeSubmitPopup.bind(this);
        this.closeCancelPopup = this.closeCancelPopup.bind(this);
        this.submitOrderHandler = this.submitOrderHandler.bind(this);
        this.cancelOrderHandler = this.cancelOrderHandler.bind(this);
        this.scrollToFirstError = this.scrollToFirstError.bind(this);
        this.closeInfoPopup = this.closeInfoPopup.bind(this);
        this.goToCatalog = this.goToCatalog.bind(this);
    }

    componentDidMount() {
        const filter = this.props.searchFilter ?? defaultSearchFilter;
        this.props.requestOrder(filter.idActiveOrder, filter.idLocation, true);
    }

    showProductDetails(id) {
        this.state.productModalMap.set(id, true);
        this.setState(state => state);
    }

    setModalHide(id) {
        this.state.productModalMap.set(id, false);
        this.setState(state => state);
    }

    setStartDate(date) {
        this.props.setStartDate(date);
    }

    updateCustomAuthorizedBy(event) {
        this.props.updateCustomAuthorizedBy(event.currentTarget.value);
    }

    updateSupplierRep(idSupplier, supplierRep) {
        this.props.updateSupplierRep([idSupplier, supplierRep]);
    }

    updateAccountNumber(idSupplier, accountNumber) {
        this.props.updateAccountNumber([idSupplier, accountNumber]);
    }

    updatePurchaseOrderNumber(idWarehouse, purchaseOrderNumber) {
        this.props.updatePurchaseOrderNumber([idWarehouse, purchaseOrderNumber]);
    }

    updateCustomerNotes(idWarehouse, customerNotes) {
        this.props.updateCustomerNotes([idWarehouse, customerNotes]);
    }

    updateQuantity(idSku, quantity) {
        if (quantity) {
            this.props.updateQuantity([idSku, parseInt(quantity)]);
        } else {
            this.props.updateQuantity([idSku, undefined]);
        }
    }

    updateNewPlacement(idSku, checked) {
        this.props.updateNewPlacement([idSku, checked]);
    }

    deleteItem(idSku) {
        this.props.deleteItem(idSku);
    }

    saveOrder() {
        this.props.updateOrder(this.props.orderManageModel);
    }

    cancelChanges() {
        this.props.reloadOrder(this.props.orderManageModel);
    }

    submitOrder() {
        this.setState(() => {
            return {showSubmitConfirmationPopup: true};
        });
    }
    
    submitOrderHandler() {
        this.setState(() => {
            return {showSubmitConfirmationPopup: false};
        });
        this.props.submitOrder(this.props.orderManageModel);
    }

    closeSubmitPopup() {
        this.setState(() => {
            return {showSubmitConfirmationPopup: false};
        });
    }

    cancelOrder() {
        this.setState(() => {
            return {showCancelConfirmationPopup: true};
        });
    }
    
    cancelOrderHandler() {
        this.setState(() => {
            return {showCancelConfirmationPopup: false};
        });
        this.props.cancelOrder(this.props.orderManageModel);
    }

    closeCancelPopup() {
        this.setState(() => {
            return {showCancelConfirmationPopup: false};
        });
    }

    closeInfoPopup() {
        this.props.goToHomePage();
    }

    scrollToFirstError(errors) {
        if (errors) {
            const keys = Object.keys(errors);
            if (keys && keys.length > 0) {
                scroller.scrollTo(`scroll_${keys[0]}`, {offset: -200, smooth: true});
            }
        }
    }

    goToCatalog() {
        this.props.goToCatalog();
    }
    
    render() {
        const {orderManageModel: order, currentOrder: orderViewModel} = this.props;
        const salesRep = orderViewModel?.availableSalesReps?.filter(srep => srep.id === order?.idSalesRep)[0]?.fullName;
        const locationAddress = orderViewModel?.locationAddress;
        let orderIndex = 0;
        
        if (this.props.errors) {
            setTimeout(() => this.scrollToFirstError(this.props.errors));
        }

        return (
            <>
                <Container className="order-container">
                    <Loading active={this.props.isLoading} normalOverlay={true}>
                        <Card className="no-border-radius">
                            <Card.Body>
                                <div hidden={this.props.isLoading || (orderViewModel?.suppliers?.length ?? 0) !== 0} className="order-empty-notice">
                                    <h1>Your order is currently empty.</h1>
                                    <Button variant="primary" className="btn-large" onClick={this.goToCatalog}>Start a New Order</Button>
                                </div>
                                <Form hidden={(orderViewModel?.suppliers?.length ?? 0) === 0}>
                                    <div className="order-heading">
                                        <div className="order-section">
                                            <div className="left order-static-field-group">
                                                <div className="order-static-field">Created Date: <span
                                                    className="value"><FormattedDateOnly
                                                    value={orderViewModel?.dateCreated}/></span></div>
                                                <div className="order-static-field">Order: <span
                                                    className="value">{orderViewModel?.id}</span></div>
                                                <div className="order-static-field">Account: <span
                                                    className="value">{orderViewModel?.buyerName}</span>
                                                </div>
                                                <div className="order-static-field">Location: <span
                                                    className="value">{orderViewModel?.locationName}</span>
                                                </div>
                                                <div className="order-static-field" hidden={!salesRep}>Sales Rep:
                                                    <span className="value">{salesRep}</span>
                                                </div>
                                            </div>
                                            <div className="right order-static-field-group">
                                                <div
                                                    className="order-static-field">{orderViewModel?.billingName} ({orderViewModel?.buyerEmail})
                                                </div>
                                                <div className="order-static-field">{locationAddress?.address1}</div>
                                                <div className="order-static-field"
                                                     hidden={!locationAddress?.address2}>{locationAddress?.address2}</div>
                                                <div
                                                    className="order-static-field">{locationAddress?.city}, {locationAddress?.stateName} {locationAddress?.zip}</div>
                                                <div className="order-static-field"
                                                     hidden={!locationAddress?.phone}>Phone: {locationAddress?.phone}</div>
                                                <div className="order-static-field"
                                                     hidden={!locationAddress?.fax}>Fax: {locationAddress?.fax}</div>
                                            </div>
                                        </div>
                                        <div className="order-section">
                                            <div className="order-edit-fields">
                                                <Form.Group className="form-group-md">
                                                    <Form.Label>Authorized Buyer <span className="text-danger">*</span></Form.Label>
                                                    <Element as="span" name="scroll_CustomAuthorizedBy" />
                                                    <Form.Control type="text" className="form-control-md"
                                                                  value={order?.customAuthorizedBy ?? ''}
                                                                  onChange={this.updateCustomAuthorizedBy} isInvalid={this.props.errors?.CustomAuthorizedBy}/>
                                                    <Form.Control.Feedback type="invalid">{this.props.errors?.CustomAuthorizedBy}</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Element as="span" name="scroll_StartDate" />
                                                    <DateInput value={order?.startDate} onChange={this.setStartDate}
                                                               className="form-control-md" isInvalid={this.props.errors?.StartDate}/>
                                                    <Form.Control.Feedback type="invalid">{this.props.errors?.StartDate}</Form.Control.Feedback>
                                                </Form.Group>
                                            </div>
                                            <div className="order-subtotals mb-2">
                                                <div className="font-weight-bold">Combined Total Before
                                                    Discounts*: <FormattedCurrency value={orderViewModel?.total}/></div>
                                                <div className="font-weight-bold">Combined Total After
                                                    Discounts*: <FormattedCurrency
                                                        value={orderViewModel?.totalDiscounted}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {orderViewModel?.suppliers?.map((supplier, index) => {
                                        return supplier.groups?.map((group) => {
                                            orderIndex++;
                                            return (
                                                <WarehouseSection key={orderIndex} products={this.props.products}
                                                                  order={order}
                                                                  orderViewModel={orderViewModel}
                                                                  supplier={supplier} group={group}
                                                                  supplierIndex={index}
                                                                  show={this.state.productModalMap}
                                                                  onHide={this.setModalHide}
                                                                  onShow={this.showProductDetails}
                                                                  orderIndex={orderIndex}
                                                                  deleteItem={this.deleteItem}
                                                                  updateQuantity={this.updateQuantity}
                                                                  updateNewPlacement={this.updateNewPlacement}
                                                                  updateSupplierRep={this.updateSupplierRep}
                                                                  updateAccountNumber={this.updateAccountNumber}
                                                                  updatePurchaseOrderNumber={this.updatePurchaseOrderNumber}
                                                                  updateCustomerNotes={this.updateCustomerNotes}
                                                                  errors={this.props.errors}
                                                />
                                            );
                                        });
                                    })}
                                </Form>
                            </Card.Body>
                            <Card.Footer className="order-action-buttons" hidden={(orderViewModel?.suppliers?.length ?? 0) === 0}>
                                <div hidden={!this.props.isDirty}>
                                    <Button variant="primary" onClick={this.saveOrder}><i className="fas fa-save"/><span className="ml-1">SAVE</span></Button>
                                </div>
                                <div hidden={!this.props.isDirty}>
                                    <Button variant="secondary" onClick={this.cancelChanges}><i className="fas fa-times"/><span className="ml-1">REVERT</span></Button>
                                </div>
                                <div hidden={this.props.isDirty}>
                                    <Button variant="success" onClick={this.submitOrder}><i className="fas fa-paper-plane"/><span className="ml-1">SUBMIT</span></Button>
                                </div>
                                <div hidden={this.props.isDirty}>
                                    <Button variant="danger" onClick={this.cancelOrder}><i className="fas fa-trash"/><span className="ml-1">CANCEL</span></Button>
                                </div>
                            </Card.Footer>
                        </Card>
                    </Loading>
                </Container>
                <ConfirmationPopup show={this.state.showSubmitConfirmationPopup} onHide={this.closeSubmitPopup}>
                    <Card>
                        <Card.Header>
                            <h2>Confirm your action</h2>
                        </Card.Header>
                        <Card.Body className="confirmation-popup-body">
                            Are you sure you want to <b>Submit</b> your Order?
                        </Card.Body>
                        <Card.Footer className="confirmation-popup-footer">
                            <div className="left">
                                <Button variant="secondary" className="card-button" onClick={this.closeSubmitPopup}>No</Button>
                            </div>
                            <div className="right">
                                <Button variant="primary" className="card-button" onClick={this.submitOrderHandler}>
                                    Yes
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </ConfirmationPopup>
                <ConfirmationPopup show={this.state.showCancelConfirmationPopup} onHide={this.closeCancelPopup}>
                    <Card>
                        <Card.Header>
                            <h2>Confirm your action</h2>
                        </Card.Header>
                        <Card.Body className="confirmation-popup-body">
                            Are you sure you want to <b>Cancel</b> your Order?
                        </Card.Body>
                        <Card.Footer className="confirmation-popup-footer">
                            <div className="left">
                                <Button variant="secondary" className="card-button" onClick={this.closeCancelPopup}>No</Button>
                            </div>
                            <div className="right">
                                <Button variant="primary" className="card-button" onClick={this.cancelOrderHandler}>
                                    Yes
                                </Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </ConfirmationPopup>
                <ConfirmationPopup show={this.props.showActionConfirmationModal} onHide={this.closeInfoPopup}>
                    <Card>
                        <Card.Header>
                            <h2>Order {this.props.confirmationActionText}</h2>
                        </Card.Header>
                        <Card.Body className="confirmation-popup-body">
                            Your Order was successfully {this.props.confirmationActionText}
                        </Card.Body>
                        <Card.Footer className="confirmation-popup-footer">
                            <Button variant="success" className="card-button" onClick={this.closeInfoPopup}>Ok</Button>
                        </Card.Footer>
                    </Card>
                </ConfirmationPopup>
            </>
        );
    }
}

Order.propTypes = {
    currentOrder: PropTypes.shape(OrderViewModel)
};

export default connect(
    (state) => {
        return {
            ...state.order,
            products: state.product.orderProducts,
            searchFilter: state.searchFilter?.searchFilter
        };
    },
    dispatch => bindActionCreators(ordersApi, dispatch)
)(Order);