import GetDisplayVariant from "./Base/DisplayTypeToVariant";
import {Badge} from "react-bootstrap";
import React from "react";

export default class LocationStatusBadge extends React.PureComponent {
    render() {
        const locationStatuses = this.props.referenceData?.locationStatusesDict;

        if (locationStatuses) {
            const locationStatus = locationStatuses[this.props.status];

            if (locationStatus) {
                const variant = GetDisplayVariant(locationStatus.displayType);

                return <Badge variant={variant}>{locationStatus.text}</Badge>;
            }
        }

        return null;
    }
}