import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {api as homeApi} from "../store/Home";
import CardShortcut from "./Home/CardShortcut";
import {Carousel, Container} from "react-bootstrap";
import PureHtml from "./Base/PureHtml";
import Loading from "./Base/Loading";
import {scroller, Element} from 'react-scroll';

class Home extends Component {
    expression = /(?<area>#area_(?<areaId>\d+))(#item_(?<itemId>\d+))?/;
    componentDidMount() {
        this.props.requestHomePage();
    }

    render() {
        
        let openOnLoadSlide = undefined;
        let openOnLoadArea = undefined;
        const hash = this.props?.location?.hash;
        if (hash && this.props.homePage?.widgets) {
            const match = hash.match(this.expression);
            const area = match.groups.area;
            
            const areaId = match.groups.areaId;
            const itemId = match.groups.itemId;
            
            setTimeout(() => scroller.scrollTo(area, {offset: 0, smooth: true}));
            if (itemId) {
                openOnLoadArea = parseInt(areaId)
                openOnLoadSlide = parseInt(itemId);
            }
        }
        
        return (
            <Container className="home-page">
                <Loading active={this.props.isLoading}>
                    <Carousel className="home-page-slider">
                        {this.props.homePage?.slides?.map((slide, index) => (
                            <Carousel.Item key={index}>
                                {slide.url ?
                                    <a href={slide.url}>
                                        <img
                                            src={slide.image}
                                            alt=""
                                        />
                                        <Carousel.Caption>
                                            <PureHtml data={slide.text}/>
                                        </Carousel.Caption>
                                    </a> :
                                    <>
                                        <img
                                            src={slide.image}
                                            alt=""
                                        />
                                        <Carousel.Caption>
                                            <PureHtml data={slide.text}/>
                                        </Carousel.Caption>
                                    </>}
                            </Carousel.Item>
                        ))}
                    </Carousel>
                    {this.props.homePage?.widgets?.map((area, index) => {
                        return area.isPublished ? (
                            <Element as="div" name={`#area_${area.id}`} key={index} className="content-widget-group">
                                <div className="content-widget-header">
                                    <b/>
                                    <div className="content-widget-header-text">
                                        <i className={area.icon}/><h2>{area.name}</h2>
                                    </div>
                                    <b/>
                                </div>
                                <div className="content-widget-items">
                                    {area.cards?.map((item, index) => {
                                        return <CardShortcut data={item} key={index} openOnLoad={
                                            openOnLoadArea === area.id && openOnLoadSlide === item.id ? openOnLoadSlide : null
                                        } goToUrl={this.props.goToUrl}/>;
                                    })}
                                </div>
                            </Element>
                        ) : "";
                    })}
                </Loading>
            </Container>
        );
    }
}

export default connect(
    (state) => {
        return state.home;
    },
    dispatch => bindActionCreators(homeApi, dispatch)
)(Home);