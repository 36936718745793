import {createSlice, Dictionary, Draft, PayloadAction} from "@reduxjs/toolkit";
import {push} from "connected-react-router";
import {parse} from 'query-string';
import {
    AccountClient,
    BaseSupplierListItemOfBaseWarehouseListItem,
    BaseWarehouseListItem,
    BuyerAccountStatus,
    LocationFilter,
    LocationListItemModel,
    LocationsClient,
    LocationStatus,
    Lookup,
    LookupVariant,
    OrderType,
    PagedListOfLocationListItemModel,
    Paging,
    SettingsClient,
    SupplierFilter,
    VProductSkuFilter
} from "../api/clients";
import localStorage from "./localStorage";
import {api as catalogApi} from './Catalog';
import {api as ordersApi, OrderRetrieve, processOrderErrors} from './Orders';
import {api as searchPopupApi} from './SearchPopup';
import {api as homeApi} from './Home';
import * as _ from "lodash";
import {undefinedIfZero} from "./Base/Backend";
import {AppValidationException} from "../api/exceptions";
import {processServerException} from "./apiExceptionFilter";

export interface IVProductSkuFilter extends VProductSkuFilter {
    paging: IPaging
}

export interface SearchFilterEditState {
    searchText: string,
    upc: string,
    distributorItemNumber: string,
    selectedBrand: string,
    selectedDistributor: number,
    selectedWarehouse: number,
    category: number | undefined,
    hasPromotion: boolean,
    caseStack: boolean,
    oi: boolean,
    mcb: boolean,
    np: boolean,
    preferred: boolean
}

export interface SearchState extends SearchFilterEditState {
    brands: Lookup | undefined,
    brandsDictionary: Dictionary<LookupVariant>,
    distributors: BaseSupplierListItemOfBaseWarehouseListItem[] | undefined,
    warehouses: BaseWarehouseListItem[] | undefined,
    locations: LocationListItemModel[] | undefined,
    isLoading: boolean,
    selectedLocation: number | undefined,
    selectedOrder: number | undefined,
    searchFilter: IVProductSkuFilter | undefined
}

export interface SearchStateStored {
    selectedDistributor: number | undefined,
    selectedWarehouse: number | undefined,
}

export interface OrderStateStored {
    selectedLocation: number | undefined,
    selectedOrder: number | undefined
}

const searchFilterEditableInitialState: SearchFilterEditState = {
    searchText: '',
    upc: '',
    distributorItemNumber: '',
    selectedBrand: '',
    selectedDistributor: 0,
    selectedWarehouse: 0,
    category: undefined,
    hasPromotion: false,
    caseStack: false,
    oi: false,
    mcb: false,
    np: false,
    preferred: false
};

const initialState: SearchState = {
    ...searchFilterEditableInitialState,
    selectedLocation: undefined,
    selectedOrder: undefined,

    searchFilter: undefined,

    isLoading: false,

    brands: undefined,
    brandsDictionary: {},
    distributors: undefined,
    warehouses: undefined,
    locations: undefined
};

interface SelectedDistributorWarehousesResolverInputData {
    selectedDistributor: number | undefined,
    selectedWarehouse: number | undefined,
    distributors: BaseSupplierListItemOfBaseWarehouseListItem[] | undefined
}

interface SelectedDistributorWarehousesResolverOutputData extends SelectedDistributorWarehousesResolverInputData {
    selectedDistributor: number,
    selectedWarehouse: number,
    distributors: BaseSupplierListItemOfBaseWarehouseListItem[],
    warehouses: BaseWarehouseListItem[]
}

type StateUpdater = (state: SearchState) => void;

export const selectedDistributorWarehousesArrayResolver = (
    data: [number | undefined, number | undefined, BaseSupplierListItemOfBaseWarehouseListItem[] | undefined])
    : [number, number, BaseSupplierListItemOfBaseWarehouseListItem[], BaseWarehouseListItem[]] => {
    const input: SelectedDistributorWarehousesResolverInputData = {
        selectedDistributor: undefined,
        selectedWarehouse: undefined,
        distributors: undefined
    };
    [input.selectedDistributor, input.selectedWarehouse, input.distributors] = data;

    const out = selectedDistributorWarehousesResolver(input);

    return [out.selectedDistributor, out.selectedWarehouse, out.distributors, out.warehouses];
};

export const selectedDistributorWarehousesResolver = (
    input?: SelectedDistributorWarehousesResolverInputData | undefined)
    : SelectedDistributorWarehousesResolverOutputData => {
    const out: SelectedDistributorWarehousesResolverOutputData = {
        selectedDistributor: input?.selectedDistributor ?? 0,
        selectedWarehouse: input?.selectedDistributor ? input?.selectedWarehouse ?? 0 : 0,
        distributors: input?.distributors ?? [],
        warehouses: []
    };
    const filteredDistributors = out.distributors.filter(d => d.id === out.selectedDistributor);

    if (filteredDistributors?.length === 1 && filteredDistributors[0].warehouses !== undefined) {
        out.warehouses = filteredDistributors[0].warehouses;

        if (!out.warehouses.some(w => w.id === out.selectedWarehouse)) {
            out.selectedWarehouse = 0;
        }
    } else {
        out.selectedDistributor = 0;
        out.selectedWarehouse = 0;
    }

    return out;
};

export const ensureSearchFilter = (filter?: IVProductSkuFilter | undefined): IVProductSkuFilter =>
    filter ? _.cloneDeep(filter) : createSearchFilterPrototype();

const createSearchFilterPrototype = (): IVProductSkuFilter => {
    return {
        relatedOrderType: OrderType.Normal,
        paging: createPagingPrototype()
    } as IVProductSkuFilter;
};

export interface IPaging extends Paging {
    pageIndex: number;
    pageItemCount: number;
}

const createPagingPrototype = (): IPaging => {
    return {
        pageIndex: 1,
        pageItemCount: 24
    } as IPaging;
};

export const parseSearchQuery = (searchQuery: string | undefined) : SearchFilterEditState | undefined => {
    if (searchQuery) {
        const queryParsed = parse(searchQuery);
        const distributor = queryParsed["distributor"] as string | undefined ?? '0';
        const distributorParsed = parseInt(distributor);
        const selectedDistributor = isNaN(distributorParsed) ? 0 : distributorParsed;
        const warehouse = queryParsed["warehouse"] as string | undefined ?? '0';
        const warehouseParsed = parseInt(warehouse);
        const selectedWarehouse = selectedDistributor > 0 && !isNaN(warehouseParsed) ? warehouseParsed : 0;

        return {
            upc: queryParsed["upc"] as string | undefined ?? '',
            distributorItemNumber: queryParsed["itemNumber"] as string | undefined ?? '',
            selectedBrand: queryParsed["brand"] as string | undefined ?? '',
            selectedDistributor: selectedDistributor,
            selectedWarehouse: selectedWarehouse,
            category: parseInt(queryParsed["category"] as string | undefined ?? '0'),
            searchText: queryParsed["search"] as string | undefined ?? '',
            hasPromotion: queryParsed["hasPromotion"] === 'true',
            caseStack: queryParsed["caseStack"] === 'true',
            oi: queryParsed["oi"] === 'true',
            mcb: queryParsed["mcb"] === 'true',
            np: queryParsed["np"] === 'true',
            preferred: (distributor === 'preferred' || distributor === 'pref' || distributor === 'p')
                || (selectedDistributor > 0 && (warehouse === 'preferred' || warehouse === 'pref' || warehouse === 'p'))
        } as SearchFilterEditState;
    }

    return undefined;
}

export const defaultSearchFilter: IVProductSkuFilter = ((): IVProductSkuFilter => {
    let filter = createSearchFilterPrototype();

    const storedState: SearchStateStored | undefined = localStorage.getItem("searchFilter");
    filter.idSupplier = undefinedIfZero(storedState?.selectedDistributor);
    filter.idWarehouse = undefinedIfZero(storedState?.selectedWarehouse);

    return filter;
})();

export const defaultPaging: IPaging = createPagingPrototype();

export const defaultLocationsFilter: LocationFilter = (() => {
    const filter = new LocationFilter();

    filter.status = LocationStatus.Active;
    filter.buyerStatus = BuyerAccountStatus.Active;
    filter.paging = undefined;

    return filter;
})();

export const isSearchFilterEdited = (commonState: SearchFilterEditState) => {
    const searchFilterEditState: SearchFilterEditState = {} as SearchFilterEditState;
    updateState.refreshStateObject(searchFilterEditState, commonState);

    return !_.isEqual(searchFilterEditState, searchFilterEditableInitialState);
}

export const updateState = {
    requestFilterData: (state: SearchState) => {
        state.isLoading = true;
    },
    loadOrderingContext: (state: SearchState) => {
        const storedState: OrderStateStored = localStorage.getItem("orderingContext");

        if (storedState) {
            state.selectedLocation = storedState.selectedLocation;
            state.selectedOrder = storedState.selectedOrder;

            state.searchFilter = ensureSearchFilter(state.searchFilter);
            state.searchFilter.idActiveOrder = storedState.selectedOrder;
            state.searchFilter.idLocation = storedState.selectedLocation;
        }
    },
    changeDistributor: (state: SearchState, selectedDistributor: number) => {
        if (state.selectedDistributor !== selectedDistributor) {
            state.selectedWarehouse = 0;
        }

        [
            state.selectedDistributor,
            state.selectedWarehouse,,
            state.warehouses
        ] = selectedDistributorWarehousesArrayResolver([
            selectedDistributor, state.selectedWarehouse, state.distributors]);

        state.searchFilter = ensureSearchFilter(state.searchFilter);
        state.searchFilter.idSupplier = undefinedIfZero(state.selectedDistributor);
        state.searchFilter.idWarehouse = undefinedIfZero(state.selectedWarehouse);
    },
    changeWarehouse: (state: SearchState, selectedWarehouse: number) => {
        state.selectedWarehouse = selectedWarehouse;
        state.searchFilter = ensureSearchFilter(state.searchFilter);
        state.searchFilter.idWarehouse = undefinedIfZero(state.selectedWarehouse);
    },
    changeDistributorAndWarehouse: (state: SearchState, selectedDistributor: number | undefined,
                                    selectedWarehouse: number | undefined) => {
        if (selectedDistributor !== undefined) {
            updateState.changeDistributor(state, selectedDistributor);
        }

        if (state.selectedDistributor && selectedWarehouse !== undefined) {
            updateState.changeWarehouse(state, selectedWarehouse);
        }
    },
    changeDistributorAndWarehouseWithPreferred: (state: SearchState, selectedDistributor: number | undefined,
                                                 selectedWarehouse: number | undefined,
                                                 preferred: boolean | undefined) => {
        const [distributor, warehouse,, warehouses] = selectedDistributorWarehousesArrayResolver([
            !preferred || selectedDistributor ? selectedDistributor : state.selectedDistributor,
            !preferred || selectedWarehouse ? selectedWarehouse : state.selectedWarehouse,
            state.distributors]);
        updateState.changeDistributor(state, distributor);
        state.warehouses = warehouses;
        updateState.changeWarehouse(state, warehouses?.length > 0 ? warehouse : 0);
    },
    changeBrand: (state: SearchState, brand: string) => {
        state.selectedBrand = brand;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        if (state.brands?.lookupVariants) {
            state.searchFilter.idBrand = state.brandsDictionary[state.selectedBrand]?.id;
        }
    },
    changeDealsAndCaseStackOnly: (state: SearchState, value: boolean) => {
        state.hasPromotion = value;
        
        state.searchFilter = ensureSearchFilter(state.searchFilter);
        
        state.searchFilter.hasPromotion = state.hasPromotion;
    },
    changeCaseStack: (state: SearchState, value: boolean) => {
        state.caseStack = value;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        state.searchFilter.caseStack = state.caseStack;
    },
    changeOi: (state: SearchState, value: boolean) => {
        state.oi = value;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        state.searchFilter.oi = state.oi;
    },
    changeMcb: (state: SearchState, value: boolean) => {
        state.mcb = value;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        state.searchFilter.mcb = state.mcb;
    },
    changeNp: (state: SearchState, value: boolean) => {
        state.np = value;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        state.searchFilter.np = state.np;
    },
    changeCategory: (state: SearchState, idCategory: number | undefined) => {
        state.category = idCategory;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        state.searchFilter.idCategory = undefinedIfZero(state.category);
    },
    changeSearchFilterPaging: (state: SearchState, paging: IPaging = createPagingPrototype()) => {
        state.searchFilter = ensureSearchFilter(state.searchFilter);
        state.searchFilter.paging = paging;
    },
    changeLocationWithPreserve: (state: SearchState, selectedLocation: number | undefined) => {
        updateState.changeLocation(state, selectedLocation);

        const storedState: OrderStateStored = localStorage.getItem("orderingContext") ?? {} as OrderStateStored;
        storedState.selectedLocation = state.selectedLocation;
        localStorage.setItem("orderingContext", storedState);
    },
    changeLocation: (state: SearchState, selectedLocation: number | undefined) => {
        state.selectedLocation = selectedLocation;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        state.searchFilter.idLocation = state.selectedLocation;
    },
    changeDistributorItemNumber: (state: Draft<SearchState>, distributorItemNumber: string) => {
        state.distributorItemNumber = distributorItemNumber;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        if (state.distributorItemNumber) {
            state.searchFilter.supplierProductFieldValues = state.distributorItemNumber.split(",").map(x => x.trim());
        } else {
            state.searchFilter.supplierProductFieldValues = undefined;
        }
    },
    changeUpc: (state: Draft<SearchState>, upc: string) => {
        state.upc = upc;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        if (state.upc) {
            state.searchFilter.codes = state.upc.split(",").map(x => x.trim());
        } else {
            state.searchFilter.codes = undefined;
        }
    },
    changeSearchText: (state: Draft<SearchState>, searchText: string) => {
        state.searchText = searchText;

        state.searchFilter = ensureSearchFilter(state.searchFilter);

        state.searchFilter.searchText = state.searchText;
    },
    refreshStateFromEditState: (state: Draft<SearchState>, editState: SearchFilterEditState | undefined) => {
        if (editState) {
            updateState.changeDistributorAndWarehouseWithPreferred(state, editState.selectedDistributor,
                editState.selectedWarehouse, editState.preferred);
            updateState.changeBrand(state, editState.selectedBrand);
            updateState.changeDistributorItemNumber(state, editState.distributorItemNumber);
            updateState.changeUpc(state, editState.upc);
            updateState.changeSearchText(state, editState.searchText);
            updateState.changeCategory(state, editState.category);
            updateState.changeDealsAndCaseStackOnly(state, editState.hasPromotion);
            updateState.changeCaseStack(state, editState.caseStack);
            updateState.changeOi(state, editState.oi);
            updateState.changeMcb(state, editState.mcb);
            updateState.changeNp(state, editState.np);
        }
    },
    updateFilterFromEditState: (filter: IVProductSkuFilter, editState: SearchFilterEditState,
                                distributors: BaseSupplierListItemOfBaseWarehouseListItem[],
                                brands: Dictionary<LookupVariant>) => {
        const [selectedDistributor, selectedWarehouse, , warehouses] = selectedDistributorWarehousesArrayResolver([
            !editState.preferred || editState.selectedDistributor ? editState.selectedDistributor : filter.idSupplier,
            !editState.preferred || editState.selectedWarehouse ? editState.selectedWarehouse : filter.idWarehouse,
            distributors]);
        filter.idSupplier = undefinedIfZero(selectedDistributor);

        if (warehouses && warehouses.length > 0) {
            filter.idWarehouse = undefinedIfZero(selectedWarehouse);
        } else {
            filter.idWarehouse = undefined;
        }

        filter.searchText = editState.searchText;

        if (editState.upc) {
            filter.codes = editState.upc.split(",").map(x => x.trim());
        } else {
            filter.codes = undefined;
        }

        if (editState.distributorItemNumber) {
            filter.supplierProductFieldValues = editState.distributorItemNumber.split(",").map(x => x.trim());
        } else {
            filter.supplierProductFieldValues = undefined;
        }

        filter.idCategory = undefinedIfZero(editState.category);
        filter.idBrand = brands[editState.selectedBrand]?.id;
        filter.hasPromotion = editState.hasPromotion;
        filter.caseStack = editState.caseStack;
        filter.oi = editState.oi;
        filter.mcb = editState.mcb;
        filter.np = editState.np;
    },
    refreshStateObject: (state: SearchFilterEditState, editState: SearchFilterEditState | undefined) => {
        if (editState) {
            state.upc = editState.upc;
            state.distributorItemNumber = editState.distributorItemNumber;
            state.selectedBrand = editState.selectedBrand;
            state.selectedDistributor = editState.selectedDistributor;
            state.selectedWarehouse = editState.selectedWarehouse;
            state.category = editState.category;
            state.searchText = editState.searchText;
            state.hasPromotion = editState.hasPromotion;
            state.caseStack = editState.caseStack;
            state.oi = editState.oi;
            state.mcb = editState.mcb;
            state.np = editState.np;
        }
    },
};

const searchFilterSlice = createSlice({
    name: "searchFilter",
    initialState: initialState,
    reducers: {
        loadOrderingContextWithRequestFilterData: (state: Draft<SearchState>) => {
            updateState.loadOrderingContext(state);
            updateState.requestFilterData(state);
        },
        receiveFilterData: (state: Draft<SearchState>, action: PayloadAction<[
                Lookup | undefined,
                BaseSupplierListItemOfBaseWarehouseListItem[] | undefined,
                LocationListItemModel[] | undefined,
                SearchStateStored | undefined,
                OrderStateStored | undefined,
                SearchFilterEditState | undefined
        ]>) => {
            const [brands, distributors, locations, storedState, orderStoredState, editState] = action.payload;
            state.brands = brands;
            state.locations = locations;
            state.brandsDictionary = {};
            state.brands?.lookupVariants?.forEach(lv => {
                if (lv.valueVariant) {
                    state.brandsDictionary[lv.valueVariant] = lv;
                }
            });
            state.distributors = distributors;

            if (!orderStoredState) {
                state.selectedLocation = (locations ?? [])[0]?.id;

                state.searchFilter = ensureSearchFilter(state.searchFilter);
                state.searchFilter.idLocation = state.selectedLocation;
            }

            updateState.changeDistributorAndWarehouse(
                state, storedState?.selectedDistributor, storedState?.selectedWarehouse);
            updateState.refreshStateFromEditState(state, editState);

            state.isLoading = false;
        },
        refreshStateFromEditState: (state: Draft<SearchState>, action: PayloadAction<SearchFilterEditState | undefined>) => {
            const editState = action.payload;
            updateState.refreshStateFromEditState(state, editState);
        },
        receivePopupData: (state: Draft<SearchState>, action: PayloadAction<SearchStateStored | undefined>) => {
            if (action.payload) {
                const {selectedDistributor, selectedWarehouse} = action.payload;
                updateState.changeDistributorAndWarehouse(state, selectedDistributor, selectedWarehouse);
            } else {
                updateState.changeDistributorAndWarehouse(state, undefined, undefined);
            }
        },
        changeDistributor: (state: Draft<SearchState>, action: PayloadAction<number>) => {
            updateState.changeDistributor(state, action.payload);
        },
        changeWarehouse: (state: Draft<SearchState>, action: PayloadAction<number>) => {
            updateState.changeWarehouse(state, action.payload);
        },
        changeBrand: (state: Draft<SearchState>, action: PayloadAction<string>) => {
            updateState.changeBrand(state, action.payload);
        },
        changeDistributorItemNumber: (state: Draft<SearchState>, action: PayloadAction<string>) => {
            updateState.changeDistributorItemNumber(state, action.payload);
        },
        changeUpc: (state: Draft<SearchState>, action: PayloadAction<string>) => {
            updateState.changeUpc(state, action.payload);
        },
        changeSearchText: (state: Draft<SearchState>, action: PayloadAction<string>) => {
            updateState.changeSearchText(state, action.payload);
        },
        changeCategory: (state: Draft<SearchState>, action: PayloadAction<number | undefined>) => {
            updateState.changeCategory(state, action.payload);
        },
        changeDealsAndCaseStackOnly: (state: Draft<SearchState>, action: PayloadAction<boolean>) => {
            updateState.changeDealsAndCaseStackOnly(state, action.payload);
        },
        changeCaseStack: (state: Draft<SearchState>, action: PayloadAction<boolean>) => {
            updateState.changeCaseStack(state, action.payload);
        },
        changeOi: (state: Draft<SearchState>, action: PayloadAction<boolean>) => {
            updateState.changeOi(state, action.payload);
        },
        changeMcb: (state: Draft<SearchState>, action: PayloadAction<boolean>) => {
            updateState.changeMcb(state, action.payload);
        },
        changeNp: (state: Draft<SearchState>, action: PayloadAction<boolean>) => {
            updateState.changeNp(state, action.payload);
        },
        changeLocation: (state: Draft<SearchState>, action: PayloadAction<number>) => {
            updateState.changeLocationWithPreserve(state, action.payload);
        },
        changeOrder: (state: Draft<SearchState>, action: PayloadAction<number | undefined>) => {
            state.selectedOrder = action.payload;

            state.searchFilter = ensureSearchFilter(state.searchFilter);

            state.searchFilter.idActiveOrder = state.selectedOrder;

            const storedState: OrderStateStored = localStorage.getItem("orderingContext") ?? {} as OrderStateStored;
            storedState.selectedOrder = state.selectedOrder;
            localStorage.setItem("orderingContext", storedState);
        },
        changeSearchFilterPaging: (state: Draft<SearchState>, action: PayloadAction<IPaging | undefined>) => {
            updateState.changeSearchFilterPaging(state, action.payload ?? createPagingPrototype())
        }
    }
});

export const {reducer} = searchFilterSlice;
const {actions} = searchFilterSlice;

const locationDataAccessProvider = {
    retrieveLocations: async (filter: LocationFilter): Promise<OrderRetrieve<PagedListOfLocationListItemModel>> => {
        try {
            const locationsResult = await new LocationsClient().getLocations(filter);

            if (locationsResult.success) {
                return {
                    success: true,
                    data: locationsResult.data
                } as OrderRetrieve<PagedListOfLocationListItemModel>;
            } else {
                return processOrderErrors<OrderRetrieve<PagedListOfLocationListItemModel>>(locationsResult.messages);
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);
            const orderRetrieveState = processOrderErrors<OrderRetrieve<PagedListOfLocationListItemModel>>(
                appValidationException.messages);

            //TODO: 404 processing as empty result everywhere basically
            if (e.status === 403) {
                orderRetrieveState.hasAccessDeniedError = true;
            }

            return orderRetrieveState;
        }
    }

    //TODO move here another data accessors
};

const internalApi = {
    runSearch: (dispatch: any, getState: any, withCategories: boolean = false) => {
        return internalApi.runSearchWithTempState(dispatch, getState, () => {
        }, withCategories);
    },
    runSearchWithTempState: (dispatch: any, getState: any, stateUpdater: StateUpdater, withCategories: boolean = false) => {
        dispatch(actions.changeSearchFilterPaging());

        const {searchFilter}: { searchFilter: SearchState } = getState();
        let tempState: SearchState = {...searchFilter};

        updateState.changeSearchFilterPaging(tempState);
        stateUpdater(tempState);

        return dispatch(catalogApi.requestCatalog(tempState.searchFilter, withCategories));
    },
    receivePopupData: (dispatch: any, popupState : SearchStateStored | undefined) => {
        const storingState: SearchStateStored = popupState ?? {} as SearchStateStored;
        localStorage.setItem("searchFilter", storingState);

        return dispatch(actions.receivePopupData(storingState));
    }
};

export const api = {
    ...actions,
    requestFilterData: (router: any) => async (dispatch: any) => {
        dispatch(actions.loadOrderingContextWithRequestFilterData());

        const filter = new SupplierFilter();
        filter.useDesktopPreferences = true;

        const client = new SettingsClient();

        if (router?.location?.pathname !== '/') {
            dispatch(homeApi.requestHomePage());
        }

        const filterStoredState = localStorage.getItem("searchFilter") as SearchStateStored | undefined;
        const orderStoredState = localStorage.getItem("orderingContext") as OrderStateStored | undefined;

        const locationsClient = new LocationsClient();
        const selectedLocations: LocationListItemModel[] = await (async () => {
            if (orderStoredState?.selectedLocation) {
                const selectedLocationFilter = {
                    ...defaultLocationsFilter,
                    ids: [orderStoredState.selectedLocation]
                } as LocationFilter;

                return (await locationsClient.getLocations(selectedLocationFilter)).data?.items ?? []
            } else {
                return [];
            }
        })();

        const [suppliersData, lookupsData, locationsData] = await Promise.all([
            client.getSuppliers(filter),
            client.getProductListFilterLookups(),
            locationsClient.getLocations(defaultLocationsFilter)
        ]);

        //TODO errors processing

        const productBrands: Lookup | undefined = lookupsData?.data?.productBrands;
        const distributors: BaseSupplierListItemOfBaseWarehouseListItem[] | undefined = suppliersData?.data?.items;
        const locations = [...selectedLocations, ...(locationsData.data?.items ?? [])].filter(
            (v: LocationListItemModel, _, a) => {
                const duplicates = a.filter(av => av.id === v.id);

                return duplicates.length === 1 || duplicates[0] === v;
            }
        );
        const idLocation = locations[0]?.id;

        if (idLocation && (!orderStoredState?.selectedLocation || idLocation !== orderStoredState.selectedLocation)) {
            //TODO check here if we need to reset orderId
            let storedState = orderStoredState ?? {} as OrderStateStored;
            storedState.selectedLocation = idLocation;
            localStorage.setItem("orderingContext", storedState);
        }

        if (router?.location?.pathname === '/catalog') {
            const filter = createSearchFilterPrototype();
            filter.idSupplier = undefinedIfZero(filterStoredState?.selectedDistributor);
            filter.idWarehouse = undefinedIfZero(filterStoredState?.selectedWarehouse);
            filter.idLocation = idLocation;
            filter.idActiveOrder = orderStoredState?.selectedOrder;

            const searchQuery = router?.location?.search;
            const editState = parseSearchQuery(searchQuery);

            if (editState) {
                const brandsDictionary = {} as Dictionary<LookupVariant>;
                productBrands?.lookupVariants?.forEach(lv => {
                    if (lv.valueVariant) {
                        brandsDictionary[lv.valueVariant] = lv;
                    }
                });

                updateState.updateFilterFromEditState(filter, editState, distributors ?? [], brandsDictionary);
            }

            dispatch(actions.receiveFilterData([productBrands, distributors, locations, filterStoredState, orderStoredState, editState]));

            dispatch(catalogApi.requestCatalog(filter, true));
            dispatch(ordersApi.requestOrder(filter.idActiveOrder, filter.idLocation));
        } else {
            dispatch(actions.receiveFilterData([productBrands, distributors, locations, filterStoredState, orderStoredState, undefined]));
        }

        return dispatch(searchPopupApi.receivePopupData([filterStoredState, distributors]));
    },
    receivePopupData: (popupState: SearchStateStored | undefined) => (dispatch: any) => {
        return internalApi.receivePopupData(dispatch, popupState);
    },
    receivePopupDataThenRunSearch: (popupState: SearchStateStored | undefined) => (dispatch: any, getState: any) => {
        internalApi.receivePopupData(dispatch, popupState);

        return internalApi.runSearchWithTempState(dispatch, getState, (tempState: SearchState) =>
            updateState.changeDistributorAndWarehouse(tempState, popupState?.selectedDistributor, popupState?.selectedWarehouse), true);
    },
    changeCategoryWithReload: (idCategory: number | undefined) => (dispatch: any, getState: any) => {
        dispatch(actions.changeCategory(idCategory));

        return internalApi.runSearchWithTempState(dispatch, getState, (tempState: SearchState) =>
            updateState.changeCategory(tempState, idCategory));
    },
    runSearch: () => (dispatch: any, getState: any) => {
        return internalApi.runSearchWithTempState(dispatch, getState, (tempState: SearchState) => {
            if (tempState?.selectedBrand && !tempState?.searchFilter?.idBrand) {
                dispatch(actions.changeBrand(''));
            }
        }, true);
    },
    runSearchWithRedirect: () => (dispatch: any) => {
        return dispatch(push("/catalog"));
    },
    resetSearchFilter: () => (dispatch: any) => {
        dispatch(actions.refreshStateFromEditState(searchFilterEditableInitialState));

        return (dispatch(catalogApi.clearCategoryPath()));
    },
    changeLocation: (idLocation: number, orderNumber: number | undefined = undefined) => (dispatch: any, getState: any) => {
        dispatch(actions.changeLocation(idLocation));
        dispatch(ordersApi.requestOrder(orderNumber, idLocation));

        return internalApi.runSearchWithTempState(dispatch, getState,
            (s: SearchState) => updateState.changeLocation(s, idLocation));
    },
    logout: () => async () => {
        await new AccountClient().logout();
        localStorage.removeItem("searchFilter");
        localStorage.removeItem("orderingContext");
        window.location.reload();
    },
    retrieveLocations: (locationFilterText: string) => async (): Promise<PagedListOfLocationListItemModel | undefined> => {
        const locationsFilter = defaultLocationsFilter;
        locationsFilter.locationFilterText = locationFilterText;
        const locationResult = await locationDataAccessProvider.retrieveLocations(locationsFilter);

        if (locationResult.success) {
            return locationResult.data;
        } else {
            return undefined;
        } 
    }
};
