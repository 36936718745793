import GetDisplayVariant from "../Base/DisplayTypeToVariant";
import {Badge} from "react-bootstrap";
import React from "react";

export default class OrderStatusBadge extends React.PureComponent {
    render() {
        const orderStatuses = this.props.referenceData?.orderStatusesDict;
        if (orderStatuses) {
            const orderStatus = orderStatuses[this.props.status];
            if (orderStatus) {
                const variant = GetDisplayVariant(orderStatus.displayType);
                return (<Badge variant={variant}>{orderStatus.text}</Badge>);
            }
        }
        return null;
    }
}