import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {
    MessageInfo,
    ProductsClient,
    VProductSkuFilter,
    ProductGridItemModel,
    ProductCategoryTreeItemModel, Paging, LimitedPagedListOfProductGridItemModel
} from "../api/clients";
import {replace, RouterState} from "connected-react-router";
import {
    api as searchApi,
    defaultPaging,
    defaultSearchFilter,
    ensureSearchFilter, IPaging, IVProductSkuFilter, parseSearchQuery,
    SearchFilterEditState,
    SearchState
} from "./SearchFilter";
import {parseErrors, processServerException} from "./apiExceptionFilter";
import {AppValidationException} from "../api/exceptions";
import {TreeMenuItem, TreeNodeInArray} from "react-simple-tree-menu";
import * as Enumerable from "linq-es2015";
import * as _ from "lodash";
import {updateState as searchFilterUpdateState} from "./SearchFilter";

interface state {
    errors: any,
    globalErrors: string[] | undefined,
    productList: ProductGridItemModel[] | undefined,
    rootCategory: ProductCategoryTreeItemModel | undefined,
    categories: TreeMenuItem[] | undefined,
    selectedCategoryPath: string,
    selectedCategoryOpenNodes: string[] | undefined,
    currentFilter: IVProductSkuFilter | undefined,
    hasMore: boolean | undefined,
    isLoading: boolean,
    isCategoriesLoading: boolean,
    isStateReady: boolean
}

const initialState: state = {
    errors: null,
    globalErrors: undefined,
    productList: undefined,
    rootCategory: undefined,
    categories: undefined,
    selectedCategoryPath: '',
    selectedCategoryOpenNodes: undefined,
    currentFilter: undefined,
    hasMore: undefined,
    isLoading: false,
    isCategoriesLoading: false,
    isStateReady: false
};

const getSelectedPath = (idCategory: number | undefined, categoryList: ProductCategoryTreeItemModel[] | undefined)
    : string | undefined => {
    const category = Enumerable.asEnumerable(categoryList || []).FirstOrDefault(c => c.id === idCategory);

    if (category) {
        if (category.subItems && category.subItems.length > 0) {
            return `category_${category.id?.toString()}/category_${category.id?.toString()}`;
        } else {
            return `category_${category.id?.toString()}`;
        }
    } else {
        const deepPath = Enumerable.asEnumerable(categoryList || [])
            .Select(c => {
                const path = getSelectedPath(idCategory, c.subItems);
                if (path) {
                    return {category: c, path: path};
                }
                return undefined;
            })
            .FirstOrDefault(c => c !== undefined);

        if (deepPath) {
            return `category_${deepPath?.category?.id?.toString()}/${deepPath?.path}`;
        }
    }
};

const getOpenNodes = (selectedCategory: string | undefined): string[] | undefined => {
    if (selectedCategory) {
        const pathList = selectedCategory.split("/");
        return Enumerable.asEnumerable(pathList).Take(pathList.length - 1).ToArray();
    }
};

const mapSubcategoriesToMenuItems = (categoryList: ProductCategoryTreeItemModel[] | undefined, level: number)
    : TreeNodeInArray[] | undefined => {
    if (!categoryList) {
        return undefined;
    }

    return categoryList?.map(c => {
        const result: TreeNodeInArray = {
            key: `category_${c.id?.toString()}`,
            label: c.name ?? '',
            nodes: mapSubcategoriesToMenuItems(c.subItems, level + 1),
            id: c.id
        };

        return result;
    });
};

const mapCategoriesToMenuItems = (categoryList: ProductCategoryTreeItemModel[] | undefined, level: number)
    : TreeMenuItem[] | undefined => {
    if (!categoryList) {
        return undefined;
    }

    return categoryList?.map(c => {
        const nodes: TreeNodeInArray[] | undefined = mapSubcategoriesToMenuItems(c.subItems, level + 1);
        const result: any = {
            key: `category_${c.id?.toString()}`,
            label: c.name ?? '',
            level: level,
            hasNodes: (c.subItems && c.subItems.length > 0) ?? false,
            id: c.id
        };

        if (nodes !== undefined && nodes.length > 0) {
            result.nodes = [{...result, level: result.level + 1, nodes: undefined}, ...nodes];
        }

        return result;
    });
};

export const baseHasMoreItems = (dataCount: number, totalDataCount: number, paging: Paging) : boolean | undefined => {
        let {pageItemCount, pageIndex} = paging;

        if (!pageItemCount) {
            pageItemCount = 0;
        }

        if (!pageIndex) {
            pageIndex = 0;
        }

        return dataCount >= pageItemCount && totalDataCount > pageItemCount * pageIndex;
}

const hasMoreItems = (resultData: LimitedPagedListOfProductGridItemModel | undefined, paging: Paging | undefined)
    : boolean | undefined => {
    if (resultData) {
        return baseHasMoreItems(resultData.count ?? 0, resultData?.actualCount ?? 0, paging ?? defaultSearchFilter.paging ?? defaultPaging);
    } else {
        return undefined;
    }
}

const updateState = {
    requestCatalog: (state: state, isRequestCategories: boolean = false) => {
        state.isLoading = true;

        if (isRequestCategories) {
            updateState.requestCategories(state);
        }
    },
    setCatalogNextPage: (state: state) => {
        state.currentFilter = state.currentFilter !== undefined ? ((filter: IVProductSkuFilter) => {
            filter.paging.pageIndex++;

            return filter;
        })(_.cloneDeep(state.currentFilter)) : ((filter: VProductSkuFilter, paging: IPaging): IVProductSkuFilter => {
            filter.paging = paging;

            return filter as IVProductSkuFilter;
        })(new VProductSkuFilter(), defaultPaging);
    },
    requestCatalogNextPage: (state: state) => {
        updateState.setCatalogNextPage(state);
        updateState.requestCatalog(state);
    },
    requestCategories: (state: state) => {
        state.isCategoriesLoading = true;
    },
    receiveErrors: (state: state, errors: any, globalErrors: string[]) => {
        state.isLoading = false;
        state.isCategoriesLoading = false;
        state.hasMore = undefined;

        state.errors = errors;
        state.globalErrors = globalErrors;
    },
    receiveCategoriesErrors: (state: state, errors: any, globalErrors: string[], concatErrors: boolean = false) => {
        state.isLoading = false;
        state.isCategoriesLoading = false;

        if (concatErrors) {
            if (errors) {
                state.errors = ((oldErrors : any, errors : any) => ({...oldErrors ?? {}, ...errors ?? {}}))
                (state.errors, errors);
            }

            if (globalErrors.length > 0) {
                state.globalErrors = [...state.globalErrors ?? [], ...globalErrors];
            }
        } else {
            state.errors = errors ?? {};
            state.globalErrors = globalErrors;
        }
    }
};

const catalogSlice = createSlice({
    name: "catalog",
    initialState: initialState,
    reducers: {
        requestCatalog: (state: Draft<state>, action: PayloadAction<boolean | undefined>) => {
            updateState.requestCatalog(state, action.payload ?? false);
        },
        requestCatalogNextPage: (state: Draft<state>) => {
            updateState.requestCatalogNextPage(state);
        },
        requestCategories: (state: Draft<state>) => {
            updateState.requestCategories(state);
        },
        receiveCatalogResults: (state: Draft<state>, action: PayloadAction<[
            ProductGridItemModel[] | undefined,
            ProductCategoryTreeItemModel | undefined,
            IVProductSkuFilter,
            boolean | undefined]>) => {
            const [productList, rootCategory, filter, hasMore] = action.payload;

            state.currentFilter = _.cloneDeep(filter);

            if (rootCategory) {
                state.rootCategory = rootCategory;
                state.categories = mapCategoriesToMenuItems(rootCategory.subItems, 0);
            }

            const selectedPath = getSelectedPath(filter.idCategory, state.rootCategory?.subItems);

            state.selectedCategoryPath = selectedPath ?? '_';
            state.selectedCategoryOpenNodes = getOpenNodes(selectedPath);
            state.productList = productList;
            state.hasMore = hasMore;
            state.isLoading = false;
            state.isCategoriesLoading = false;
            state.isStateReady = true;
        },
        receiveCatalogNextPageResults: (state: Draft<state>, action: PayloadAction<[
                ProductGridItemModel[] | undefined, boolean | undefined]>) => {
            const [productList, hasMore] = action.payload;

            if (state?.productList && productList) {
                state.productList = [...state.productList, ...productList];
            }

            state.hasMore = hasMore;
            state.isLoading = false;
        },
        receiveErrors: (state: Draft<state>, action) => {
            updateState.receiveErrors(state, action.payload.errors, action.payload.globalErrors);
        },
        receiveCategoriesErrors: (state: Draft<state>, action) => {
            updateState.receiveCategoriesErrors(state, action.payload.errors, action.payload.globalErrors);
        },
        receiveAllErrors: (state: Draft<state>, action) => {
            updateState.receiveErrors(state, action.payload.errors, action.payload.globalErrors);
            updateState.receiveCategoriesErrors(state, action.payload.errors, action.payload.globalErrors, true);
        },
        clearErrors: (state: Draft<state>) => {
            state.globalErrors = undefined;
        },
        clearCategoryPath: (state: Draft<state>) => {
            state.selectedCategoryPath = '_';
        }
    }
});

export const {reducer} = catalogSlice;

const {actions} = catalogSlice;

const processCatalogErrors = (messages?: MessageInfo[]) => {
    const errors: any = {};
    const globalErrors = parseErrors(messages, errors);

    return {errors, globalErrors};
};

const internalApi = {
    getNewUrl: (router: any, state: SearchFilterEditState) => {
        if ('URLSearchParams' in window) {
            const searchParams = new URLSearchParams(router.location.search)

            if (state.upc) {
                searchParams.set("upc", state.upc);
            } else {
                searchParams.delete("upc");
            }
            if (state.distributorItemNumber) {
                searchParams.set("itemNumber", state.distributorItemNumber);
            } else {
                searchParams.delete("itemNumber");
            }
            if (state.selectedBrand) {
                searchParams.set("brand", state.selectedBrand);
            } else {
                searchParams.delete("brand");
            }
            if (state.selectedDistributor > 0) {
                searchParams.set("distributor", state.selectedDistributor.toString());
            } else {
                searchParams.delete("distributor");
            }
            if (state.selectedWarehouse > 0) {
                searchParams.set("warehouse", state.selectedWarehouse.toString());
            } else {
                searchParams.delete("warehouse");
            }
            if (state.category && state.category > 0) {
                searchParams.set("category", state.category.toString());
            } else {
                searchParams.delete("category");
            }
            if (state.searchText) {
                searchParams.set("search", state.searchText);
            } else {
                searchParams.delete("search");
            }
            if (state.hasPromotion) {
                searchParams.set("hasPromotion", state.hasPromotion.toString());
                if (state.caseStack) {
                    searchParams.set("caseStack", state.caseStack.toString());
                } else {
                    searchParams.delete("caseStack");
                }
                if (state.oi) {
                    searchParams.set("oi", state.oi.toString());
                } else {
                    searchParams.delete("oi");
                }
                if (state.mcb) {
                    searchParams.set("mcb", state.mcb.toString());
                } else {
                    searchParams.delete("mcb");
                }
                if (state.np) {
                    searchParams.set("np", state.np.toString());
                } else {
                    searchParams.delete("np");
                }
            } else {
                searchParams.delete("hasPromotion");
                searchParams.delete("caseStack");
                searchParams.delete("oi");
                searchParams.delete("mcb");
                searchParams.delete("np");
            }

            let newRelativePathQuery = router.location.pathname + '?' + searchParams.toString();

            if (router.location.hash) {
                newRelativePathQuery += "#" + router.location.hash;
            }

            return newRelativePathQuery;
        }
    }
};

export const api = {
    ...actions,
    requestCatalog: (filter: IVProductSkuFilter | undefined, withCategories: boolean, parseQuery: boolean = false) => async (dispatch: any, getState: any) => {
        const {searchFilter, router} : {searchFilter: SearchState, router: RouterState} = getState();

        let updatedState = {...searchFilter} as SearchFilterEditState;

        dispatch(actions.requestCatalog(withCategories));

        if (!filter) {
            filter = ensureSearchFilter(searchFilter.searchFilter);
        }

        if (parseQuery) {
            const searchQuery = router?.location?.search;
            const editState = parseSearchQuery(searchQuery);

            if (editState) {
                filter = _.cloneDeep(filter);

                searchFilterUpdateState.updateFilterFromEditState(filter, editState, searchFilter.distributors ?? [],
                    searchFilter.brandsDictionary);
                searchFilterUpdateState.refreshStateObject(updatedState, editState);
                dispatch(searchApi.refreshStateFromEditState(editState));
            }
        }

        const client = new ProductsClient();

        try {
            const [catalogResult, categories] = await Promise.all([
                client.getProducts(filter),
                withCategories ? client.getCategoriesTree(filter) : Promise.resolve(undefined)
            ]);

            if (catalogResult.success && (!categories || categories.success)) {
                if (withCategories) {
                    const selectedPath = getSelectedPath(filter.idCategory, categories?.data?.subItems);

                    if (!selectedPath && filter.idCategory) {
                        const newFilter = {...filter, idCategory: undefined} as IVProductSkuFilter;
                        dispatch(searchApi.changeCategory(undefined));
                        updatedState = {...updatedState, category: undefined} as SearchFilterEditState;

                        if (router?.location?.pathname === '/catalog') {
                            dispatch(replace(internalApi.getNewUrl(router, updatedState)));
                        }

                        const newCatalogResult = await client.getProducts(newFilter);

                        if (newCatalogResult.success) {
                            return dispatch(actions.receiveCatalogResults([
                                newCatalogResult.data?.items,
                                categories?.data,
                                newFilter,
                                hasMoreItems(newCatalogResult.data, newFilter.paging)
                            ]));
                        } else {
                            return dispatch(actions.receiveErrors(processCatalogErrors(newCatalogResult.messages)));
                        }
                    }
                } else {
                    if (catalogResult.data?.count === 0 && filter.idCategory) {
                        const categories = await client.getCategoriesTree(filter);

                        if (categories.success) {
                            const selectedPath = getSelectedPath(filter.idCategory, categories?.data?.subItems);

                            if (!selectedPath) {
                                const newFilter = {...filter, idCategory: undefined} as IVProductSkuFilter;
                                dispatch(searchApi.changeCategory(undefined));
                                updatedState = {...updatedState, category: undefined} as SearchFilterEditState;

                                dispatch(replace(internalApi.getNewUrl(router, updatedState)));

                                return dispatch(api.requestCatalog(newFilter, true));
                            }
                        } else {
                            return dispatch(actions.receiveErrors(processCatalogErrors(categories.messages)));
                        }
                    }
                }

                if (router?.location?.pathname === '/catalog') {
                    dispatch(replace(internalApi.getNewUrl(router, updatedState)));
                }

                return dispatch(actions.receiveCatalogResults([catalogResult.data?.items, categories?.data, filter,
                    hasMoreItems(catalogResult.data, filter.paging)]));
            } else {
                let dispatchResult;

                if (!catalogResult.success && categories && !categories.success) {
                    dispatchResult = dispatch(actions.receiveAllErrors([
                        processCatalogErrors(catalogResult.messages), processCatalogErrors(categories.messages)]));
                } else {
                    if (!catalogResult.success) {
                        dispatchResult = dispatch(actions.receiveErrors(processCatalogErrors(catalogResult.messages)));
                    } else if (categories && !categories.success) {
                        dispatchResult = dispatch(
                            actions.receiveCategoriesErrors(processCatalogErrors(categories.messages)));
                    }
                }

                return dispatchResult;
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);

            return dispatch(actions.receiveErrors(processCatalogErrors(appValidationException.messages)));
        }
    },
    requestCatalogNextPage: () => async (dispatch: any, getState: any) => {
        const newState : state = (state => {
            const {catalog} : {catalog: state} = state;

            return _.cloneDeep(catalog);
        })(getState());

        updateState.setCatalogNextPage(newState);
        dispatch(actions.requestCatalogNextPage());

        try {
            const {success, data, messages} = await new ProductsClient().getProducts(newState.currentFilter as IVProductSkuFilter);

            if (success) {
                return dispatch(actions.receiveCatalogNextPageResults([
                    data?.items, hasMoreItems(data, (newState.currentFilter as IVProductSkuFilter).paging)]));
            } else {
                return dispatch(actions.receiveErrors(processCatalogErrors(messages)));
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);

            return dispatch(actions.receiveErrors(processCatalogErrors(appValidationException.messages)));
        }
    }
};