import {Button, Card, Image, Modal} from "react-bootstrap";
import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import PropTypes from "prop-types";
import {ContentWidgetCardImageModel} from "../../api/clients";
import {Swipeable} from "react-swipeable";

export default class HomeCardImageSwiper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            zoomIsOpen: false,
            zoomSlide: 0
        }

        this.openZoomView = this.openZoomView.bind(this);
        this.closeZoomView = this.closeZoomView.bind(this);
    }

    openZoomView(index) {
        this.setState({
            zoomIsOpen: true,
            zoomSlide: index
        })
    }

    closeZoomView() {
        this.setState({
            zoomIsOpen: false
        })
    }

    render() {
        return (
            <>
                <div className="image-container">
                    <Swiper
                        className="image"
                        autoplay={false}
                        centeredSlides={true}
                        navigation
                        scrollbar={{draggable: true}}
                        watchOverflow={true}
                        lazy={{
                            loadOnTransitionStart: true
                        }}
                        initialSlide={this.props.initialSlide}
                    >
                        {this.props.images?.map((img, index) => (
                            <SwiperSlide key={index} onClick={() => this.openZoomView(index)} title={img.title}>
                                <Card.Img className="image swiper-lazy" variant="top" data-src={img.originalImage} alt={img.alternativeText}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <Modal
                    show={this.state.zoomIsOpen}
                    onHide={this.closeZoomView}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered={true}
                    size="xl"
                    backdrop="static"
                    className="zoom-modal-container"
                >
                    <Button variant="dark" size="lg" className="exit-button" onClick={this.closeZoomView}>
                        <span aria-hidden="true"><i className="fas fa-times"/></span>
                        <span className="sr-only">Close</span>
                    </Button>
                    <Swipeable onSwipedUp={this.closeZoomView} delta={300}>
                        <div className="zoom-image-container">
                            <Swiper
                                autoplay={true}
                                centeredSlides={true}
                                keyboard
                                pagination={{clickable: true}}
                                watchOverflow={true}
                                zoom={true}
                                initialSlide={this.state.zoomSlide}
                            >
                                {this.props.images?.map((img, index) => (
                                    <SwiperSlide key={index} zoom={true} title={img.title}>
                                        <Image fluid={true} variant="top" src={img.originalImage} alt={img.alternativeText}/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </Swipeable>
                </Modal>
            </>
        )
    }
}

HomeCardImageSwiper.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape(ContentWidgetCardImageModel)),
    initialSlide: PropTypes.number
};
