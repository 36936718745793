import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api as profileApi} from "../store/Profile";
import {api as orderApi} from "../store/Orders";
import OrderView from "./Order/OrderView";

class OrderDetail extends React.Component {
    componentDidMount() {
        this.props.requestOrder(parseInt(this.props.match.params.id));
    }

    render() {
        return (
            <OrderView {...this.props} />
        );
    };
}

export default connect(
    (state) => ({
        ...state.profile,
        products: state.product.orderHistoryProducts,
        router: state.router
    }),
    dispatch => bindActionCreators({...profileApi, downloadPdf: orderApi.downloadPdf}, dispatch)
)(OrderDetail);