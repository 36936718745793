import {Card} from "react-bootstrap";
import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import PropTypes from "prop-types";
import {ContentWidgetCardImageModel} from "../../api/clients";

export default class HomeCardImageSwiperNoZoom extends React.Component {
    constructor(props) {
        super(props);

        this.onClick = this.onClick.bind(this);
    }

    onClick(index) {
        if (this.props.onClick) {
            this.props.onClick(index);
        }
    }

    render() {
        return (
            <>
                <div className="image-container">
                    <Swiper
                        className="image"
                        autoplay={false}
                        centeredSlides={true}
                        navigation
                        scrollbar={{draggable: true}}
                        watchOverflow={true}
                        lazy={{
                            loadOnTransitionStart: true
                        }}
                    >
                        {this.props.images?.map((img, index) => (
                            <SwiperSlide key={index} onClick={() => this.onClick(index)} title={img.title}>
                                <Card.Img className="image swiper-lazy" variant="top" data-src={img.source} alt={img.alternativeText}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </>
        )
    }
}

HomeCardImageSwiperNoZoom.propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape(ContentWidgetCardImageModel)),
    onClick: PropTypes.func
};