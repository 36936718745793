import React from "react";
import {FormattedDateOnly} from "./Base/Formatters";
import {Button} from "react-bootstrap";

export class FileListItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.download = this.download.bind(this);
        this.delete = this.delete.bind(this);
    }

    download() {
        this.props.download(this.props.model.fileName);
    }

    delete() {
        this.props.delete(this.props.model.fileName);
    }

    render() {
        const model = this.props.model;

        return (
            <div className="file-list-item order-list-item">
                <div className="file-list-item fileName user-list-value fullName" onClick={this.download}>{model.fileName}</div>
                <div className="file-list-item uploadDate user-list-value lastLogin">
                    <div>Upload Date:</div>
                    <div><FormattedDateOnly value={model.uploadDate}/></div>
                </div>
                <div className="file-list-item description user-list-value email">{model.description}</div>
                <div className="user-list-value actions">
                    <div>
                        <Button variant="primary" className="mr-2" onClick={this.download}>
                            <i className="fa fa-download"/><span className="ml-1">Download</span>
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                            <i className="far fa-trash-alt"/><span className="ml-1">Delete</span>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}