import React from 'react';
import CardBlock from './Card.js';
import {
    Card,
    Button,
    Modal,
    Form, 
    Badge
} from "react-bootstrap";
import PropTypes from "prop-types";
import {ContentWidgetCardModel} from "../../api/clients";
import HomeCardImageSwiperNoZoom from "./HomeCardImageSwiperNoZoom";

function ModalCard(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
        >
            {props.children}
        </Modal>
    );
}

export default class CardShortcut extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            modalShow: props.openOnLoad ?? false,
            initialSlide: props.openOnLoad
        };

        this.showCardDetails = this.showCardDetails.bind(this);
        this.setModalHide = this.setModalHide.bind(this);
    }

    showCardDetails(index) {
        this.setState({
            modalShow: true,
            initialSlide: index 
        });
    }

    setModalHide() {
        this.setState({
            modalShow: false
        });
    }

    render() {
        const { data } = this.props;
        return (
            <Card className="widget-card-shortcut rounded-0">
                <div className="card-shortcut-image">
                    <Badge className="badge-lg" pill variant="primary">{data.badgeText}</Badge>
                    <div className="badge-spacer" hidden={data.badgeText} />
                    <HomeCardImageSwiperNoZoom images={data.images} />
                </div>
                <Card.Body className="widget-card-body">
                    <h3 className="card-title">{data.title}</h3>
                </Card.Body>
                <div className="widget-card-footer">
                    <div className="card-control-buttons">
                        <Form.Group>
                            <Button className="btn btn-red-orange" block onClick={() => this.showCardDetails()}>See Details</Button>
                        </Form.Group>
                        <ModalCard
                            show={this.state.modalShow}
                            onHide={this.setModalHide}
                        >
                            <CardBlock data={data} onHide={this.setModalHide} goToUrl={this.props.goToUrl} initialSlide={this.state.initialSlide} />
                        </ModalCard>
                    </div>
                </div>
            </Card>
        )
    }
}

CardShortcut.propTypes = {
    data: PropTypes.shape(ContentWidgetCardModel)
};