import React, {PureComponent} from "react";
import CardShortcut from "./CardShortcut";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api as ordersApi} from "../../store/Orders";
import {Card} from "react-bootstrap";

class CatalogProductList extends PureComponent {
    
    render() {
        return (
            <>
                {this.props.productList?.length ? this.props.productList.map((item, index) => 
                    <CardShortcut data={item} productsMap={this.props.orderProductsMap}
                                         location={this.props.searchFilter?.idLocation}
                                         order={this.props.searchFilter?.idActiveOrder}
                                         isLoading={this.props.orderIsLoading} key={index}
                                         addToOrder={this.props.addToOrder}/>
                ) :
                    <Card className="card-shortcut border-0 rounded-0 my-3 mx-lg-3 mx-md-2 mx-sm-2" key={0}>
                        {/* fixme button tag */}
                        <button className="text-no-data"><i className="fas fa-warehouse fa-2x" />
                        <br />
                            This product(s) is not currently available. Please check back for availability or reach out to your iLevel Rep to submit a pre-commit.
                        </button>
                    </Card>
                }
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            productList: state.catalog.productList,
            searchFilter: state.searchFilter.searchFilter,
            orderIsLoading: state.order.isLoading,
            orderProductsMap: state.order.productsMap,
        };
    },
    dispatch => bindActionCreators({
        addToOrder: ordersApi.addToOrder
    }, dispatch)
)(CatalogProductList);