import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../store/Profile";
import {Container, Button, Card, Alert} from "react-bootstrap";
import Loading from "./Base/Loading";
import Formsy from "formsy-react";
import ValidatedFormGroupControl from "./Base/ValidatedFormGroupControl";
import ValidatedFormGroupControlSelect from "./Base/ValidatedFormGroupControlSelect";
import {LocationNav} from "./LocationNav";

class LocationDistributor extends React.Component {
    initialState = {
        warehouses: undefined,
        selectedWarehouses: [],
        submitted: false
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.isWarehouseSelected = this.isWarehouseSelected.bind(this);
        this.onSupplierChange = this.onSupplierChange.bind(this);
        this.onWarehousesChange = this.onWarehousesChange.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
    }

    componentDidMount() {
        this.props.requestLocationDistributorAssignment();
    }

    submit(model) {
        this.props.addLocationDistributorAssignment({...model,
            warehouseIds: this.state.selectedWarehouses,
            idLocation: this.props.getLocationIdFromParams(this.props.match.params)});
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    validate() {
        this.setState({
            submitted: true
        });
    }

    onSupplierChange(value) {
        this.setState({
            warehouses: this.props.suppliers?.find(c => c.id === parseInt(value))?.warehouses ?? []
        })
    }

    onWarehousesChange(values) {
        this.setState({
            selectedWarehouses: values?.map(o => o.value)
        });
    }

    isWarehouseSelected(value) {
        return this.state.selectedWarehouses.indexOf(value) !== -1;
    }

    render() {
        return (
            <Container className="registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    <Formsy onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors}
                            autoComplete="off">
                        <Card className="registration-form-wrapper">
                            <Card.Header className="p-0">
                                <LocationNav idLocation={this.props.getLocationIdFromParams(this.props.match.params)}/>
                            </Card.Header>
                            <Card.Body className="registration-form">

                                {
                                    this.props.globalErrors && this.props.globalErrors.length > 0 ?
                                        <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                                            <Alert.Heading>You've got an error!</Alert.Heading>
                                            <hr/>
                                            {this.props.globalErrors?.map(err => {
                                                return `${err}\n`;
                                            })}
                                        </Alert>
                                        : (!this.props.isLoading && this.props.successMessage ?
                                        <Alert variant="success">{this.props.successMessage}</Alert>
                                        : '')
                                }

                                <div className="registration-form-separator">
                                    <b/>
                                    <div className="registration-form-separator-text"><h2>Add Distributor and Warehouse Assignment</h2></div>
                                    <b/>
                                </div>

                                <div className="registration-form-block">

                                    <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                     id="idSupplier"
                                                                     label="Distributor"
                                                                     name="idSupplier"
                                                                     onChange={this.onSupplierChange}
                                                                     validationError="Distributor is required."
                                                                     autoComplete="off" required>
                                        <option key={0} value={0}>Select Distributor</option>
                                        {this.props.suppliers?.map((s, i) =>
                                            <option key={i+1} value={s.id}>{s.name}</option>
                                        )}
                                    </ValidatedFormGroupControlSelect>

                                    {
                                        this.state.warehouses?.length > 0 ?
                                            <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                             id="warehouseIds"
                                                                             label="Warehouses"
                                                                             name="warehouseIds"
                                                                             placeholder="Select Warehouses"
                                                                             multiple={true}
                                                                             options={this.state.warehouses?.map(
                                                                                 wh => ({'value': wh.id, 'label': wh.name}))}
                                                                             value={[]}
                                                                             onChange={this.onWarehousesChange}
                                                                             validationError="Warehouses are required."
                                                                             autoComplete="off" required />
                                            : null
                                    }

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="accountNumber"
                                                               type="text" label="Account Number"
                                                               name="accountNumber"
                                                               autoComplete="off" />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="supplierRep"
                                                               type="text" label="Distributor Rep"
                                                               name="supplierRep"
                                                               autoComplete="off" />

                                </div>
                            </Card.Body>
                            <Card.Footer className="registration-form-action-buttons">
                                <div><Button type="submit" variant="primary">SAVE</Button></div>
                            </Card.Footer>
                        </Card>
                    </Formsy>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(api, dispatch)
)(LocationDistributor);