import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {api as ordersApi} from "../store/Orders";
import TreeViewMenu from 'react-simple-tree-menu';
import {Card, Form, ListGroup} from "react-bootstrap";
import {api as catalogApi} from "../store/Catalog";
import {api as accountApi} from "../store/Account";
import {api as searchApi} from "../store/SearchFilter";
import Loading from "./Base/Loading";
import * as B from "react-bootstrap";
import CatalogProductList from "./Catalog/CatalogProductList";

class Catalog extends Component {
    constructor(props) {
        super(props);

        this.dismissErrors = this.dismissErrors.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.loadNextPage = this.loadNextPage.bind(this);
    }

    componentDidMount() {
        const filter = this.props.searchFilter;

        if (this.props.router?.location?.search) {
            this.props.requestCatalog(filter, true, true);
        } else if (filter) {
            this.props.requestCatalog(filter, true);
        } else if (!this.props.loggedIn) {
            this.props.openLoginForm();
        }

        if (filter) {
            this.props.requestOrder(filter.idActiveOrder, filter.idLocation);
        }
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    changeCategory(id) {
        this.props.changeCategoryWithReload(id);
    }

    loadNextPage() {
        this.props.requestCatalogNextPage();
    }

    render() {
        return (
            <>
                <div className="catalog-wrapper">
                    <div className="search-wrapper">
                        <div className="search-categories-container">
                            <Loading active={this.props.isCategoriesLoading} normalOverlay={true}>
                                {this.props.isStateReady ? (
                                    <TreeViewMenu
                                        data={this.props.categories}
                                        activeKey={this.props.selectedCategoryPath}
                                        focusKey={this.props.selectedCategoryPath}
                                        initialOpenNodes={this.props.selectedCategoryOpenNodes}
                                        onClickItem={({id}) => this.changeCategory(id)}
                                        debounceTime={125}>
                                        {({search, items, resetOpenNodes}) => (
                                            <>
                                                <Form.Control onChange={e => search(e.target.value)} defaultValue=""
                                                              placeholder="Search category..."/>
                                                <ListGroup>
                                                    {items.map(({
                                                                    level,
                                                                    key,
                                                                    hasNodes,
                                                                    isOpen,
                                                                    label,
                                                                    toggleNode,
                                                                    onClick,
                                                                    active
                                                                }) =>
                                                        (hasNodes ?
                                                            <React.Fragment key={key}>
                                                                <ListGroup.Item style={{paddingLeft: (15 * (level + 1))}}
                                                                                onClick={() => isOpen ? toggleNode() : resetOpenNodes([key], this.props.selectedCategoryPath)}><i
                                                                    className={isOpen ? "fas fa-caret-down" : "fas fa-caret-right"}
                                                                    onClick={() => isOpen ? toggleNode() : resetOpenNodes([key], this.props.selectedCategoryPath)}/> {label}
                                                                </ListGroup.Item>
                                                            </React.Fragment>
                                                            :
                                                            <ListGroup.Item key={key} style={{paddingLeft: (15 * (level + 2))}}
                                                                            onClick={onClick}
                                                                            active={active}>{label}</ListGroup.Item>))}
                                                </ListGroup>
                                            </>
                                        )}
                                    </TreeViewMenu>
                                ) : null}
                            </Loading>
                        </div>
                    </div>
                    <Loading active={this.props.isLoading}>
                        <div className="catalog">
                            <CatalogProductList />
                            <Card className="card-shortcut border-0 rounded-0 my-3 mx-lg-3 mx-md-2 mx-sm-2" key={this.props.productList?.length ?? 0} hidden={!this.props.hasMore}>
                                <B.Button variant="secondary" className="btn-load-more" onClick={this.loadNextPage}>Load More Products<br /><i className="far fa-plus-square fa-2x" /></B.Button>
                            </Card>
                        </div>
                    </Loading>
                </div>
            </>
        );
    }
}

export default connect(
    (state) => {
        return {
            ...state.catalog,
            searchFilter: state.searchFilter.searchFilter,
            orderIsLoading: state.order.isLoading,
            orderProductsMap: state.order.productsMap,
            loggedIn: state.account.loggedIn,
            router: state.router
        };
    },
    dispatch => bindActionCreators({
        ...catalogApi,
        changeCategoryWithReload: searchApi.changeCategoryWithReload,
        addToOrder: ordersApi.addToOrder,
        requestOrder: ordersApi.requestOrder,
        openLoginForm: accountApi.openLoginForm
    }, dispatch)
)(Catalog);