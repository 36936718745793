import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as B from "react-bootstrap";
import {api as searchPopupApi} from "../store/SearchPopup";
import Loading from "./Base/Loading";

class SearchPopup extends React.Component {
    constructor(props) {
        super(props);

        this.changeDistributor = this.changeDistributor.bind(this);
        this.changeWarehouse = this.changeWarehouse.bind(this);
        this.modalHandleClose = this.modalHandleClose.bind(this);
        this.modalHandleSave = this.modalHandleSave.bind(this);
    }

    changeDistributor(event) {
        this.props.changeDistributor(parseInt(event.currentTarget.value));
    }

    changeWarehouse(event) {
        this.props.changeWarehouse(parseInt(event.currentTarget.value));
    }

    modalHandleClose() {
        this.props.hidePopup();
    }

    modalHandleSave(event) {
        event.preventDefault();
        const {selectedDistributor, selectedWarehouse} = this.props;

        if (this.props.router.location.pathname === '/catalog') {
            this.props.sendPopupResultDataThenRunSearch(selectedDistributor, selectedWarehouse);
        } else {
            this.props.sendPopupResultData(selectedDistributor, selectedWarehouse);
        }

        this.props.hidePopup();
    }

    render() {
        return (
            <B.Modal show={this.props.showPopup} onHide={this.modalHandleClose} size="lg" centered={true}>
                <B.Form onSubmit={this.modalHandleSave}>
                    <B.Modal.Header closeButton>
                        <B.Modal.Title>Select Your Distributor and Warehouse</B.Modal.Title>
                    </B.Modal.Header>
                    <B.Modal.Body>
                        <Loading active={this.props.isLoading} className="loading-indicator" normalOverlay={true}>
                            <div className="bottom-form">
                                <B.FormGroup>
                                    <B.Row>
                                        <B.Col xs={2} sm={2} md={2}>
                                            <B.Form.Label htmlFor="distributor">Distributor</B.Form.Label>
                                        </B.Col>
                                        <B.Col xs={10} sm={10} md={10}>
                                            <B.Form.Control as="select" id="distributor" value={this.props.selectedDistributor}
                                                            onChange={this.changeDistributor}>
                                                <option key={0} value={0}>All Distributors</option>
                                                {this.props.distributors?.map((dist) => {
                                                    return <option key={dist.id}
                                                                   value={dist.id}>{dist.name}</option>;
                                                })}
                                            </B.Form.Control>
                                        </B.Col>
                                    </B.Row>
                                </B.FormGroup>
                                <B.FormGroup>
                                    <B.Row>
                                        <B.Col xs={2} sm={2} md={2}>
                                            <B.Form.Label htmlFor="warehouse">Warehouse</B.Form.Label>
                                        </B.Col>
                                        <B.Col xs={10} sm={10} md={10}>
                                            <B.Form.Control as="select" id="warehouse" value={this.props.selectedWarehouse}
                                                            onChange={this.changeWarehouse}>
                                                <option key={0} value={0}>All Warehouses</option>
                                                {this.props.warehouses?.map((wh) => {
                                                    return <option key={wh.id} value={wh.id}>{wh.name}</option>;
                                                })}
                                            </B.Form.Control>
                                        </B.Col>
                                    </B.Row>
                                </B.FormGroup>
                            </div>
                        </Loading>
                    </B.Modal.Body>
                    <B.Modal.Footer>
                        <B.Button variant="secondary" onClick={this.modalHandleClose}>Close</B.Button>
                        <B.Button type="submit" variant="primary" onClick={this.modalHandleSave}>Save Changes</B.Button>
                    </B.Modal.Footer>
                </B.Form>
            </B.Modal>
        );
    }
}

export default connect(
    (state) => {
        return {
            ...state.searchPopup,
            router: state.router
        };
    },
    dispatch => bindActionCreators(searchPopupApi, dispatch)
)(SearchPopup);