import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {
    AccountClient, ActivateUserModel,
    BuyerClient, CountryListItemModel, CreateAccountModel,
    RegisterBuyerModel, SettingsClient, StateListItemModel, SupplierClient, SupplierListItem
} from "../api/clients";
import {api as accountApi} from "./Account";
import {BaseErrorsContainer} from "./Base/BaseInterfaces";
import {IResult, processBackendCall, processErrors} from "./Base/Backend";
import {parse} from "query-string";

interface state extends BaseErrorsContainer {
    suppliers: SupplierListItem[] | undefined,
    countries: CountryListItemModel[] | undefined,
    states: StateListItemModel[] | undefined,
    model: RegisterBuyerModel | undefined,
    activateUserModel: ActivateUserModel | undefined,
    token: string | undefined,
    successMessage: string | undefined,
    isLoading: boolean,
}

const initialState: state = {
    suppliers: undefined,
    countries: undefined,
    states: undefined,
    model: undefined,
    activateUserModel: undefined,
    token: undefined,
    successMessage: undefined,
    success: true,
    errors: null,
    globalErrors: undefined,
    isLoading: false
};

const registrationSlice = createSlice({
    name: "registration",
    initialState: initialState,
    reducers: {
        requestRegistration: (state: Draft<state>) => {
            state.isLoading = true;
        },
        receiveRegistrationPrototype: (state: Draft<state>,
                                       action: PayloadAction<IResult<RegisterBuyerModel>>) => {
            state.success = true;
            state.model = action.payload.data;
            state.states = state.countries?.find(c => c.id === state.model?.address?.country)?.states;
            state.isLoading = false;
        },
        receiveSuccessResult: (state: Draft<state>, action: PayloadAction<string | undefined>) => {
            state.errors = undefined;
            state.globalErrors = undefined;
            state.success = true;
            state.successMessage = action.payload;
            state.isLoading = false;
        },
        receiveErrorResult: (state: Draft<state>, action: PayloadAction<BaseErrorsContainer | undefined>) => {
            state.errors = action.payload?.errors;
            state.globalErrors = action.payload?.globalErrors;

            if (action.payload && !action.payload?.success
                && (!state.errors || state.errors.length === 0)
                && (!state.globalErrors || state.globalErrors.length === 0)) {
                state.globalErrors = ["Something unexpected happened."];
            }

            state.isLoading = false;
        },
        clearErrors: (state: Draft<state>) => {
            state.globalErrors = undefined;
        },
        clearSuccessMessage: (state: Draft<state>) => {
            state.successMessage = undefined;
        },
        changeCountries: (state: Draft<state>, action: PayloadAction<CountryListItemModel[] | undefined>) => {
            state.countries = action.payload;
        },
        changeStates: (state: Draft<state>, action: PayloadAction<StateListItemModel[] | undefined>) => {
            state.states = action.payload;
        },
        changeSuppliers: (state: Draft<state>, action: PayloadAction<SupplierListItem[] | undefined>) => {
            state.suppliers = action.payload;
        },
        changeRecaptcha: (state: Draft<state>, action: PayloadAction<string | undefined>) => {
            if (!state.model) {
                state.model = new RegisterBuyerModel();
            }

            state.model.recaptcha = action.payload;
        },
        changeToken: (state: Draft<state>, action: PayloadAction<string | undefined>) => {
            state.token = action.payload;
        },
        receiveActivateUserModel: (state: Draft<state>, action: PayloadAction<IResult<ActivateUserModel>>) => {
            state.success = true;
            state.activateUserModel = action.payload.data;
            state.isLoading = false;
        },
    }
});

export const {reducer} = registrationSlice;
const {actions} = registrationSlice;

export const api = {
    ...actions,
    requestRegistrationPrototype: () => async (dispatch: any) => {
        dispatch(actions.requestRegistration());
        const countries = (await new SettingsClient().getCountries())?.data ?? [];
        dispatch(actions.changeCountries(Object.values(countries)));
        const suppliers = (await new SupplierClient().getSuppliersPublic())?.data?.items ?? [];
        dispatch(actions.changeSuppliers(suppliers));

        return await processBackendCall(
            () => new BuyerClient().createBuyerPrototypeForRegistration(),
            data => dispatch(actions.receiveRegistrationPrototype(data)),
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
    requestRegistration: (model: RegisterBuyerModel) => async (dispatch: any) => {
        dispatch(actions.requestRegistration());

        return await processBackendCall(
            () => new BuyerClient().registerBuyer(model),
            () => dispatch(actions.receiveSuccessResult("Pending registration completed. " +
                "You will be contacted with further instructions after your registration become reviewed.")),
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
    requestActivateUserModel: (router: any) => async (dispatch: any) => {
        const params = router?.location?.search;

        if (params) {
            const paramsParsed = parse(params);

            if (paramsParsed["pid"] && paramsParsed["token"]) {
                const token: string | undefined = paramsParsed["token"] as string;
                dispatch(actions.requestRegistration());
                dispatch(actions.changeToken(token));

                return await processBackendCall(
                    () => new AccountClient().getUser(paramsParsed["pid"] as string, token),
                    data => dispatch(actions.receiveActivateUserModel(data)),
                    messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
            }
        }
    },
    requestActivation: (model: CreateAccountModel) => async (dispatch: any) => {
        dispatch(actions.requestRegistration());

        return await processBackendCall(
        async () => {
                const result = await new AccountClient().activate(model);

                if (result.messages?.length === 1 && result.messages[0].code === '0') {
                    result.success = true;
                }

                return result;
            },
            result => dispatch(result.messages?.length !== 1 || result.messages[0].code !== '0' ?
                accountApi.login() : actions.receiveSuccessResult('Account Currently Locked. ' +
                    'Thank you for registering. Your account is in locked pending review. Reviews generally take up to one business day. You will receive a follow-up email once this has been completed letting you know that all is good or if there is any other information we may need to activate the account.'
                    )),
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages))
        );
    },
};