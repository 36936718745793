import React from 'react';
import Datetime from "react-datetime";

const DateInput = (props) => (
        <div>
            <Datetime {...props} timeFormat={false} locale="en-US" />
        </div>
)

DateInput.propTypes = {
    ...Datetime.propTypes
};

export default DateInput;