import React from 'react';
import {
    Button, Card, Badge
} from "react-bootstrap";
import PropTypes from "prop-types";
import {ProductGridItemModel} from "../../api/clients";
import PureHtml from "../Base/PureHtml";
import HomeCardImageSwiper from "./HomeCardImageSwiper";

export default class CardBlock extends React.Component {
    constructor(props) {
        super(props);
        
        this.goToUrl = this.goToUrl.bind(this);
    }

    goToUrl() {
        if (this.props.data.url) {
            this.props.goToUrl(this.props.data.url);
        }
    }

    render() {
        const {data} = this.props;
        return (
            <Card>
                <Card.Body className="card-flex">
                    <div className="card-image">
                        <Badge className="badge-lg" pill variant="primary">{data.badgeText}</Badge>
                        <HomeCardImageSwiper images={data.images} initialSlide={this.props.initialSlide} />
                    </div>
                    <div className="card-content">
                        <h4 className="card-title">{data.title}</h4>
                        <div className="card-content-scrollable">
                            <div id="product_description" className="card-content-extended">
                                <PureHtml data={data.description}/>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer className="product-detail-card-footer">
                    <div className="left">
                        <Button variant="secondary" className="card-button" onClick={this.props.onHide}>Close</Button>
                    </div>
                    <div className="right">
                        <Button variant="primary" onClick={this.goToUrl} hidden={!this.props.data.url} className="card-button">See Products</Button>
                    </div>
                </Card.Footer>
            </Card>
        );
    }
}

CardBlock.propTypes = {
    onHide: PropTypes.func,
    data: PropTypes.shape(ProductGridItemModel),
    initialSlide: PropTypes.number
};