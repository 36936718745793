import React from 'react';
import {Nav} from "react-bootstrap";
import {NavLink as RRNavLink} from "react-router-dom";

export class LocationNav extends React.PureComponent {
    render() {
        const idLocation = this.props.idLocation ? this.props.idLocation : this.props.location?.id;

        return (

            <Nav className="location-menu-section">
                <Nav.Item key={0}>
                    <Nav.Link as={RRNavLink} className="text-orange"
                                activeClassName="active"
                                exact={true} to={"/locations/" + idLocation}>Location
                        Details</Nav.Link>
                </Nav.Item>
                <Nav.Item key={1}>
                    <Nav.Link as={RRNavLink} className="text-orange"
                                activeClassName="active"
                                to={"/locations/" + idLocation + "/users"}>Location
                        Managers</Nav.Link>
                </Nav.Item>
                <Nav.Item key={2}>
                    <Nav.Link as={RRNavLink} className="text-orange"
                                activeClassName="active"
                                to={"/locations/" + idLocation + "/distributors"}>Distributors
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item key={3}>
                    <Nav.Link as={RRNavLink} className="text-orange"
                                activeClassName="active"
                                to={"/locations/" + idLocation + "/files"}>Files
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        );
    }
}