import React from "react";
import {NavLink as RRNavLink} from 'react-router-dom';
import Formsy from "formsy-react";
import {Card, Alert, FormControl, FormGroup, FormLabel} from "react-bootstrap";
import ValidatedFormGroupControl from "./Base/ValidatedFormGroupControl";
import emailRegex from "./Base/EmailRegex";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api as loginApi} from "../store/Account";

class LoginForm extends React.Component {
    initialState = {
        submitted: false,
        forgotPassword: false
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.forgotPassword = this.forgotPassword.bind(this);
        this.dismissForgotPassword = this.dismissForgotPassword.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
        this.dismissSuccess = this.dismissSuccess.bind(this);
    }

    submit(model) {
        if (this.state.forgotPassword) {
            this.props.requestForgotPasswordEmail(model);
        } else {
            this.props.requestLogin(model);
        }
    }

    forgotPassword() {
        this.setState({
            forgotPassword: true
        });
    }

    dismissForgotPassword() {
        this.setState({
            forgotPassword: false
        });
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    dismissSuccess() {
        this.props.clearSuccessMessage();
        this.setState(this.initialState);
    }

    validate() {
        this.setState({
            submitted: true
        });
    }

    render() {
        return this.state.submitted && this.props.success && this.props.successMessage ?
            (this.state.forgotPassword ?
                <Alert variant="success" dismissible onClose={this.dismissSuccess}>
                    <Alert.Heading>Success!</Alert.Heading>
                    <hr/>
                    {this.props.successMessage}
                </Alert> : <Alert variant="info">
                    <Alert.Heading>Account Currently Locked</Alert.Heading>
                    <hr/>
                    Account activation successful. No further action needed by you at this time.
                    Your account is in locked pending review. Reviews generally take up to one
                    business day. You will receive a follow-up email once this has been
                    completed letting you know that all is good or if there is any other
                    information we may need to activate the account.
                </Alert>) : <Formsy onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors}
                                    autoComplete="on">
                <Card>
                    <Card.Body className="login-form">
                        {
                            this.state.submitted && this.props.globalErrors && this.props.globalErrors.length > 0 ? (
                                <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                                    <Alert.Heading>You've got an error!</Alert.Heading>
                                    <hr/>
                                    {this.props.globalErrors?.map(err => {
                                        return `${err}\n`;
                                    })}
                                </Alert>) : null
                        }
                        <FormLabel>To see further detail please login</FormLabel>
                        <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                   id="email"
                                                   type="email" label="Email"
                                                   name="email"
                                                   validations={{
                                                       isEmail: true,
                                                       matchRegexp: emailRegex
                                                   }}
                                                   validationError="This is not a valid email"
                                                   autoComplete="email" required/>

                        {
                            this.state.forgotPassword ?
                        <>
                            <FormGroup>
                                <FormControl type="submit" value="RESET PASSWORD"
                                             className="btn btn-primary"/>
                            </FormGroup>
                            <FormGroup>
                                <FormControl as="button" onClick={this.dismissForgotPassword}>Want
                                    to login?</FormControl>
                            </FormGroup>
                        </>
                                :
                        <>
                            <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                       id="password"
                                                       type="password" label="Password"
                                                       name="password"
                                                       validations={{
                                                           minLength: 8
                                                       }}
                                                       validationError="Password required"
                                                       autoComplete="current-password" required/>
                            <FormGroup>
                                <FormControl type="submit" value="LOGIN"
                                             className="btn btn-success"/>
                            </FormGroup>
                            <FormGroup>
                                <FormControl as="button" onClick={this.forgotPassword}
                                             className="btn btn-primary">Forgot
                                    password?</FormControl>
                            </FormGroup>
                            <FormGroup className="mb-0">
                                <FormControl as={RRNavLink} onClick={this.props.closeLoginForm}
                                             className="btn btn-red-orange"
                                             exact={true} to="/registration">Register New
                                    Account</FormControl>
                            </FormGroup>
                        </>
                        }
                    </Card.Body>
                </Card></Formsy>
        ;
    }
}

export default connect(
    (state) => state.account,
    dispatch => bindActionCreators(loginApi, dispatch)
)(LoginForm);