import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Alert, Button, Card, Container} from "react-bootstrap";
import Loading from "./Base/Loading";
import {LocationNav} from "./LocationNav";
import {FileListItem} from "./FileListItem";
import {api} from "../store/Profile";

class LocationFiles extends React.Component {
    constructor(props) {
        super(props);

        this.add = this.add.bind(this);
        this.download = this.download.bind(this);
        this.remove = this.remove.bind(this);
    }

    componentDidMount() {
        this.props.requestLocationFiles(this.props.getLocationIdFromParams(this.props.match.params));
    }

    add() {
        this.props.viewLocationFile(this.props.getLocationIdFromParams(this.props.match.params));
    }

    download(filename) {
        this.props.downloadLocationFile(filename, this.props.getLocationIdFromParams(this.props.match.params));
    }

    remove(filename) {
        this.props.removeLocationFile(filename, this.props.getLocationIdFromParams(this.props.match.params));
    }

    render() {
        return (
            <Container className="order-list-container registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    {
                        !this.props.isLoading && this.props.globalErrors && this.props.globalErrors.length > 0 ?
                            <Alert variant="danger">
                                <Alert.Heading>You've got an error!</Alert.Heading>
                                <hr/>
                                {this.props.globalErrors?.map(err => `${err}\n`)}
                            </Alert>
                            : (!this.props.isLoading && this.props.successMessage ?
                            <Alert variant="success">{this.props.successMessage}</Alert>
                            : '')
                    }

                    <Card className="registration-form-wrapper">
                        <Card.Header className="p-0">
                            <LocationNav idLocation={this.props.getLocationIdFromParams(this.props.match.params)}/>
                        </Card.Header>
                        <Card.Body className="registration-form-action-buttons">
                            <div><Button variant="primary" onClick={this.add}>
                                Upload File</Button></div>
                        </Card.Body>
                        <Card.Body className="registration-form">

                            <div className="registration-form-separator">
                                <b/>
                                <div className="registration-form-separator-text"><h2>Files</h2></div>
                                <b/>
                            </div>

                            <div className="order-list-items">
                                { this.props.files?.map((file, index) =>
                                    <FileListItem model={file} key={index} delete={this.remove} download={this.download} />
                                )}

                                {/*<Card className="order-list-item border-0 rounded-0 p-0 m-0"*/}
                                {/*      key={this.props.list?.length ?? 0} hidden={!this.props.hasMore}>*/}
                                {/*    <Button variant="secondary" className="btn-load-more"*/}
                                {/*            onClick={this.props.requestListNextPage}><span*/}
                                {/*        className="baseline-text">Load More</span><i*/}
                                {/*        className="far fa-plus-square fa-2x"/></Button>*/}
                                {/*</Card>*/}

                            </div>
                        </Card.Body>
                        {/*<Card.Footer className="registration-form-action-buttons">*/}
                        {/*    <div><Button variant="primary" onClick={this.add}>*/}
                        {/*        Add New</Button></div>*/}
                        {/*</Card.Footer>*/}
                    </Card>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(api, dispatch)
)(LocationFiles);