import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {
    MessageInfo, HomePageClient, HomePageContainer, ContentWidgetModel
} from "../api/clients";
import {parseErrors} from "./apiExceptionFilter";
import {processBackendCall} from "./Base/Backend";
import {push} from "connected-react-router";

interface state {
    globalErrors: string[] | undefined,
    homePage: HomePageContainer | undefined,
    menuItems: ContentWidgetModel[] | undefined,
    isLoading: boolean
}

const initialState: state = {
    globalErrors: undefined,
    homePage: undefined,
    menuItems: undefined,
    isLoading: false
};

const homeSlice = createSlice({
    name: "home",
    initialState: initialState,
    reducers: {
        requestHomePage: (state: Draft<state>) => {
            state.isLoading = true;
        },
        receiveHomePage: (state: Draft<state>, action: PayloadAction<HomePageContainer | undefined>) => {
            const homePage = action.payload;
            state.homePage = homePage;
            state.menuItems = homePage?.widgets?.filter(x => x.mapToMenu);
            state.isLoading = false;
        },
        receiveErrors: (state: Draft<state>, action) => {
            state.globalErrors = action.payload.globalErrors;
            state.isLoading = false;
        },
        clearErrors: (state: Draft<state>) => {
            state.globalErrors = undefined;
        }
    }
});

export const {reducer} = homeSlice;
const {actions} = homeSlice;

const processHomeErrors = (messages?: MessageInfo[]) => {
    const errors: any = {};
    const globalErrors = parseErrors(messages, errors);
    return {errors, globalErrors};
};

export const api = {
    ...actions,
    requestHomePage: () => async (dispatch: any) => {
        dispatch(actions.requestHomePage());
        const client = new HomePageClient();

        return await processBackendCall(
            () => client.getHomePage(),
            result => {
                const homePageData = result.data;
                return dispatch(actions.receiveHomePage(homePageData));
            },
            (messages) => dispatch(actions.receiveErrors(processHomeErrors(messages)))
        );
    },
    goToUrl: (url: string) => (dispatch: any) => {
        return dispatch(push(url));
    }
};