import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../store/Profile";
import {Container, Button, Card, Alert} from "react-bootstrap";
import Loading from "./Base/Loading";
import Formsy from "formsy-react";
import ValidatedFormGroupControl from "./Base/ValidatedFormGroupControl";
import emailRegex from "./Base/EmailRegex";
import ValidatedFormGroupControlSelect from "./Base/ValidatedFormGroupControlSelect";

class Store extends React.Component {
    initialState = {
        country: undefined,
        states: undefined,
        state: undefined,
        corporateAccount: undefined,
        submitted: false
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.onCorporateAccountChange = this.onCorporateAccountChange.bind(this);
    }

    componentDidMount() {
        this.props.requestStoreDetails();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const stateDiff = {};

        if (prevState.states === undefined && nextProps.states) {
            stateDiff.states = nextProps.states;
        }

        if (prevState.country === undefined && nextProps.storeDetails?.address?.country) {
            stateDiff.country = nextProps.storeDetails.address.country;
        }

        if (prevState.state === undefined && (nextProps.storeDetails?.address?.state || nextProps.storeDetails?.address?.county)) {
            stateDiff.state = nextProps.states?.length > 0 ? nextProps.storeDetails.address.state : nextProps.storeDetails.address.county;
        }

        if (prevState.corporateAccount === undefined && nextProps.storeDetails?.corporateAccount !== undefined) {
            stateDiff.corporateAccount = nextProps.storeDetails.corporateAccount;
        }

        return stateDiff === {} ? null : stateDiff;
    }

    submit(model) {
        this.props.updateStoreDetails({...this.props.storeDetails, ...model});
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    validate(model) {
        model.corporateAccount = this.state.corporateAccount;
        this.setState({
            submitted: true
        });
    }

    onCountryChange(value) {
        this.setState({
            country: value,
            states: this.props.countries?.find(c => c.id === parseInt(value))?.states ?? []
        })
    }

    onStateChange(value) {
        this.setState({
            state: value
        })
    }

    onCorporateAccountChange(event) {
        this.setState({
            corporateAccount: event.currentTarget.checked
        })
    }

    render() {
        return (
            <Container className="registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    <Formsy onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors}
                            autoComplete="on">
                        <Card className="registration-form-wrapper">
                            <Card.Body className="registration-form">

                                {
                                    this.state.submitted && this.props.globalErrors && this.props.globalErrors.length > 0 ?
                                        <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                                            <Alert.Heading>You've got an error!</Alert.Heading>
                                            <hr/>
                                            {this.props.globalErrors?.map(err => {
                                                return `${err}\n`;
                                            })}
                                        </Alert>
                                        : (this.state.submitted && !this.props.isLoading && this.props.successMessage ?
                                        <Alert variant="success">{this.props.successMessage}</Alert>
                                        : '')
                                }

                                <div className="registration-form-separator">
                                    <b/>
                                    <div className="registration-form-separator-text"><h2>Primary Business Details</h2></div>
                                    <b/>
                                </div>

                                <div className="registration-form-block">
                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="id"
                                                               type="text" label="ID"
                                                               name="id"
                                                               value={this.props.storeDetails?.id}
                                                               autoComplete="off" readOnly />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="accountName"
                                                               type="text" label="Account Name"
                                                               name="accountName"
                                                               value={this.props.storeDetails?.accountName}
                                                               validationError="Account Name is required."
                                                               autoComplete="organization" required />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="email"
                                                               type="email" label="Email"
                                                               name="email"
                                                               value={this.props.storeDetails?.email}
                                                               validations={{matchRegexp: emailRegex}}
                                                               validationError="This is not a valid email"
                                                               autoComplete="off" />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="corporateAccount"
                                                               type="checkbox" label="Is Corporate Account"
                                                               name="corporateAccount"
                                                               onChange={this.onCorporateAccountChange}
                                                               checked={this.state.corporateAccount}
                                                               autoComplete="off" />
                                </div>

                                <div className="registration-form-separator">
                                    <b/>
                                    <div className="registration-form-separator-text"><h2>Primary Address</h2></div>
                                    <b/>
                                </div>

                                <div className="registration-form-block">
                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="address.address1"
                                                               type="text" label="Address 1"
                                                               name="address.address1"
                                                               value={this.props.storeDetails?.address?.address1}
                                                               validationError="Address 1 is required."
                                                               autoComplete="address-line1" required />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="address.address2"
                                                               type="text" label="Address 2"
                                                               name="address.address2"
                                                               value={this.props.storeDetails?.address?.address2}
                                                               autoComplete="address-line2" />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="address.city"
                                                               type="text" label="City"
                                                               name="address.city"
                                                               value={this.props.storeDetails?.address?.city}
                                                               validationError="City is required."
                                                               autoComplete="address-level2" required />

                                    <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                     id="address.country"
                                                                     type="text" label="Country"
                                                                     name="address.country"
                                                                     value={this.state.country}
                                                                     onChange={this.onCountryChange}
                                                                     validationError="Country is required."
                                                                     autoComplete="country-name" required>
                                        <option key={0} value={0}>Select Country</option>
                                        {this.props.countries?.map((c, i) =>
                                            <option key={i+1} value={c.id}>{c.countryName}</option>
                                        )}
                                    </ValidatedFormGroupControlSelect>

                                    {
                                        this.state.states?.length > 0 ?
                                            <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                             id="address.state"
                                                                             type="text" label="State/Province"
                                                                             name="address.state"
                                                                             value={this.state.state ?? 0}
                                                                             onChange={this.onStateChange}
                                                                             validationError="State or Province is required."
                                                                             autoComplete="address-level1" required>
                                                <option key={0} value={0}>Select State</option>
                                                {this.state.states?.map((s, i) =>
                                                    <option key={i+1} value={s.id}>{s.stateName}</option>
                                                )}
                                            </ValidatedFormGroupControlSelect> :

                                            <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                                       id="address.county"
                                                                       type="text" label="State/Province"
                                                                       name="address.county"
                                                                       value={!Number.isInteger(parseInt(this.state.state)) ? this.state.state : ''}
                                                                       validationError="State or Province is required."
                                                                       autoComplete="address-level1" required/>
                                    }

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="address.zip"
                                                               type="text" label="Zip"
                                                               name="address.zip"
                                                               value={this.props.storeDetails?.address?.zip}
                                                               validationError="Zip is required."
                                                               autoComplete="postal-code" required />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="address.phone"
                                                               type="text" label="Phone"
                                                               name="address.phone"
                                                               value={this.props.storeDetails?.address?.phone}
                                                               autoComplete="tel" />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="address.fax"
                                                               type="text" label="Fax"
                                                               name="address.fax"
                                                               value={this.props.storeDetails?.address?.fax}
                                                               autoComplete="off" />
                                </div>
                            </Card.Body>
                            <Card.Footer className="registration-form-action-buttons">
                                <div><Button type="submit" variant="primary">SAVE</Button></div>
                            </Card.Footer>
                        </Card>
                    </Formsy>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(api, dispatch)
)(Store);