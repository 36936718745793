import React from 'react';
import CardBlock from './Card.js';
import {
    Card,
    Button,
    InputGroup,
    FormControl,
    Modal,
    Form,
    OverlayTrigger,
    Tooltip, Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";
import {ProductGridItemModel} from "../../api/clients";
import {FormattedCurrency, FormattedPercent} from "../Base/Formatters";
import CardImageSwiper from "../Base/CardImageSwiper";

function ModalCard(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
        >
            {props.children}
        </Modal>
    );
}

export default class CardShortcut extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShow: false,
            quantity: 1
        };

        this.changeQuantity = this.changeQuantity.bind(this);
        this.showProductDetails = this.showProductDetails.bind(this);
        this.setModalHide = this.setModalHide.bind(this);
    }

    showProductDetails() {
        this.setState({
            modalShow: true
        });
    }

    setModalHide() {
        this.setState({
            modalShow: false
        });
    }

    changeQuantity(event) {
        this.setState({
            quantity: event.currentTarget.value
        });
    }

    render() {
        const {data} = this.props;
        let skuOrdered;
        if (this.props.productsMap) {
            skuOrdered = this.props.productsMap[data.skuId];
        }
        const isInOrder = skuOrdered !== undefined;
        return (
            <Card className="card-shortcut border-0 rounded-0 my-3 mx-lg-3 mx-md-2 mx-sm-2">
                <div className="card-shortcut-image">
                    <CardImageSwiper mainImage={data.details?.mainProductImage ?? data.thumbnail} additionalImages={data.images}/>
                </div>
                <Card.Body className="product-card-body">
                    <h3 className="card-title">{data.name}</h3>
                    <span className="card-brand my-2 text-muted">{data.sku?.brandName}</span>

                    <Card.Text className="card-price-shortcut font-weight-bold my-2"><FormattedCurrency
                        value={data.sku?.wholesalePrice}/></Card.Text>

                    <div className="discount-buttons">
                        {data.hasCaseStack ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-CS">{data.details?.csDescriptions?.map((item, index) => {
                                    return (
                                        <p key={index}>{item}</p>
                                    );
                                })}</Tooltip>}>
                                <Button variant="primary" size="sm"
                                        className="card-CS discount-button">CS</Button>
                            </OverlayTrigger>
                            : null}
                        {data.hasOi ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip id="button-tooltip-OI">{data.details?.oiDescription}</Tooltip>}>
                                <Button variant="success" size="sm"
                                        className="card-OI discount-button">OI:<FormattedPercent
                                    value={data.sku?.oiPercent}/></Button>
                            </OverlayTrigger>
                            : null}
                        {data.hasMcb ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip id="button-tooltip-MCB">{data.details?.mcbDescription}</Tooltip>}>
                                <Button variant="danger" size="sm"
                                        className="card-MCB discount-button">MCB:<FormattedPercent
                                    value={data.sku?.mcbPercent}/></Button>
                            </OverlayTrigger>
                            : null}

                        {data.hasRbp ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip id="button-tooltip-RBP">{data.details?.rbpDescription}</Tooltip>}>
                                <Button variant="info" size="sm"
                                        className="card-RBP discount-button">RBP:<FormattedPercent
                                    value={data.sku?.rbpPercent}/></Button>
                            </OverlayTrigger>
                            : null}
                        {data.hasNp ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip id="button-tooltip-NP">{data.details?.npDescription}</Tooltip>}>
                                <Button variant="dark" size="sm"
                                        className="card-NP discount-button">NP</Button>
                            </OverlayTrigger>
                            : null}
                    </div>
                    <ul className="list-group card-shortcut-info">
                        <li className="list-group-item border-0 pl-0 pb-0 card-distributor">
                            <span>{data.supplierName}</span>
                        </li>
                        <li className="list-group-item border-0 pl-0 pb-0 card-distributor">
                            <span>{data.warehouseName}</span>
                        </li>
                        <li className="list-group-item border-0 pl-0 pb-0 card-distributor">
                            <span>{data.sku?.code}</span>
                        </li>
                        <li className="list-group-item border-0 pl-0 card-distributor">
                            <span>{data.sku?.supplierProductFieldValue}</span>
                        </li>
                    </ul>

                </Card.Body>
                <Card.Footer>
                    <div className="card-control-buttons">
                        <Form.Group>
                            <Button className="btn btn-red-orange" block onClick={this.showProductDetails}>See
                                Details</Button>
                        </Form.Group>
                        <ModalCard
                            show={this.state.modalShow}
                            onHide={this.setModalHide}
                        >
                            <CardBlock data={data} onHide={this.setModalHide}
                                       location={this.props.location} isLoading={this.props.isLoading}
                                       addToOrder={this.props.addToOrder} order={this.props.order} skuOrdered={skuOrdered} isInOrder={isInOrder}/>
                        </ModalCard>
                        <InputGroup>
                            <FormControl
                                placeholder="Qty"
                                aria-label="Qty"
                                aria-describedby="basic-addon2"
                                className="card-qty"
                                value={isInOrder ? (skuOrdered?.quantity ?? 1) : this.state.quantity}
                                onChange={this.changeQuantity}
                                type="number"
                                disabled={isInOrder}
                                isInvalid={this.state.quantity <= 0}
                            />
                            <FormControl.Feedback type="invalid" tooltip={true}>Quantity must be greater than 0.</FormControl.Feedback>
                            <InputGroup.Append>
                                <Button variant={isInOrder ? "success" : "primary"} className="card-button"
                                        onClick={() => this.props.addToOrder(data.productId, this.state.quantity, this.props.location, this.props.order)}
                                        disabled={this.props.isLoading || isInOrder || this.state.quantity <= 0}
                                >
                                    {isInOrder ? <span>Added to Cart!</span> : <span>Add to Cart</span>}
                                    <Spinner className="ml-2" animation="border" size="sm" variant="success"
                                             hidden={!this.props.isLoading || isInOrder}/>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </Card.Footer>
            </Card>
        )
    }
}

CardShortcut.propTypes = {
    data: PropTypes.shape(ProductGridItemModel),
    addToOrder: PropTypes.func
};