import React from "react";
import Formsy from "formsy-react";
import {Card, Alert, Container, Button} from "react-bootstrap";
import ValidatedFormGroupControl from "./Base/ValidatedFormGroupControl";
import emailRegex from "./Base/EmailRegex";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api as loginApi} from "../store/Account";
import Loading from "./Base/Loading";
import Reaptcha from "reaptcha";

class ResetPasswordForm extends React.Component {
    initialState = {
        verified: false,
        submitted: false,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.onVerify = this.onVerify.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
    }

    componentDidMount() {
        this.props.receiveForgotPasswordData(this.props.router);
    }

    submit(model) {
        if (this.props.showResendForgotPasswordEmailButton) {
            this.props.requestForgotPasswordEmail(model.Email);
        } else {
            this.props.resetPassword({...model, token: this.props.token});
        }
        this.captcha.reset();
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    validate() {
        this.setState({
            submitted: true
        });
    }

    onVerify(recaptchaResponse) {
        this.setState({
            verified: true
        });

        if (this.props.changeRecaptcha) {
            this.props.changeRecaptcha(recaptchaResponse);
        }
    };

    render() {
        return <Container className="registration-form-container">
            <Loading active={this.props.isLoading} className="loading-indicator" normalOverlay={true} onlyOverlay={true}>
                {!this.props.isLoading && !this.props.showResetPasswordForm ?
                    <Alert variant="danger">
                        <Alert.Heading>Error! {this.props.globalErrors?.map(err => {
                            return `${err}\n`;
                        })}</Alert.Heading>
                        <hr/>
                        You cannot complete reset password now. You should click on or copy whole text of reset password link at your email message!<br/> If the error repeats please contact support.
                    </Alert> : (!this.props.isLoading && this.props.success && this.props.successMessage ?
                    <Alert variant="info">
                        <Alert.Heading>Account Currently Locked</Alert.Heading>
                        <hr/>
                        Account activation successful. No further action needed by you at this time.
                        Your account is in locked pending review. Reviews generally take up to one
                        business day. You will receive a follow-up email once this has been
                        completed letting you know that all is good or if there is any other
                        information we may need to activate the account.
                    </Alert> :
                    <Formsy hidden={this.props.isLoading} onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors} autoComplete="off">

    <Card className="registration-form-wrapper">
        <Card.Body className="registration-form">
            {
                this.state.submitted && this.props.globalErrors && this.props.globalErrors.length > 0 ? (
                <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                    <Alert.Heading>You've got an error!</Alert.Heading>
                    <hr/>
                    {this.props.globalErrors?.map(err => {
                        return `${err}\n`;
                    })}
                </Alert>) : null
            }

            <div className="registration-form-separator">
                <b/>
                <div className="registration-form-separator-text"><h2>Reset Your Password</h2></div>
                <b/>
            </div>

            <div className="registration-form-block">

                <ValidatedFormGroupControl
                    value={this.props.email}
                    showErrors={this.state.submitted}
                    id="email"
                    type="email"
                    name="email"
                    validations={{
                        isEmail: true,
                        matchRegexp: emailRegex
                    }}
                    validationError="This is not a valid email"
                    autoComplete="off" required readOnly />

            </div>

            {this.props.showResendForgotPasswordEmailButton ?
            '' :
            <>
                <span className="account-numbers-error">*Must be at least 8 characters long, contain at least 1 numerical digit, 1 capital character, 1 lowercase character and 1 special character.<br/>Example: Dontuse!@1</span>

                <div className="registration-form-block">

                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                               id="password"
                                               type="password" label="Password"
                                               name="password"
                                               validations={{
                                                   minLength: 8
                                               }}
                                               validationError="Min password length is 8 characters"
                                               autoComplete="new-password" required />

                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                               id="confirmPassword"
                                               type="password" label="Confirm Password"
                                               name="confirmPassword"
                                               validationError="Confirmation password should equal the password"
                                               validations={{
                                                   equalsField: 'password'
                                               }}
                                                autoComplete="new-password" required />
                </div>
            </>}
        </Card.Body>
        <Card.Footer className="registration-form-action-buttons">
            <div><Reaptcha ref={e => (this.captcha = e)}
                           size={window.innerWidth < 376 ? "compact" : "normal"}
                           sitekey="6Lc6d5AUAAAAAJ1qctpvpF7Sk9PiQjQPGd8LrALy"
                           onVerify={this.onVerify} /></div>
            <div>
                <Button type="submit" variant="primary" disabled={!this.state.verified}>
                    {this.props.showResendForgotPasswordEmailButton ? "RESEND RESET PASSWORD EMAIL" : "RESET PASSWORD"}</Button>
            </div>
        </Card.Footer>
    </Card>
                </Formsy>)}
            </Loading>
        </Container>
    ;}
}

export default connect(
    state => ({...state.account, router: state.router}),
    dispatch => bindActionCreators(loginApi, dispatch)
)(ResetPasswordForm);