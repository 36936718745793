import React from 'react';
import {
    Container,
} from "react-bootstrap";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api as contentApi} from "../store/Content";
import PropTypes from "prop-types";
import {ContentViewModel} from "../api/clients";
import Loading from "./Base/Loading";
import PureHtml from "./Base/PureHtml";

class Content extends React.Component {
    componentDidMount() {
        this.props.requestContentPage(this.props.match.params.url);
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.match.params.url !== this.props.match.params.url) {
            this.props.requestContentPage(this.props.match.params.url);
        }
    }

    render() {
        return (
            <Loading active={this.props.isLoading} normalOverlay={true}>
                <Container className="content-container">
                    <PureHtml data={this.props.contentPage?.body} />
                </Container>
            </Loading>
        );
    }
}

Content.propTypes = {
    contentPage: PropTypes.shape(ContentViewModel)
};

export default connect(
    (state) => {
        return {
            ...state.content,
            router: state.router
        };
    },
    dispatch => bindActionCreators(contentApi, dispatch)
)(Content);