import React from "react";
import Formsy from "formsy-react";
import {Card, Alert, Container, Button} from "react-bootstrap";
import ValidatedFormGroupControl from "../Base/ValidatedFormGroupControl";
import ValidatedFormGroupControlSelect from "../Base/ValidatedFormGroupControlSelect";
import emailRegex from "../Base/EmailRegex";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../../store/Registration";
import Loading from "../Base/Loading";
import Reaptcha from 'reaptcha';

class RegistrationForm extends React.Component {
    initialState = {
        country: undefined,
        states: undefined,
        state: undefined,
        warehouses: undefined,
        verified: false,
        submitted: false
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.onVerify = this.onVerify.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
        this.dismissSuccess = this.dismissSuccess.bind(this);
        this.onCountryChange = this.onCountryChange.bind(this);
        this.onStateChange = this.onStateChange.bind(this);
        this.onSupplierChange = this.onSupplierChange.bind(this);
    }

    componentDidMount() {
        this.props.requestRegistrationPrototype();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const stateDiff = {};

        if (prevState.states === undefined && nextProps.states) {
            stateDiff.states = nextProps.states;
        }

        if (prevState.country === undefined && nextProps.model?.address?.country) {
            stateDiff.country = nextProps.model.address.country;
        }

        if (prevState.state === undefined && (nextProps.model?.address?.state || nextProps.model?.address?.county)) {
            stateDiff.state = nextProps.states?.length > 0 ? nextProps.model.address.state : nextProps.model.address.county;
        }

        return stateDiff === {} ? null : stateDiff;
    }

    submit(model) {
        this.props.requestRegistration({...this.props.model, ...model});
        this.captcha.reset();
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    dismissSuccess() {
        this.props.clearSuccessMessage();
        this.setState(this.initialState);
    }

    validate(model, successCallback, errorCallback) {
        // successCallback(data)
        // errorCallback(errors, invalidate)
        this.setState({
            submitted: true
        });
    }

    onVerify(recaptchaResponse) {
        this.setState({
            verified: true
        });
        this.props.changeRecaptcha(recaptchaResponse);
    };

    onCountryChange(value) {
        this.setState({
            country: value,
            states: this.props.countries?.find(c => c.id === parseInt(value))?.states ?? []
        })
    }

    onStateChange(value) {
        this.setState({
            state: value
        })
    }

    onSupplierChange(value) {
        this.setState({
            warehouses: this.props.suppliers?.find(c => c.id === parseInt(value))?.warehouses ?? []
        })
    }

    render() {
        return (
            <Container className="registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    {!this.props.isLoading && this.props.loggedIn ?
                        <Alert variant="danger">
                            You have already logged in. You should logout to perform a new account registration!
                        </Alert>
                        : (this.state.submitted && this.props.success && this.props.successMessage ?
                            <Alert variant="success" dismissible onClose={this.dismissSuccess}>
                                <Alert.Heading>Success!</Alert.Heading>
                                <hr/>
                                {this.props.successMessage}
                            </Alert>
                            :
                            <Formsy onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors}
                                    autoComplete="on">
                    <Card className="registration-form-wrapper">
                        <Card.Header className="buyer-description" dangerouslySetInnerHTML={{__html: this.props.model?.buyerDescription}} />
                        <Card.Body className="registration-form">

                            {
                                this.state.submitted && this.props.globalErrors && this.props.globalErrors.length > 0 ? (
                                    <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                                        <Alert.Heading>You've got an error!</Alert.Heading>
                                        <hr/>
                                        {this.props.globalErrors?.map(err => {
                                            return `${err}\n`;
                                        })}
                                    </Alert>) : null
                            }
        
                            <div className="registration-form-separator">
                                <b/>
                                <div className="registration-form-separator-text"><h2>Primary Business Details</h2></div>
                                <b/>
                            </div>

                            <div className="registration-form-block">
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="accountName"
                                                           type="text" label="Account Name"
                                                           name="accountName"
                                                           value={this.props.model?.accountName}
                                                           validationError="Account Name is required."
                                                           autoComplete="organization" required />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="email"
                                                           type="email" label="Email"
                                                           name="email"
                                                           value={this.props.model?.email}
                                                           validations={{matchRegexp: emailRegex}}
                                                           validationError="This is not a valid email"
                                                           autoComplete="off" />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="address.address1"
                                                           type="text" label="Address 1"
                                                           name="address.address1"
                                                           value={this.props.model?.address?.address1}
                                                           validationError="Address 1 is required."
                                                           autoComplete="address-line1" required />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="address.address2"
                                                           type="text" label="Address 2"
                                                           name="address.address2"
                                                           value={this.props.model?.address?.address2}
                                                           autoComplete="address-line2" />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="address.city"
                                                           type="text" label="City"
                                                           name="address.city"
                                                           value={this.props.model?.address?.city}
                                                           validationError="City is required."
                                                           autoComplete="address-level2" required />
            
                                <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                           id="address.country"
                                                           type="text" label="Country"
                                                           name="address.country"
                                                           value={this.state.country}
                                                           onChange={this.onCountryChange}
                                                           validationError="Country is required."
                                                           autoComplete="country-name" required>
                                    <option key={0} value={0}>Select Country</option>
                                    {this.props.countries?.map((c, i) =>
                                        <option key={i+1} value={c.id}>{c.countryName}</option>
                                    )}
                                </ValidatedFormGroupControlSelect>

                                {
                                    this.state.states?.length > 0 ?
                                        <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                         id="address.state"
                                                                         type="text" label="State/Province"
                                                                         name="address.state"
                                                                         value={this.state.state}
                                                                         onChange={this.onStateChange}
                                                                         validationError="State or Province is required."
                                                                         autoComplete="address-level1" required>
                                            <option key={0} value={0}>Select State</option>
                                            {this.state.states?.map((s, i) =>
                                                <option key={i+1} value={s.id}>{s.stateName}</option>
                                            )}
                                        </ValidatedFormGroupControlSelect> :

                                        <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                                   id="address.county"
                                                                   type="text" label="State/Province"
                                                                   name="address.county"
                                                                   value={this.state.state}
                                                                   validationError="State or Province is required."
                                                                   autoComplete="address-level1" required/>
                                }

                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="address.zip"
                                                           type="text" label="Zip"
                                                           name="address.zip"
                                                           value={this.props.model?.address?.zip}
                                                           validationError="Zip is required."
                                                           autoComplete="postal-code" required />

                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="address.phone"
                                                           type="text" label="Phone"
                                                           name="address.phone"
                                                           value={this.props.model?.address?.phone}
                                                           autoComplete="tel" />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="address.fax"
                                                           type="text" label="Fax"
                                                           name="address.fax"
                                                           value={this.props.model?.address?.fax}
                                                           autoComplete="off" />
                            </div>

                            <div className="registration-form-separator">
                                <b/>
                                <div className="registration-form-separator-text"><h2>Current Distributor Account Numbers</h2></div>
                                <b/>
                            </div>

                            <span className="account-numbers-error">* Customer must provide one of the following Account Numbers to open an account on Turnpike.</span>

                            <div className="registration-form-block">
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="accountNumberKeHE"
                                                           type="text" label="KeHE"
                                                           name="accountNumberKeHE"
                                                           value={this.props.model?.accountNumberKeHE}
                                                           autoComplete="off" />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="accountNumberThreshold"
                                                           type="text" label="Threshold"
                                                           name="accountNumberThreshold"
                                                           value={this.props.model?.accountNumberThreshold}
                                                           autoComplete="off" />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="accountNumberUNFIEast"
                                                           type="text" label="UNFI East"
                                                           name="accountNumberUNFIEast"
                                                           value={this.props.model?.accountNumberUNFIEast}
                                                           autoComplete="off" />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="accountNumberUNFIWellness"
                                                           type="text" label="UNFI Wellness"
                                                           name="accountNumberUNFIWellness"
                                                           value={this.props.model?.accountNumberUNFIWellness}
                                                           autoComplete="off" />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="accountNumberUNFIWest"
                                                           type="text" label="UNFI West"
                                                           name="accountNumberUNFIWest"
                                                           value={this.props.model?.accountNumberUNFIWest}
                                                           autoComplete="off" />
                            </div>

                            <div className="registration-form-separator">
                                <b/>
                                <div className="registration-form-separator-text"><h2>Primary Admin User Details</h2></div>
                                <b/>
                            </div>

                            <div className="registration-form-block">                            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="userFirstName"
                                                           type="text" label="First Name"
                                                           name="userFirstName"
                                                           value={this.props.model?.userFirstName}
                                                           validationError="First Name is required."
                                                           autoComplete="given-name" required />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="userLastName"
                                                           type="text" label="Last Name"
                                                           name="userLastName"
                                                           value={this.props.model?.userLastName}
                                                           validationError="Last Name is required."
                                                           autoComplete="family-name" required />

                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="userEmail"
                                                           type="email" label="Email"
                                                           name="userEmail"
                                                           value={this.props.model?.userEmail}
                                                           validations={{matchRegexp: emailRegex}}
                                                           validationError="This is not a valid email"
                                                           autoComplete="email" required />

                                <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                           id="preferredIdSupplier"
                                                           label="Preferred Distributor"
                                                           name="preferredIdSupplier"
                                                           value={this.props.model?.preferredIdSupplier}
                                                           onChange={this.onSupplierChange}
                                                           autoComplete="off">
                                    <option key={0} value={0}>Select Distributor</option>
                                    {this.props.suppliers?.map((s, i) =>
                                        <option key={i+1} value={s.id}>{s.name}</option>
                                    )}
                                </ValidatedFormGroupControlSelect>

                                {
                                    this.state.warehouses?.length > 0 ?
                                    <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                     id="preferredIdWarehouse"
                                                                     label="Preferred Warehouse"
                                                                     name="preferredIdWarehouse"
                                                                     value={this.props.model?.preferredIdWarehouse}
                                                                     autoComplete="off">
                                        <option key={0} value={0}>Select Warehouse</option>
                                        {this.state.warehouses?.map((wh, i) =>
                                            <option key={i} value={wh.id}>{wh.name}</option>
                                        )}
                                    </ValidatedFormGroupControlSelect> : ''
                                }
                            </div>
                        </Card.Body>
                        <Card.Footer className="registration-form-action-buttons">
                            <div><Reaptcha ref={e => (this.captcha = e)}
                                           size={window.innerWidth < 376 ? "compact" : "normal"}
                                           sitekey="6Lc6d5AUAAAAAJ1qctpvpF7Sk9PiQjQPGd8LrALy"
                                           onVerify={this.onVerify} /></div>
                            <div><Button type="submit" variant="primary" disabled={!this.state.verified}>REGISTER</Button></div>
                        </Card.Footer>
                    </Card></Formsy>)}
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => ({
        ...state.registration,
        loggedIn: state.account.loggedIn
    }),
    dispatch => bindActionCreators(api, dispatch)
)(RegistrationForm);