import React from "react";
import {Form} from "react-bootstrap";
import {withFormsy} from "formsy-react";
import PropTypes from 'prop-types';
import nextId from "react-id-generator";
import Select from "react-select";

class ValidatedFormGroupControlSelect extends React.Component {
    constructor(props) {
        super(props);

        this.inputId = nextId();
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue(event) {
        const {value} = event.currentTarget;
        this.props.setValue(value);

        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        return this.props.multiple ?
            <Form.Group as={this.props.as}>
                <Form.Label htmlFor={this.props.id ?? this.inputId}>{this.props.label}</Form.Label>
                <Select className="form-control select multi-select"
                        isMulti
                        placeholder={this.props.placeholder}
                        options={this.props.options}
                        defaultValue={this.props.value}
                        onChange={this.props.onChange}
                />
                <Form.Control.Feedback type="invalid">{this.props.errorMessage}</Form.Control.Feedback>
            </Form.Group>
            :
            <Form.Group as={this.props.as}>
                <Form.Label htmlFor={this.props.id ?? this.inputId}>{this.props.label}</Form.Label>
                <Form.Control as="select" id={this.props.id ?? this.inputId} bsPrefix={this.props.bsPrefix}
                              custom={this.props.custom} disabled={this.props.disabled} plaintext={this.props.plaintext}
                              readOnly={this.props.readOnly} size={this.props.size} onChange={this.changeValue}
                              isInvalid={this.props.showErrors && (this.props.showError || this.props.showRequired)}
                              value={this.props.value || ''} autoComplete={this.props.autoComplete}>
                    { this.props.children }
                </Form.Control>
                <Form.Control.Feedback type="invalid">{this.props.errorMessage}</Form.Control.Feedback>
            </Form.Group>
            ;
    }
}

ValidatedFormGroupControlSelect.propTypes = {
    ...Form.Control.propTypes,
    label: PropTypes.string,
    showErrors: PropTypes.bool,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    multiple: PropTypes.bool,
    options: PropTypes.array
};

export default withFormsy(ValidatedFormGroupControlSelect);
