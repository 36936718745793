import {api as orderApi, dataAccessProvider, OrderRetrieveState} from "./Orders";
import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {
    AddUpdateBuyerModel,
    BuyerClient,
    BuyerUserFilter,
    BuyerUserListItemModel,
    BuyerUserModel,
    CountryListItemModel,
    LocationFilter,
    LocationListItemModel,
    BaseLocationManageModel,
    LocationsClient,
    OrderFilter,
    OrderListItemModel,
    OrderStatus,
    OrderViewModel,
    PagedListOfBuyerUserListItemModel,
    PagedListOfLocationListItemModel,
    Paging,
    ReferenceData,
    SettingsClient,
    StateListItemModel,
    SupplierClient,
    SupplierListItem,
    SupplierLocationFilter,
    LocationSupplierListItemModel,
    PagedListOfLocationSupplierListItemModel,
    LocationAssignmentManageModel,
    LocationFile
} from "../api/clients";
import {BaseErrorsContainer} from "./Base/BaseInterfaces";
import * as Enumerable from "linq-es2015";
import {api as productsApi, productsView} from "./ProductDetails";
import {baseHasMoreItems} from "./Catalog";
import * as _ from "lodash";
import {IResult, processBackendCall, processErrors, undefinedIfZero} from "./Base/Backend";
import {createMatchSelector, push} from "connected-react-router";
import moment from "moment";
import axios from 'axios';

interface OrderHistoryState extends OrderRetrieveState {
    orderList: OrderListItemModel[] | undefined,
    filter: OrderFilter,
    hasMore: boolean | undefined,
}

interface ProfileState extends OrderHistoryState {
    countries: CountryListItemModel[] | undefined,
    states: StateListItemModel[] | undefined,
    storeDetails: AddUpdateBuyerModel | undefined,
    users: BuyerUserListItemModel[] | undefined,
    user: BuyerUserModel | undefined,
    suppliers: SupplierListItem[] | undefined,
    locations: LocationListItemModel[] | undefined,
    location: BaseLocationManageModel | undefined,
    distributors: LocationSupplierListItemModel[] | undefined,
    files: LocationFile[] | undefined,
    referenceData: ReferenceData | undefined,
    successMessage: string | undefined,
    isLoading: boolean
}

export const ensureOrdersFilter = (filter?: OrderFilter | undefined): OrderFilter =>
    filter ? _.cloneDeep(filter) : createFilterPrototype();

export const createFilterPrototype = (withDates: boolean = true) : OrderFilter => {
    const filter = {
        paging: createPagingPrototype()
    } as OrderFilter;

    if (withDates) {
        const today = moment().utcOffset(0).set({hour:0,minute:0,second:0,millisecond:0});
        filter.dateTo = today.toDate();
        filter.dateFrom = today.add(-1, "month").toDate();
    }

    return filter;
};

const createPagingPrototype = () : Paging => ({
    pageIndex: 1,
    pageItemCount: 50
} as Paging);

const initialState: ProfileState = {
    isLoading: false,
    referenceData: undefined,
    orderList: undefined,
    filter: createFilterPrototype(),
    hasMore: undefined,
    currentOrder: undefined,
    countries: undefined,
    states: undefined,
    storeDetails: undefined,
    users: undefined,
    user: undefined,
    suppliers: undefined,
    locations: undefined,
    location: undefined,
    distributors: undefined,
    files: undefined,
    successMessage: undefined,
    errors: undefined,
    globalErrors: undefined
};

const profileSlice = createSlice({
    name: "profile",
    initialState: initialState,
    reducers: {
        requestLoading: (state: Draft<ProfileState>) => {
            state.isLoading = true;
            state.errors = undefined;
            state.globalErrors = undefined;
            state.successMessage = undefined;
        },        
        requestOrderHistory: (state: Draft<ProfileState>) => {
            state.isLoading = true;
            state.errors = undefined;
            state.globalErrors = undefined;
            state.currentOrder = undefined;
        },
        resetRefreshing: (state: Draft<ProfileState>) => {
            state.isLoading = false;
        },
        requestOrderHistoryNextPage: (state: Draft<ProfileState>) => {
            state.isLoading = true;
            state.errors = undefined;
            state.globalErrors = undefined;
            const filter = ensureOrdersFilter(state.filter);
            if (filter.paging && filter.paging.pageIndex) {
                filter.paging.pageIndex++;
            } else {
                filter.paging = createPagingPrototype()
            }
            state.filter = filter;
        },
        receiveReferenceData: (state: Draft<ProfileState>, action: PayloadAction<ReferenceData | undefined>) => {
            state.referenceData = action.payload;
        },
        receiveOrderList: (state: Draft<ProfileState>,
                           action: PayloadAction<{ orderList: OrderListItemModel[], count: number }>) => {
            const {orderList, count} = action.payload;

            state.errors = undefined;
            state.globalErrors = undefined;
            state.orderList = orderList;
            state.hasMore = baseHasMoreItems(orderList.length, count, state.filter.paging ?? createPagingPrototype());
            state.isLoading = false;
        },
        receiveOrderListNextPage: (state: Draft<ProfileState>,
                                   action: PayloadAction<{ orderList: OrderListItemModel[], count: number }>) => {
            const {orderList, count} = action.payload;

            state.errors = undefined;
            state.globalErrors = undefined;
            state.orderList = [...state.orderList ?? [], ...orderList];
            state.hasMore = baseHasMoreItems(orderList.length, count ?? 0, state.filter.paging ?? createPagingPrototype());
            state.isLoading = false;
        },
        receiveOrder: (state: Draft<ProfileState>, action: PayloadAction<{ order: OrderViewModel }>) => {
            state.errors = undefined;
            state.globalErrors = undefined;
            state.currentOrder = action.payload.order;
            state.isLoading = false;
        },
        filterChangeDistributor: (state: Draft<ProfileState>, action: PayloadAction<number | undefined>) => {
            const filter = ensureOrdersFilter(state.filter);
            filter.idSupplier = undefinedIfZero(action.payload);
            state.filter = filter;
        },
        filterChangeId: (state: Draft<ProfileState>, action: PayloadAction<number | undefined>) => {
            const filter = ensureOrdersFilter(state.filter);
            filter.id = action.payload;
            state.filter = filter;
        },
        filterChangeDateFrom: (state: Draft<ProfileState>, action: PayloadAction<Date | undefined>) => {
            const filter = ensureOrdersFilter(state.filter);
            filter.dateFrom = action.payload;
            state.filter = filter;
        },
        filterChangeDateTo: (state: Draft<ProfileState>, action: PayloadAction<Date | undefined>) => {
            const filter = ensureOrdersFilter(state.filter);
            filter.dateTo = action.payload;
            state.filter = filter;
        },
        filterChangeStatus: (state: Draft<ProfileState>, action: PayloadAction<OrderStatus | undefined>) => {
            const filter = ensureOrdersFilter(state.filter);
            filter.status = undefinedIfZero(action.payload);
            state.filter = filter;
        },
        processErrors: (state: Draft<ProfileState>, action: PayloadAction<BaseErrorsContainer>) => {
            state.errors = action.payload.errors;
            state.globalErrors = action.payload.globalErrors;
            state.isLoading = false;
        },
        clearErrors: (state: Draft<ProfileState>) => {
            state.errors = undefined;
            state.globalErrors = undefined;
        },
        changeCountries: (state: Draft<ProfileState>, action: PayloadAction<CountryListItemModel[] | undefined>) => {
            state.countries = action.payload;
        },
        changeStates: (state: Draft<ProfileState>, action: PayloadAction<StateListItemModel[] | undefined>) => {
            state.states = action.payload;
        },
        receiveSuccessResult: (state: Draft<ProfileState>, action: PayloadAction<string | undefined>) => {
            state.errors = undefined;
            state.globalErrors = undefined;
            state.successMessage = action.payload;
            state.isLoading = false;
        },
        receiveErrorResult: (state: Draft<ProfileState>, action: PayloadAction<BaseErrorsContainer | undefined>) => {
            state.errors = action.payload?.errors;
            state.globalErrors = action.payload?.globalErrors;

            if (action.payload && !action.payload?.success
                && (!state.errors || state.errors.length === 0)
                && (!state.globalErrors || state.globalErrors.length === 0)) {
                state.globalErrors = ["Something unexpected happened."];
            }

            state.isLoading = false;
        },
        receiveStoreDetails: (state: Draft<ProfileState>,
                                       action: PayloadAction<IResult<AddUpdateBuyerModel>>) => {
            state.storeDetails = action.payload.data;
            state.states = state.countries?.find(c => c.id === state.storeDetails?.address?.country)?.states;
            state.isLoading = false;
        },
        receiveUsers: (state: Draft<ProfileState>,
                                       action: PayloadAction<IResult<PagedListOfBuyerUserListItemModel>>) => {
            state.users = action.payload.data?.items;
            state.isLoading = false;
        },
        changeSuppliers: (state: Draft<ProfileState>, action: PayloadAction<SupplierListItem[] | undefined>) => {
            state.suppliers = action.payload;
        },
        receiveUser: (state: Draft<ProfileState>,
                                       action: PayloadAction<IResult<BuyerUserModel>>) => {
            state.user = action.payload.data;
            state.isLoading = false;
        },
        receiveLocations: (state: Draft<ProfileState>,
                                       action: PayloadAction<IResult<PagedListOfLocationListItemModel>>) => {
            state.locations = action.payload.data?.items;
            state.isLoading = false;
        },
        receiveLocation: (state: Draft<ProfileState>,
                                       action: PayloadAction<IResult<BaseLocationManageModel>>) => {
            state.location = action.payload.data;
            state.states = state.countries?.find(c => c.id === state.location?.address?.country)?.states;
            state.isLoading = false;
        },
        receiveDistributors: (state: Draft<ProfileState>,
                       action: PayloadAction<IResult<PagedListOfLocationSupplierListItemModel>>) => {
            state.distributors = action.payload.data?.items;
            state.isLoading = false;
        },
        receiveFiles: (state: Draft<ProfileState>,
                       action: PayloadAction<IResult<LocationFile[]>>) => {
            state.files = action.payload.data;
            state.isLoading = false;
        },
    }
});

export const {reducer, actions} = profileSlice;

export const api = {
    ...actions,
    requestOrderList: (orderFilter: OrderFilter) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestOrderHistory());
        const {profile: {referenceData}}: { profile: ProfileState } = getState();

        if (!referenceData) {
            await processBackendCall(
                () => new SettingsClient().getReferenceData(),
                result => dispatch(actions.receiveReferenceData(result.data)),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        const orderListResult = await dataAccessProvider.retrieveOrderList(orderFilter);

        if (orderListResult.success) {
            return dispatch(actions.receiveOrderList({
                orderList: orderListResult.data.items as OrderListItemModel[], count: orderListResult.data.count ?? 0
            }));
        } else {
            return dispatch(actions.processErrors(orderListResult));
        }
    },
    requestOrderListNextPage: () => async (dispatch: any, getState: any) => {
        const {profile: {filter}}: { profile: ProfileState } = getState();

        dispatch(actions.requestOrderHistoryNextPage());

        const newFilter = ensureOrdersFilter(filter);

        if (!newFilter.paging || !newFilter.paging.pageIndex) {
            newFilter.paging = createPagingPrototype();
        } else {
            newFilter.paging.pageIndex++;
        }

        const orderListResult = await dataAccessProvider.retrieveOrderList(newFilter);

        if (orderListResult.success) {
            return dispatch(actions.receiveOrderListNextPage({
                orderList: orderListResult.data.items as OrderListItemModel[], count: orderListResult.data.count ?? 0
            }));
        } else {
            return dispatch(actions.processErrors(orderListResult));
        }
    },
    requestOrder: (orderNumber: number) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestOrderHistory());

        const {profile: {referenceData}}: { profile: ProfileState } = getState();

        if (!referenceData) {
            await processBackendCall(
                () => new SettingsClient().getReferenceData(),
                result => dispatch(actions.receiveReferenceData(result.data)),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        const orderResult = await dataAccessProvider.retrieveOrder(orderNumber, undefined, true);

        if (orderResult.success && orderResult.data) {
            const idSkus = Enumerable.From(orderResult.data?.suppliers ?? []).SelectMany(s => s.groups ?? [])
                .SelectMany(g => g.positions ?? []).Select(p => p.idSku ?? 0).ToArray();

            dispatch(productsApi.requestProducts(orderResult.data?.id, orderResult.data?.idLocation, idSkus, productsView.orderHistory));

            return dispatch(actions.receiveOrder({order: orderResult.data}));
        } else {
            if (orderResult.success) {
                orderResult.globalErrors = ["Empty order data"];
            }

            return dispatch(actions.processErrors(orderResult));
        }
    },
    viewOrder: (orderNumber: number) => async (dispatch: any) => {
        return dispatch(push(`/order-detail/${orderNumber}`));
    },
    viewOrders: () => async (dispatch: any) => {
        return dispatch(push('/orders/'));
    },
    makeOrderActive: (orderNumber: number) => async (dispatch: any) => {
        dispatch(actions.requestOrderHistory());

        const orderResult = await dataAccessProvider.getShortOrderInfo(orderNumber);

        if (orderResult.success && orderResult.data?.id && orderResult.data?.status === OrderStatus.NotSubmitted
            && orderResult.data?.idLocation) {
            dispatch(actions.clearErrors());
            dispatch(actions.resetRefreshing());

            return dispatch(orderApi.requestOrderWithRedirect(orderResult.data?.id, orderResult.data?.idLocation));
        } else {
            if (orderResult.success) {
                let msg: string;

                switch (true) {
                    case !orderResult.data?.id:
                        msg = "Empty order data";
                        break;
                    case orderResult.data?.status !== OrderStatus.NotSubmitted:
                        msg = "Unsupported order status";
                        break;
                    default:
                        msg = "Unknown error";
                }

                orderResult.globalErrors = [msg];
            }

            return dispatch(actions.processErrors(orderResult));
        }
    },
    requestStoreDetails: () => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const {profile: {countries}}: { profile: ProfileState } = getState();

        if (!countries) {
            await processBackendCall(
                () => new SettingsClient().getCountries(),
                result => dispatch(actions.changeCountries(Object.values(result?.data ?? []))),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        return await processBackendCall(
            _ => new BuyerClient().getCurrentBuyer(),
            data => dispatch(actions.receiveStoreDetails(data)),
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
    updateStoreDetails: (model: AddUpdateBuyerModel) => async (dispatch: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new BuyerClient().addUpdateBuyer(model),
            _ => dispatch(actions.receiveSuccessResult("Updated successfully.")),
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
    requestUsers: (idLocation: number | undefined = undefined) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const {profile: {referenceData}}: { profile: ProfileState } = getState();

        if (!referenceData) {
            await processBackendCall(
                _ => new SettingsClient().getReferenceData(),
                result => dispatch(actions.receiveReferenceData(result.data)),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        return await processBackendCall(
            _ => new BuyerClient().getUsers({idLocation} as BuyerUserFilter),
            data => dispatch(actions.receiveUsers(data)),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    addUser: (idLocation: number | undefined = undefined) => async (dispatch: any) => {
        return dispatch(push(idLocation ? `/locations/${idLocation}/users/add` : '/users/add'));
    },
    viewUser: (id: string, idLocation: number | undefined = undefined) => async (dispatch: any) => {
        return dispatch(push(idLocation ? `/locations/${idLocation}/users/${id}` : `/users/${id}`));
    },
    viewUsers: (idLocation: number | undefined = undefined) => async (dispatch: any) => {
        return dispatch(push(idLocation ? `/locations/${idLocation}/users` : '/users'));
    },
    requestUser: (id: string, idLocation: number | undefined = undefined) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const state =  getState();
        const {profile: {suppliers}}: { profile: ProfileState } = state;

        if (!suppliers) {
            dispatch(actions.changeSuppliers((await new SupplierClient().getSuppliersPublic())?.data?.items ?? []));
        }

        let idUser: string;

        if (idLocation) {
            idUser = createMatchSelector('/locations/:id/users/:idUser')(state)?.params?.idUser;
        } else {
            idUser = createMatchSelector('/users/:id')(state)?.params?.id;
        }

        if (idUser === 'add') {
            return dispatch(actions.receiveUser({
                data: undefined,
                success: true
            }))
        }

        return await processBackendCall(
            _ => new BuyerClient().getUser(id),
            data => dispatch(actions.receiveUser(data)),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    resendResetPassword: (userId: string) => async (dispatch: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new BuyerClient().resetPassword(userId),
            _ => dispatch(actions.receiveSuccessResult("Reset password email was successfully sent.")),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    resendActivation: (userId: string) => async (dispatch: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new BuyerClient().resendActivation(userId),
            _ => dispatch(actions.receiveSuccessResult("Activation password email was successfully sent.")),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    saveUser: (model: BuyerUserModel) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const state =  getState();

        return await processBackendCall(
            _ => new BuyerClient().saveUser(model),
            _ => {
                let idUser: string;

                if (model.idLocation) {
                    idUser = createMatchSelector('/locations/:id/users/:idUser')(state)?.params?.idUser;
                } else {
                    idUser = createMatchSelector('/users/:id')(state)?.params?.id;
                }

                if (idUser === 'add') {
                    dispatch(api.viewUsers(model.idLocation));
                } else {
                    dispatch(actions.receiveSuccessResult("Updated successfully."))
                }
            },
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
    removeUser: (id: string, idLocation: number | undefined = undefined) => async (dispatch: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new BuyerClient().deleteUser(id),
            _ => {
                dispatch(api.requestUsers(idLocation));

                return dispatch(actions.receiveSuccessResult("Removed successfully."))
            },
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    requestLocations: () => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const {profile: {referenceData}}: { profile: ProfileState } = getState();

        if (!referenceData) {
            await processBackendCall(
                () => new SettingsClient().getReferenceData(),
                result => dispatch(actions.receiveReferenceData(result.data)),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        return await processBackendCall(
            _ => new LocationsClient().getLocations(new LocationFilter()),
            data => dispatch(actions.receiveLocations(data)),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    addLocation: () => async (dispatch: any) => {
        return dispatch(push('/locations/add'));
    },
    viewLocation: (id: number) => async (dispatch: any) => {
        return dispatch(push(`/locations/${id}`));
    },
    viewLocations: () => async (dispatch: any) => {
        return dispatch(push('/locations'));
    },
    requestLocation: (id: number) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const {profile: {referenceData, countries}}: { profile: ProfileState } = getState();

        if (!countries) {
            await processBackendCall(
                () => new SettingsClient().getCountries(),
                result => dispatch(actions.changeCountries(Object.values(result?.data ?? []))),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        if (!referenceData) {
            await processBackendCall(
                () => new SettingsClient().getReferenceData(),
                result => dispatch(actions.receiveReferenceData(result.data)),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        return await processBackendCall(
            _ => new LocationsClient().getLocation(id),
            data => dispatch(actions.receiveLocation(data)),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    getLocationIdFromParams: (params: any) => () => {
        return params.id === 'add' ? 0 : parseInt(params?.id ?? 0);
    },
    saveLocation: (model: BaseLocationManageModel) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new LocationsClient().saveLocation(model),
            _ => {
                const { params } = createMatchSelector('/locations/:id')(getState());

                if (params.id === 'add') {
                    dispatch(api.viewLocations());
                } else {
                    dispatch(actions.receiveSuccessResult("Updated successfully."))
                }
            },
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
    removeLocation: (id: number) => async (dispatch: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new LocationsClient().deleteLocation(id),
            _ => {
                dispatch(api.requestLocations());

                return dispatch(actions.receiveSuccessResult("Removed successfully."))
            },
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    copyStoreAddressToLocation: () => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());
        const {profile: {location}}: { profile: ProfileState } = getState();

        return await processBackendCall(
            _ => new BuyerClient().getCurrentBuyer(),
            result => dispatch(actions.receiveLocation({
                    data: _.cloneDeep({...location, address: result?.data?.address} as BaseLocationManageModel)
                })),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    requestLocationDistributorsAssignments: (idLocation: number | undefined) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const {profile: {referenceData}}: { profile: ProfileState } = getState();

        if (!referenceData) {
            await processBackendCall(
                _ => new SettingsClient().getReferenceData(),
                result => dispatch(actions.receiveReferenceData(result.data)),
                messages => dispatch(processErrors(error => actions.processErrors(error), messages))
            );
        }

        return await processBackendCall(
            _ => new LocationsClient().getLocationSuppliers({idLocation} as SupplierLocationFilter),
            data => dispatch(actions.receiveDistributors(data)),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    requestLocationDistributorAssignment: () => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        const {profile: {suppliers}}: { profile: ProfileState } = getState();

        if (!suppliers) {
            dispatch(actions.changeSuppliers((await new SupplierClient().getSuppliersPublic())?.data?.items ?? []));
        }

        return dispatch(actions.receiveSuccessResult());
    },
    viewLocationDistributorAssignment: (idLocation: number | undefined = undefined) => async (dispatch: any) => {
        return dispatch(push(`/locations/${idLocation}/distributors/add`));
    },
    viewLocationDistributorsAssignments: (idLocation: number | undefined) => async (dispatch: any) => {
        return dispatch(push(`/locations/${idLocation}/distributors`));
    },
    addLocationDistributorAssignment: (model: LocationAssignmentManageModel) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new LocationsClient().addAssignment(model),
            _ => {
                dispatch(api.viewLocationDistributorsAssignments(model.idLocation));

                return dispatch(actions.receiveSuccessResult("Added successfully."));
            },
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
    requestLocationFiles: (idLocation: number | undefined) => async (dispatch: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new LocationsClient().getLocationFiles(idLocation),
            result => dispatch(actions.receiveFiles(result)),
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    removeLocationFile: (fileName: string | null | undefined, idLocation: number) => async (dispatch: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => new LocationsClient().deleteLocationFile(idLocation, fileName),
            _ => {
                dispatch(api.requestLocationFiles(idLocation));

                return dispatch(actions.receiveSuccessResult("Removed successfully."))
            },
            messages => dispatch(processErrors(error => actions.receiveErrorResult(error), messages)));
    },
    downloadLocationFile: (fileName: string | null | undefined, idLocation: number) => async () => {
        const downloadUrl = `/api/Locations/GetLocationFile?idLocation=${idLocation}&fileName=${fileName}`;
        const downloadFrame = document.createElement("iframe");
        downloadFrame.setAttribute('src', downloadUrl);
        downloadFrame.setAttribute('class', "screenReaderText");
        document.body.appendChild(downloadFrame);
        setTimeout(() => downloadFrame.setAttribute('hidden', 'true'));
    },
    viewLocationFile: (idLocation: number | undefined = undefined) => async (dispatch: any) => {
        return dispatch(push(`/locations/${idLocation}/files/add`));
    },
    viewLocationFiles: (idLocation: number | undefined) => async (dispatch: any) => {
        return dispatch(push(`/locations/${idLocation}/files`));
    },
    uploadLocationFile: (model: {idLocation: number, description: string, file: string}) => async (dispatch: any, getState: any) => {
        dispatch(actions.requestLoading());

        return await processBackendCall(
            _ => {
                const formData = new FormData();
                formData.append('files[]', model.file);
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                };

                return axios.post(
                    `/api/Locations/UploadLocationFile?idLocation=${model.idLocation}&description=${model.description ?? ''}`,
                    formData, config).then((response) => response.data);
            },
            _ => {
                dispatch(api.viewLocationFiles(model.idLocation));

                return dispatch(actions.receiveSuccessResult("Uploaded successfully."));
            },
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages)));
    },
};