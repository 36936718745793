import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../store/Profile";
import {Alert, Button, Card, Container} from "react-bootstrap";
import Loading from "./Base/Loading";
import {LocationListItem} from "./LocationListItem";

class Locations extends React.Component {
    componentDidMount() {
        this.props.requestLocations();
    }

    render() {
        return (
            <Container className="order-list-container registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    {
                        !this.props.isLoading && this.props.globalErrors && this.props.globalErrors.length > 0 ?
                            <Alert variant="danger">
                                <Alert.Heading>You've got an error!</Alert.Heading>
                                <hr/>
                                {this.props.globalErrors?.map(err => `${err}\n`)}
                            </Alert>
                            : (!this.props.isLoading && this.props.successMessage ?
                            <Alert variant="success">{this.props.successMessage}</Alert>
                            : '')
                    }

                    <Card className="registration-form-wrapper">
                        <Card.Header className="registration-form-action-buttons">
                            <div><Button type="submit" variant="primary" onClick={this.props.addLocation}>
                                Add New Store Location</Button></div>
                        </Card.Header>
                        <Card.Body className="registration-form">

                        <div className="registration-form-separator">
                        <b/>
                        <div className="registration-form-separator-text"><h2>Business Locations</h2></div>
                        <b/>
                    </div>

                    <div className="order-list-items">
                        { this.props.locations?.map((location, index) =>
                            <LocationListItem model={location} key={index} referenceData={this.props.referenceData}
                                          delete={this.props.removeLocation} edit={this.props.viewLocation} />
                        )}

                        {/*<Card className="order-list-item border-0 rounded-0 p-0 m-0"*/}
                        {/*      key={this.props.orderList?.length ?? 0} hidden={!this.props.hasMore}>*/}
                        {/*    <Button variant="secondary" className="btn-load-more"*/}
                        {/*            onClick={this.props.requestOrderListNextPage}><span*/}
                        {/*        className="baseline-text">Load More Users</span><i*/}
                        {/*        className="far fa-plus-square fa-2x"/></Button>*/}
                        {/*</Card>*/}

                    </div>
                        </Card.Body>
                    </Card>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(api, dispatch)
)(Locations);