import _ from 'lodash';

const getItem = (name) => {
    let item;
    try {
        const savedValue = window.localStorage.getItem(name);
        if (savedValue) {
            item = JSON.parse(savedValue);
        } else {
            item = undefined;
        }
    } catch (e) {
        try {
            item = window.localStorage.getItem(name) ?? undefined;
        } catch (e) {
            item = undefined;
        }
    }
    return item;
};

const setItem = (name, value) => {
    try {
        let formattedValue;
        if (!value) {
            formattedValue = undefined;
        }
        else {
            formattedValue = JSON.stringify(value)
        }
        window.localStorage.setItem(name, formattedValue);
    } catch (e) {
    }
};

const removeItem = (name) => {
    try {
        window.localStorage.removeItem(name);
    } catch (e) {
    }
};

const addCollectionItem = (name, value) => {
    let array = getItem(name, value);
    try {
        array.unshift(value);
        array = _.uniqWith(array, _.isEqual);
        try {
            window.localStorage.setItem(name, JSON.stringify(array));
        } catch (e) {
        }
        return array;
    } catch (e) {
        try {
            window.localStorage.setItem(name, JSON.stringify([value]));
        } catch (e) {
        }
        return [value];
    }
};

const localStorage = {
    getItem: getItem,
    setItem: setItem,
    removeItem: removeItem,
    addCollectionItem: addCollectionItem
};

export default localStorage;
