import React from "react";
import UserStatusBadge from "./UserStatusBadge";
import {FormattedDateOnly} from "./Base/Formatters";
import {Button} from "react-bootstrap";

export class UserListItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.edit = this.edit.bind(this);
        this.delete = this.delete.bind(this);
    }

    edit() {
        this.props.edit(this.props.model.publicId);
    }

    delete() {
        this.props.delete(this.props.model.publicId);
    }

    render() {
        const model = this.props.model;
        const referenceData = this.props.referenceData;

        return (
            <div className="order-list-item">
                <div className="user-list-value fullName" onClick={this.edit}>{model.fullName}</div>
                <div className="user-list-value email">{model.email}</div>
                <div className="user-list-value status"><UserStatusBadge
                    referenceData={referenceData} status={model.status}/></div>
                <div className="user-list-value lastLogin">
                    <div>Last Login:</div>
                    <div><FormattedDateOnly value={model.lastLoginDate}/></div>
                </div>
                <div className="user-list-value actions">
                    <div>
                        <Button variant="primary" className="mr-2" onClick={this.edit}>
                            <i className="far fa-edit"/><span className="ml-1">Edit</span>
                        </Button>
                        <Button variant="danger" onClick={this.delete}>
                            <i className="far fa-trash-alt"/><span className="ml-1">Delete</span>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}