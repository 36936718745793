import React from 'react';
import NavMenu from './NavMenu';
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";
import LiveChat from "./Base/LiveChat";

export default class Layout extends React.Component {
    render() {
        return (
            <>
                <NavMenu/>
                <div className="border-top-fix">
                    {this.props.children}
                </div>
                <Footer className="border-top-fix"/>
                <ScrollToTop/>
                <LiveChat license={9439350} />
            </>
        );
    }
}