import React from "react";
import Formsy from "formsy-react";
import {Card, Alert, Container, Button} from "react-bootstrap";
import ValidatedFormGroupControl from "../Base/ValidatedFormGroupControl";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../../store/Registration";
import {api as accountApi} from "../../store/Account";
import Loading from "../Base/Loading";
import Reaptcha from 'reaptcha';

class RegistrationForm extends React.Component {
    initialState = {
        verified: false,
        submitted: false
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.onVerify = this.onVerify.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
        this.dismissSuccess = this.dismissSuccess.bind(this);
    }

    componentDidMount() {
        this.props.requestActivateUserModel(this.props.router);
    }

    submit(model) {
        this.props.requestActivation({token: this.props.token, ...this.props.activateUserModel, ...model});
        this.captcha.reset();
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    dismissSuccess() {
        this.props.clearSuccessMessage();
        this.setState(this.initialState);
    }

    validate() {
        this.setState({
            submitted: true
        });
    }

    onVerify(recaptchaResponse) {
        this.setState({
            verified: true
        });
        this.props.changeRecaptcha(recaptchaResponse);
    };

    render() {
        return (
            <Container className="registration-form-container">
                {!this.props.isLoading && this.props.loggedIn ?
                <Alert variant="danger">
                    <Alert.Heading>Error! {this.props.globalErrors?.map(err => {
                        return `${err}\n`;
                    })}</Alert.Heading>
                    <hr/>
                    You have already logged in. You should logout to perform an activation of the account!
                </Alert>
                    : !this.props.activateUserModel ?
                        <Alert variant="danger">
                            <Alert.Heading>Error! {this.props.globalErrors?.map(err => {
                                return `${err}\n`;
                            })}</Alert.Heading>
                            <hr/>
                            You cannot complete activation now. You should click on or copy whole text of activation link at your email message!<br/> If the error repeats please contact support.
                        </Alert> :                        
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    {
                        this.state.submitted && this.props.success && this.props.successMessage ? (
                            <Alert variant="success">
                                <Alert.Heading>Account Currently Locked</Alert.Heading>
                                <hr/>
                                Thank you for registering. Your account is in locked pending review. Reviews generally take up to one business day. You will receive a follow-up email once this has been completed letting you know that all is good or if there is any other information we may need to activate the account.
                            </Alert>) : (<Formsy onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors}
                                                 autoComplete="on">
                    <Card className="registration-form-wrapper">
                        <Card.Body className="registration-form">

                            {
                                this.state.submitted && this.props.globalErrors && this.props.globalErrors.length > 0 ? (
                                    <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                                        <Alert.Heading>You've got an error!</Alert.Heading>
                                        <hr/>
                                        {this.props.globalErrors?.map(err => {
                                            return `${err}\n`;
                                        })}
                                    </Alert>) : null
                            }

                            <div className="registration-form-separator">
                                <b/>
                                <div className="registration-form-separator-text"><h2>Activate Your Account</h2></div>
                                <b/>
                            </div>

                            <div className="registration-form-block">
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="firstName"
                                                           type="text" label="First Name"
                                                           name="firstName"
                                                           value={this.props.activateUserModel?.firstName}
                                                           validationError="First Name is required."
                                                           autoComplete="given-name" required />
            
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="lastName"
                                                           type="text" label="Last Name"
                                                           name="lastName"
                                                           value={this.props.activateUserModel?.lastName}
                                                           validationError="Last Name is required."
                                                           autoComplete="family-name" required />
                            </div>

                            <span className="account-numbers-error">*Must be at least 8 characters long, contain at least 1 numerical digit, 1 capital character, 1 lowercase character and 1 special character.<br/>Example: Dontuse!@1</span>

                            <div className="registration-form-block">
                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="password"
                                                           type="password" label="Password"
                                                           name="password"
                                                           validationError="This is not a valid password"
                                                           autoComplete="new-password" required />

                                <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                           id="confirmPassword"
                                                           type="password" label="Confirm Password"
                                                           name="confirmPassword"
                                                           validations="equalsField:password"
                                                           validationError="Confirmation password should equal the password"
                                                           autoComplete="new-password" required />
                            </div>
                        </Card.Body>
                        <Card.Footer className="registration-form-action-buttons">
                            <div><Reaptcha ref={e => (this.captcha = e)}
                                           size={window.innerWidth < 376 ? "compact" : "normal"}
                                           sitekey="6Lc6d5AUAAAAAJ1qctpvpF7Sk9PiQjQPGd8LrALy"
                                           onVerify={this.onVerify} /></div>
                            <div><Button type="submit" variant="primary" disabled={!this.state.verified}>ACTIVATE</Button></div>
                        </Card.Footer>
                    </Card></Formsy>)}
                </Loading>}
            </Container>
        );
    }
}

export default connect(
    state => ({
        ...state.registration,
        router: state.router,
        loggedIn: state.account.loggedIn
    }),
    dispatch => bindActionCreators({...api, logout: accountApi.logout}, dispatch)
)(RegistrationForm);