/* tslint:disable */
/* eslint-disable */
// ReSharper disable InconsistentNaming
import {MessageInfo} from "./clients";

export class Exception implements IException {
    message?: string | undefined;
    innerException?: Exception | undefined;
    stackTrace?: string | undefined;
    source?: string | undefined;

    constructor(data?: IException) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.message = _data["Message"];
            this.innerException = _data["InnerException"] ? Exception.fromJS(_data["InnerException"]) : <any>undefined;
            this.stackTrace = _data["StackTrace"];
            this.source = _data["Source"];
        }
    }

    static fromJS(data: any): Exception {
        data = typeof data === 'object' ? data : {};
        let result = new Exception();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["Message"] = this.message;
        data["InnerException"] = this.innerException ? this.innerException.toJSON() : <any>undefined;
        data["StackTrace"] = this.stackTrace;
        data["Source"] = this.source;
        return data;
    }
}

export interface IException {
    message?: string | undefined;
    innerException?: Exception | undefined;
    stackTrace?: string | undefined;
    source?: string | undefined;
}

export class AppValidationException extends Exception implements IAppValidationException {
    viewName?: string | undefined;
    messages?: MessageInfo[] | undefined;

    constructor(data?: IAppValidationException) {
        super(data);
    }

    init(_data?: any) {
        super.init(_data);
        if (_data) {
            this.viewName = _data["ViewName"];
            if (Array.isArray(_data["Messages"])) {
                this.messages = [] as any;
                for (let item of _data["Messages"])
                    this.messages!.push(MessageInfo.fromJS(item));
            }
        }
    }

    static fromJS(data: any): AppValidationException {
        data = typeof data === 'object' ? data : {};
        let result = new AppValidationException();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["ViewName"] = this.viewName;
        if (Array.isArray(this.messages)) {
            data["Messages"] = [];
            for (let item of this.messages)
                data["Messages"].push(item.toJSON());
        }
        super.toJSON(data);
        return data;
    }
}

export interface IAppValidationException extends IException {
    viewName?: string | undefined;
    messages?: MessageInfo[] | undefined;
}