import {DisplayType} from "../../api/clients";

export default function GetDisplayVariant(displayType) {
    switch(displayType) {
        case DisplayType.Danger:
            return "danger";
        case DisplayType.Info:
            return "info";
        case DisplayType.Purple:
            return "secondary";
        case DisplayType.Success:
            return "success";
        case DisplayType.Warning:
            return "warning";
        case DisplayType.NotSpecified:
        case DisplayType.Primary:
        case DisplayType.Default:
        default:
            return "primary";
    }
}