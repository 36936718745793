import GetDisplayVariant from "./Base/DisplayTypeToVariant";
import {Badge} from "react-bootstrap";
import React from "react";

export default class UserStatusBadge extends React.PureComponent {
    render() {
        const recordStatuses = this.props.referenceData?.recordStatusesDict;

        if (recordStatuses) {
            const recordStatus = recordStatuses[this.props.status];

            if (recordStatus) {
                const variant = GetDisplayVariant(recordStatus.displayType);

                return <Badge variant={variant}>{recordStatus.text}</Badge>;
            }
        }

        return null;
    }
}