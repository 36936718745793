import React from 'react';
import {
    InputGroup,
    FormControl,
    Button,
    Badge,
    OverlayTrigger,
    Tooltip, Card, Spinner
} from "react-bootstrap";
import PropTypes from "prop-types";
import {ProductGridItemModel} from "../../api/clients";
import PureHtml from "../Base/PureHtml";
import {FormattedCurrency, FormattedPercent} from "../Base/Formatters";
import CardImageSwiper from "../Base/CardImageSwiper";

export default class CardBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            quantity: 1
        };

        this.scrollTo = this.scrollTo.bind(this);
        this.changeQuantity = this.changeQuantity.bind(this);
    }

    scrollTo(event) {
        const elementSelector = event.currentTarget.hash;
        document.querySelector(elementSelector).scrollIntoView();
        event.preventDefault();
    }

    changeQuantity(event) {
        this.setState({
            quantity: event.currentTarget.value
        });
    }

    render() {
        const {data} = this.props;
        return (
            <Card>
                <Card.Body className="card-flex product-card-body">
                    <div className="card-image">
                        <CardImageSwiper mainImage={data.details?.mainProductImage ?? data.thumbnail} additionalImages={data.images}/>
                    </div>
                    <div className="card-content">
                        <h4 className="card-title">{data.name}</h4>
                        <div className="card-content-scrollable">
                            <div className="d-flex align-items-center py-3">
                                <span className="card-subtitle text-muted card-brand">{data.sku?.brandName}</span>
                                <span className="card-subtitle card-price"><Badge
                                    variant="warning"><FormattedCurrency
                                    value={data.sku?.wholesalePrice}/></Badge></span>
                            </div>
                            <div className="discount-buttons"
                                 hidden={!data.hasCaseStack && !data.hasOi && !data.hasMcb && !data.hasRbp}>
                                {data.hasCaseStack ?
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={['hover', 'click']}
                                        delay={{show: 50, hide: 50}}
                                        overlay={<Tooltip
                                            id="button-tooltip-CS">{data.details?.csDescriptions?.map((item, index) => {
                                            return (
                                                <p key={index}>{item}</p>
                                            );
                                        })}</Tooltip>}>
                                        <Button variant="primary" size="sm"
                                                className="card-CS discount-button">CS</Button>
                                    </OverlayTrigger>
                                    : null}
                                {data.hasOi ?
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={['hover', 'click']}
                                        delay={{show: 50, hide: 50}}
                                        overlay={<Tooltip
                                            id="button-tooltip-OI">{data.details?.oiDescription}</Tooltip>}>
                                        <Button variant="success" size="sm"
                                                className="card-OI discount-button">OI:<FormattedPercent
                                            value={data.sku?.oiPercent}/></Button>
                                    </OverlayTrigger>
                                    : null}
                                {data.hasMcb ?
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={['hover', 'click']}
                                        delay={{show: 50, hide: 50}}
                                        overlay={<Tooltip
                                            id="button-tooltip-MCB">{data.details?.mcbDescription}</Tooltip>}>
                                        <Button variant="danger" size="sm"
                                                className="card-MCB discount-button">MCB:<FormattedPercent
                                            value={data.sku?.mcbPercent}/></Button>
                                    </OverlayTrigger>
                                    : null}

                                {data.hasRbp ?
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={['hover', 'click']}
                                        delay={{show: 50, hide: 50}}
                                        overlay={<Tooltip
                                            id="button-tooltip-RBP">{data.details?.rbpDescription}</Tooltip>}>
                                        <Button variant="info" size="sm"
                                                className="card-RBP discount-button">RBP:<FormattedPercent
                                            value={data.sku?.rbpPercent}/></Button>
                                    </OverlayTrigger>
                                    : null}
                                {data.hasNp ?
                                    <OverlayTrigger
                                        placement="bottom"
                                        trigger={['hover', 'click']}
                                        delay={{show: 50, hide: 50}}
                                        overlay={<Tooltip
                                            id="button-tooltip-NP">{data.details?.npDescription}</Tooltip>}>
                                        <Button variant="dark" size="sm" className="card-NP discount-button">NP</Button>
                                    </OverlayTrigger>
                                    : null}
                            </div>
                            <div className="card-text pt-4">
                                <div className="card-short-description">
                                    <PureHtml hidden={!data.details?.shortDescription}
                                              data={data.details?.shortDescription}/>
                                    <br hidden={!data.details?.shortDescription}/>
                                    <a href="#product_description" onClick={this.scrollTo}>Read More &gt;</a>
                                </div>
                            </div>

                            <ul className="list-group list-group-even-odd list-group-flush">
                                <li className="list-group-item d-flex justify-content-between align-items-center card-distributor">
                                    Distributor<span>{data.supplierName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-Warehouse">Warehouse<span>{data.warehouseName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-UPC">
                                    UPC/GTIN<span>{data.sku?.code}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-UPC">
                                    Distributor Item#<span>{data.sku?.supplierProductFieldValue}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-size">Size<span>{data.sku?.size}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-UOM">UOM<span>{data.sku?.uomName}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-type">Unit
                                    Type<span>{data.sku?.uintTypeName}</span></li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-pack-size">
                                    Pack Size<span>{data.sku?.packSize}</span>
                                </li>
                                <li className="list-group-item d-flex justify-content-between align-items-center card-srp">
                                    SRP<span><FormattedCurrency value={data.sku?.retailPrice}/></span>
                                </li>
                            </ul>

                            <div id="product_description" className="card-content-extended">
                                <PureHtml data={data.details?.description}/>
                            </div>
                        </div>
                    </div>
                </Card.Body>
                <Card.Footer className="product-detail-card-footer">
                    <div className="left">
                        <Button variant="secondary" className="card-button" onClick={this.props.onHide}>Close</Button>
                    </div>
                    <div className="right" hidden={this.props.hideOrderButton}>
                        <InputGroup>
                            <FormControl
                                placeholder="Qty"
                                aria-label="Qty"
                                aria-describedby="basic-addon2"
                                className="card-qty"
                                value={this.props.isInOrder ? (this.props.skuOrdered?.quantity ?? 1) : this.state.quantity}
                                onChange={this.changeQuantity}
                                type="number"
                                disabled={this.props.isInOrder}
                                isInvalid={this.state.quantity <= 0}
                            />
                            <FormControl.Feedback type="invalid" tooltip={true}>Quantity must be greater than 0.</FormControl.Feedback>
                            <InputGroup.Append>
                                <Button variant={this.props.isInOrder ? "success" : "primary"} className="card-button"
                                        onClick={() => this.props.addToOrder(data.details?.productId, this.state.quantity, this.props.location, this.props.order)}
                                        disabled={this.props.isLoading || this.props.isInOrder || this.state.quantity <= 0}
                                >
                                    {this.props.isInOrder ? <span>Added to Order!</span> : <span>Add to Cart</span>}
                                    <Spinner className="ml-2" animation="border" size="sm" variant="success"
                                             hidden={!this.props.isLoading || this.props.isInOrder}/>
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </div>
                </Card.Footer>
            </Card>
        );
    }
}

CardBlock.propTypes = {
    onHide: PropTypes.func,
    data: PropTypes.shape(ProductGridItemModel),
    hideOrderButton: PropTypes.bool,
    addToOrder: PropTypes.func
};