import React from 'react';
import PropTypes from "prop-types";

const PureHtml = (props) => (
    <div hidden={props.hidden} className={props.className} dangerouslySetInnerHTML={{ __html: props.data }} /> 
)

PureHtml.propTypes = {
    className: PropTypes.string,
    data: PropTypes.string,
    hidden: PropTypes.bool
};

export default PureHtml;