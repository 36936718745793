import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import { ContentViewModel, ContentViewClient} from "../api/clients";
import {BaseErrorsContainer} from "./Base/BaseInterfaces";
import {processBackendCall, processErrors} from "./Base/Backend";

interface state extends BaseErrorsContainer {
    contentPage: ContentViewModel | undefined,
    isLoading: boolean
}

const initialState: state = {
    errors: null,
    success: true,
    globalErrors: undefined,
    contentPage: undefined,
    isLoading: false
};

const contentSlice = createSlice({
    name: "content",
    initialState: initialState,
    reducers: {
        requestContentPage: (state: Draft<state>) => {
            state.contentPage = undefined;
            state.isLoading = true;
        },
        receiveContentPage: (state: Draft<state>, action: PayloadAction<ContentViewModel | undefined>) => {
            state.contentPage = action.payload;
            state.isLoading = false;
        },
        receiveErrors: (state: Draft<state>, action: PayloadAction<BaseErrorsContainer>) => {
            state.errors = action.payload.errors;
            state.globalErrors = action.payload.globalErrors;
            state.isLoading = false;
        },
        clearErrors: (state: Draft<state>) => {
            state.globalErrors = undefined;
        },
    }
});

export const {reducer} = contentSlice;

const {actions} = contentSlice;

export const api = {
    ...actions,
    requestContentPage: (url: string) => async (dispatch: any) => {
        dispatch(actions.requestContentPage());

        const client = new ContentViewClient();

        return await processBackendCall(
            () => client.contentPage(url),
            result => dispatch(actions.receiveContentPage(result.data)),
            messages => dispatch(processErrors(error => actions.receiveErrors(error), messages))
        );
    }
};