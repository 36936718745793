import {push} from "connected-react-router";
import {ApiException, MessageInfo, MessageLevel} from "../api/clients";
import {AppValidationException} from "../api/exceptions";
import {api as accountApi} from "../store/Account";

export const parseErrors = (messages?: MessageInfo[], errors?: any):string[] => {
    const globalErrors: string[] = [];
    messages?.map(val => {
        if (val.messageLevel === MessageLevel.Error && val.message) {
            if (val.field && errors) {
                errors[val.field] = val.message;
            } else {
                globalErrors.push(val.message);
            }
        }
        
        return val;
    });
    return globalErrors;
}

export const processServerException = (e?:any): AppValidationException => {
    if (e instanceof ApiException) {
        const error: ApiException = e;
        if (error.response) {
            try {
                const serverMessage = JSON.parse(error.response);
                return AppValidationException.fromJS(serverMessage);
            } catch {
                //omit
            }
        } else if (error.status === 401) {
            throw e;
        } else {
            return new AppValidationException({messages: [{message: "The entity is not accessible for this action"} as MessageInfo]})
        }
    }

    throw e;
}

export const apiExceptionFilter = (e?:any, history?: any) => {
    if (e instanceof ApiException) {
        const error: ApiException = e;
        switch(error.status) {
            case 401:
                if (history.location.pathname === '/')
                    return (dispatch:any) => dispatch(accountApi.resetLoginState());
                return (dispatch:any) => dispatch(accountApi.openLoginForm()); 
            case 302:
            case 301:
                return (dispatch:any) => dispatch(push(error.headers["location"]));
            case 500:
            case 400:
                //TODO: global error handling
                if (error.response) {
                    try {
                        const serverMessage = JSON.parse(error.response);
                        const appException = AppValidationException.fromJS(serverMessage);
                        if (appException) {
                            alert(appException.message);
                            return;
                        }
                    }
                    catch {
                        //omit
                    }
                }
                alert(error.message ?? "Unexpected error occured");
                return;
            case 404:
                alert("Not found");
                return;                
            case 403:
                alert("The entity is not accessible for this action");
                return;
            default:
                throw error;
        }
    }
    
    throw e;
};