import React from "react";
import {Badge} from "react-bootstrap";

export class DistributorListItem extends React.PureComponent {
    render() {
        const model = this.props.model;

        return (
            <div className="distributor-list-item">
                <div className="distributor-list-value name">{model.name}</div>
                <div className="distributor-list-value warehouses">{model.warehouses?.map((w, index) =>
                    <Badge key={index} variant="info">{w.name}</Badge>)}</div>
                <div className="distributor-list-value account-number">{model.accountNumber}</div>
                <div className="distributor-list-value supplier-rep">{model.supplierRep}</div>
            </div>
        )
    }
}