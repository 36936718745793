import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../store/Profile";
import {Container, Button, Card, Alert} from "react-bootstrap";
import Loading from "./Base/Loading";
import Formsy from "formsy-react";
import ValidatedFormGroupControl from "./Base/ValidatedFormGroupControl";
import {LocationNav} from "./LocationNav";

class LocationFile extends React.Component {
    initialState = {
        submitted: false,
        file: null
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.onChange = this.onChange.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
    }

    submit(model) {
        this.props.uploadLocationFile({...model, file: this.state.file,
            idLocation: this.props.getLocationIdFromParams(this.props.match.params)});
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    validate() {
        this.setState({
            submitted: true
        });
    }

    onChange(e) {
        this.setState({
            file: e.target.files[0]
        });
    }

    render() {
        return (
            <Container className="registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    <Formsy onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors}
                            autoComplete="off">
                        <Card className="registration-form-wrapper">
                            <Card.Header className="p-0">
                                <LocationNav idLocation={this.props.getLocationIdFromParams(this.props.match.params)}/>
                            </Card.Header>
                            <Card.Body className="registration-form">

                                {
                                    this.props.globalErrors && this.props.globalErrors.length > 0 ?
                                        <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                                            <Alert.Heading>You've got an error!</Alert.Heading>
                                            <hr/>
                                            {this.props.globalErrors?.map(err => {
                                                return `${err}\n`;
                                            })}
                                        </Alert>
                                        : (!this.props.isLoading && this.props.successMessage ?
                                        <Alert variant="success">{this.props.successMessage}</Alert>
                                        : '')
                                }

                                <div className="registration-form-separator">
                                    <b/>
                                    <div className="registration-form-separator-text"><h2>Upload New File</h2></div>
                                    <b/>
                                </div>

                                <div className="registration-form-block">

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="description"
                                                               type="text" label="Description"
                                                               name="description"
                                                               autoComplete="off" />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="file"
                                                               type="file" label="Locate File"
                                                               name="file"
                                                               onChange={this.onChange}
                                                               autoComplete="off" />

                                </div>
                            </Card.Body>
                            <Card.Footer className="registration-form-action-buttons">
                                <div><Button type="submit" variant="primary">UPLOAD</Button></div>
                            </Card.Footer>
                        </Card>
                    </Formsy>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(api, dispatch)
)(LocationFile);