import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {
    AddOrderItem,
    ChangeOrderStatusModel,
    MessageInfo,
    OrderFilter, OrderGroupViewModel, OrderListItemModel,
    OrderManageModel,
    OrderOperationOfAddOrderItem,
    OrderPositionEditModel,
    OrderPositionViewModel,
    OrdersClient,
    OrderSectionEditModel,
    OrderStatus,
    OrderSupplierEditModel, OrderSupplierViewModel,
    OrderViewModel,
    PagedListOfOrderListItemModel
} from "../api/clients";
import {parseErrors, processServerException} from "./apiExceptionFilter";
import {AppValidationException} from "../api/exceptions";
import {api as productsApi, productsView} from "./ProductDetails";
import {api as searchApi, OrderStateStored} from "./SearchFilter";
import * as Enumerable from "linq-es2015";
import localStorage from "./localStorage";
import {push} from "connected-react-router";
import {BaseErrorsContainer} from "./Base/BaseInterfaces";

interface ProductMap {
    [idSku: number]: OrderPositionViewModel;
}

export interface OrderRetrieveState {
    errors: any,
    globalErrors: string[] | undefined,
    currentOrder: OrderViewModel | undefined
}

interface state extends OrderRetrieveState {
    isLoading: boolean,
    isDirty: boolean,
    orderManageModel: OrderManageModel | undefined,
    productsMap: ProductMap | undefined,
    showActionConfirmationModal: boolean,
    confirmationActionText: string
}

const orderRetrieveInitialState: OrderRetrieveState = {
    errors: undefined,
    globalErrors: undefined,
    currentOrder: undefined
}

const initialState: state = {
    ...orderRetrieveInitialState,
    isLoading: false,
    isDirty: false,
    orderManageModel: undefined,
    productsMap: undefined,
    showActionConfirmationModal: false,
    confirmationActionText: ''
};

export const convertToOrderManageModel = (order: OrderViewModel): OrderManageModel => {
    const model = new OrderManageModel();
    model.id = order.id;
    model.idLocation = order.idLocation;
    model.type = order.type;
    model.positions = [];
    model.sections = [];
    model.suppliers = [];
    model.customAuthorizedBy = order.customAuthorizedBy;
    model.startDate = order.startDate;
    model.idSalesRep = order.idSalesRep;

    if (order.suppliers) {
        for (let k = 0; k < order.suppliers.length; k++) {
            const supplier = order.suppliers[k];

            if (supplier.groups) {
                for (let i = 0; i < supplier.groups.length; i++) {
                    const group = supplier.groups[i];

                    if (group.positions) {
                        for (let j = 0; j < group.positions.length; j++) {
                            const positions = group.positions;
                            const position = new OrderPositionEditModel();
                            position.id = positions[j].id;
                            position.idSku = positions[j].idSku;
                            position.quantity = positions[j].quantity;
                            position.acceptedNewPlacementDeal = positions[j].acceptedNewPlacementDeal;
                            position.messages = positions[j].messages;
                            position.overrideOIPercent = positions[j].overrideOIPercent;
                            position.overrideMCBPercent = positions[j].overrideMCBPercent;
                            position.overrideRBPPercent = positions[j].overrideRBPPercent;
                            position.resultTotalPercent = positions[j].resultTotalPercent;
                            position.dealStatus = positions[j].dealStatus;
                            model.positions.push(position);
                        }

                        const section = new OrderSectionEditModel();
                        section.idWarehouse = group.idWarehouse;
                        section.customerNotes = group.customerNotes;
                        section.purchaseOrderNumber = group.purchaseOrderNumber;
                        section.selectedLookupVariantId = group.selectedLookupVariantId;
                        section.trackingNumber = group.trackingNumber;
                        section.confirmationDate = group.confirmationDate;
                        section.approved = group.approved;
                        section.notSendEmail = group.notSendEmail;
                        section.additionalCCEmails = group.additionalCCEmails;
                        model.sections.push(section);
                    }
                }

                const supplierSection = new OrderSupplierEditModel();
                supplierSection.idSupplier = supplier.idSupplier;
                supplierSection.supplierRep = supplier.supplierRep;
                supplierSection.accountNumber = supplier.accountNumber;
                model.suppliers.push(supplierSection);
            }
        }
    }

    model.saveProgress = true;

    return model;
};

const orderSlice = createSlice({
    name: "order",
    initialState: initialState,
    reducers: {
        requestOrder: (state: Draft<state>) => {
            state.errors = undefined;
            state.globalErrors = undefined;
            state.isLoading = true;
        },
        processErrors: (state: Draft<state>, action) => {
            state.errors = action.payload.errors;
            state.globalErrors = action.payload.globalErrors;
            state.isLoading = false;
        },
        receiveOrder: (state: Draft<state>, action: PayloadAction<{order: OrderViewModel | undefined, recalculationResultsOnly: boolean}>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            state.currentOrder = action.payload.order;
            if (state.currentOrder) {
                if (action.payload.recalculationResultsOnly) {
                    state.orderManageModel = convertToOrderManageModel(state.currentOrder);
                }
                else {
                    const products = Enumerable.From(state.currentOrder.suppliers ?? [])
                        .SelectMany(s => s.groups ?? [])
                        .SelectMany(g => g.positions ?? []).ToArray();
                    const productsMap = {} as ProductMap;

                    products.map(p => productsMap[p.idSku ?? 0] = p);

                    state.productsMap = productsMap;
                    state.orderManageModel = convertToOrderManageModel(state.currentOrder);
                }
            } else {
                state.currentOrder = undefined;
                state.productsMap = undefined;
                state.orderManageModel = undefined;
            }
            if (!action.payload.recalculationResultsOnly) {
                state.isDirty = false;
            }
            state.isLoading = false;
        },
        setStartDate: (state: Draft<state>, action: PayloadAction<Date | undefined>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            if (state.orderManageModel) {
                const newManageModel = state.orderManageModel.clone();
                newManageModel.startDate = action.payload;
                state.orderManageModel = newManageModel;
                state.isDirty = true;
            }
        },
        updateCustomAuthorizedBy: (state: Draft<state>, action: PayloadAction<string | undefined>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            if (state.orderManageModel) {
                const newManageModel = state.orderManageModel.clone();
                newManageModel.customAuthorizedBy = action.payload;
                state.orderManageModel = newManageModel;
                state.isDirty = true;
            }
        },
        updateSupplierRep: (state: Draft<state>, action: PayloadAction<[number, string | undefined]>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            if (state.orderManageModel) {
                const [idSupplier, value] = action.payload;
                state.orderManageModel.suppliers?.forEach((supplier, index) => {
                    if (supplier.idSupplier === idSupplier && state.orderManageModel?.suppliers) {
                        const supplierUpdate = supplier.clone();
                        supplierUpdate.supplierRep = value;
                        const newSuppliers = [...state.orderManageModel.suppliers];
                        newSuppliers[index] = supplierUpdate;
                        const newManageModel = state.orderManageModel.clone();
                        newManageModel.suppliers = newSuppliers;
                        state.orderManageModel = newManageModel;
                        state.isDirty = true;
                        return false;
                    }
                });

                state.currentOrder?.suppliers?.forEach((supplier, index) => {
                    if (supplier.idSupplier === idSupplier && state.currentOrder?.suppliers) {
                        const supplierUpdate = supplier.clone();
                        supplierUpdate.supplierRep = value;
                        const newSuppliers = [...state.currentOrder.suppliers];
                        newSuppliers[index] = supplierUpdate;
                        const newViewModel = state.currentOrder.clone();
                        newViewModel.suppliers = newSuppliers;
                        state.currentOrder = newViewModel;
                        state.isDirty = true;
                        return false;
                    }
                });
            }
        },
        updateAccountNumber: (state: Draft<state>, action: PayloadAction<[number, string | undefined]>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            if (state.orderManageModel) {
                const [idSupplier, value] = action.payload;
                state.orderManageModel.suppliers?.forEach((supplier, index) => {
                    if (supplier.idSupplier === idSupplier && state.orderManageModel?.suppliers) {
                        const supplierUpdate = supplier.clone();
                        supplierUpdate.accountNumber = value;
                        const newSuppliers = [...state.orderManageModel.suppliers];
                        newSuppliers[index] = supplierUpdate;
                        const newManageModel = state.orderManageModel.clone();
                        newManageModel.suppliers = newSuppliers;
                        state.orderManageModel = newManageModel;
                        state.isDirty = true;
                        return false;
                    }
                });

                state.currentOrder?.suppliers?.forEach((supplier, index) => {
                    if (supplier.idSupplier === idSupplier && state.currentOrder?.suppliers) {
                        const supplierUpdate = supplier.clone();
                        supplierUpdate.accountNumber = value;
                        const newSuppliers = [...state.currentOrder.suppliers];
                        newSuppliers[index] = supplierUpdate;
                        const newViewModel = state.currentOrder.clone();
                        newViewModel.suppliers = newSuppliers;
                        state.currentOrder = newViewModel;
                        state.isDirty = true;
                        return false;
                    }
                });
            }
        },
        updatePurchaseOrderNumber: (state: Draft<state>, action: PayloadAction<[number, string | undefined]>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            if (state.orderManageModel) {
                const [idWarehouse, value] = action.payload;

                state.orderManageModel.sections?.forEach((section, index) => {
                    if (section.idWarehouse === idWarehouse && state.orderManageModel?.sections) {
                        const sectionUpdate = section.clone();
                        sectionUpdate.purchaseOrderNumber = value;
                        const newSections = [...state.orderManageModel.sections];
                        newSections[index] = sectionUpdate;
                        const newManageModel = state.orderManageModel.clone();
                        newManageModel.sections = newSections;
                        state.orderManageModel = newManageModel;
                        state.isDirty = true;
                        return false;
                    }
                });

                state.currentOrder?.suppliers?.forEach((supplier, supplierIndex) => {
                    supplier.groups?.forEach((group, index) => {
                        if (group.idWarehouse === idWarehouse && supplier.groups && state.currentOrder?.suppliers) {
                            const groupUpdate = group.clone();
                            groupUpdate.purchaseOrderNumber = value;
                            const newGroups = [...supplier.groups];
                            newGroups[index] = groupUpdate;
                            const updatedSuppliers = [...state.currentOrder.suppliers];
                            const newSupplier = supplier.clone();
                            newSupplier.groups = newGroups;
                            updatedSuppliers[supplierIndex] = newSupplier;
                            const newViewModel = state.currentOrder.clone();
                            newViewModel.suppliers = updatedSuppliers;
                            state.currentOrder = newViewModel;
                            state.isDirty = true;
                            return false;
                        }
                    });
                });
            }
        },
        updateCustomerNotes: (state: Draft<state>, action: PayloadAction<[number, string | undefined]>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            if (state.orderManageModel) {
                const [idWarehouse, value] = action.payload;

                state.orderManageModel.sections?.forEach((section, index) => {
                    if (section.idWarehouse === idWarehouse && state.orderManageModel?.sections) {
                        const sectionUpdate = section.clone();
                        sectionUpdate.customerNotes = value;
                        const newSections = [...state.orderManageModel.sections];
                        newSections[index] = sectionUpdate;
                        const newManageModel = state.orderManageModel.clone();
                        newManageModel.sections = newSections;
                        state.orderManageModel = newManageModel;
                        state.isDirty = true;
                        return false;
                    }
                });

                state.currentOrder?.suppliers?.forEach((supplier, supplierIndex) => {
                    supplier.groups?.forEach((group, index) => {
                        if (group.idWarehouse === idWarehouse && supplier.groups && state.currentOrder?.suppliers) {
                            const groupUpdate = group.clone();
                            groupUpdate.customerNotes = value;
                            const newGroups = [...supplier.groups];
                            newGroups[index] = groupUpdate;
                            const updatedSuppliers = [...state.currentOrder.suppliers];
                            const newSupplier = supplier.clone();
                            newSupplier.groups = newGroups;
                            updatedSuppliers[supplierIndex] = newSupplier;
                            const newViewModel = state.currentOrder.clone();
                            newViewModel.suppliers = updatedSuppliers;
                            state.currentOrder = newViewModel;
                            state.isDirty = true;
                            return false;
                        }
                    });
                });
            }
        },
        updateOrderManageState: (state: Draft<state>, action: PayloadAction<[OrderViewModel | undefined, OrderManageModel | undefined, ProductMap | undefined]>) => {
            const [orderViewModel, orderManageModel, productsMap] = action.payload;

            state.globalErrors = undefined;
            state.errors = undefined;
            
            state.currentOrder = orderViewModel;
            state.orderManageModel = orderManageModel;
            state.productsMap = productsMap;
            state.isDirty = true;
        },
        showConfirmation: (state: Draft<state>, action: PayloadAction<string>) => {
            state.showActionConfirmationModal = true;
            state.confirmationActionText = action.payload;
        },
        resetConfirmation: (state: Draft<state>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
            state.showActionConfirmationModal = false;
            state.confirmationActionText = '';
        },
        clearErrors: (state: Draft<state>) => {
            state.globalErrors = undefined;
            state.errors = undefined;
        },
    }
});

const internalApi = {
    updateQuantity: (state: state, data: [number, number | undefined]): [OrderViewModel | undefined, OrderManageModel | undefined, ProductMap | undefined] => {
        let orderViewModel: OrderViewModel | undefined = state.currentOrder;
        let orderManageModel: OrderManageModel | undefined = state.orderManageModel;
        let productsMap: ProductMap | undefined = state.productsMap;

        if (orderManageModel) {
            const [idSku, value] = data;
            if (productsMap) {
                const newMap: ProductMap = {...productsMap};
                const newSku = newMap[idSku].clone();
                newSku.quantity = value;
                newMap[idSku] = newSku;
                productsMap = newMap;
            }

            orderManageModel.positions?.forEach((product, index) => {
                if (product.idSku === idSku && orderManageModel?.positions) {
                    const productUpdate = product.clone();
                    productUpdate.quantity = value;
                    const newPositions: OrderPositionEditModel[] = [...orderManageModel.positions];
                    newPositions[index] = productUpdate;
                    orderManageModel = orderManageModel.clone();
                    orderManageModel.positions = newPositions;
                    return false;
                }
            });
        }

        return [orderViewModel, orderManageModel, productsMap];
    },
    updateNewPlacement: (state: state, data: [number, boolean | undefined]): [OrderViewModel | undefined, OrderManageModel | undefined, ProductMap | undefined] => {
        let orderViewModel: OrderViewModel | undefined = state.currentOrder;
        let orderManageModel: OrderManageModel | undefined = state.orderManageModel;
        let productsMap: ProductMap | undefined = state.productsMap;

        if (orderManageModel) {
            const [idSku, value] = data;
            if (productsMap) {
                const newMap: ProductMap = {...productsMap};
                const newSku = newMap[idSku].clone();
                newSku.acceptedNewPlacementDeal = value;
                newMap[idSku] = newSku;
                productsMap = newMap;
            }

            orderManageModel.positions?.forEach((product, index) => {
                if (product.idSku === idSku && orderManageModel?.positions) {
                    const productUpdate = product.clone();
                    productUpdate.acceptedNewPlacementDeal = value;
                    const newPositions: OrderPositionEditModel[] = [...orderManageModel.positions];
                    newPositions[index] = productUpdate;
                    orderManageModel = orderManageModel.clone();
                    orderManageModel.positions = newPositions;
                    return false;
                }
            });
        }

        return [orderViewModel, orderManageModel, productsMap];
    },
    deleteItem: (state: state, data: number): [OrderViewModel | undefined, OrderManageModel | undefined, ProductMap | undefined] => {
        let orderViewModel: OrderViewModel | undefined = state.currentOrder;
        let orderManageModel: OrderManageModel | undefined = state.orderManageModel;
        let productsMap: ProductMap | undefined = state.productsMap;

        if (orderManageModel) {
            const idSku = data;
            if (productsMap) {
                const newMap: ProductMap = {...productsMap};
                delete newMap[idSku];
                productsMap = newMap;
            }

            orderManageModel.positions?.forEach((product, index) => {
                if (product.idSku === idSku && orderManageModel?.positions) {
                    const newPositions: OrderPositionEditModel[] = [...orderManageModel.positions];
                    newPositions.splice(index, 1);
                    orderManageModel = orderManageModel.clone();
                    orderManageModel.positions = newPositions;
                    return false;
                }
            });

            orderViewModel?.suppliers?.forEach((supplier, supplierIndex) => {
                supplier.groups?.forEach((group, groupIndex) => {
                    group.positions?.forEach((position, index) => {
                        if (position.idSku === idSku && group.positions && supplier.groups && orderViewModel?.suppliers) {
                            const newPositions: OrderPositionViewModel[] = [...group.positions];
                            newPositions.splice(index, 1);
                            const newGroups: OrderGroupViewModel[] = [...supplier.groups];
                            const newGroup = group.clone();
                            newGroup.positions = newPositions;
                            newGroups[groupIndex] = newGroup; 
                            const updatedSuppliers: OrderSupplierViewModel[] = [...orderViewModel.suppliers];
                            const newSupplier = supplier.clone();
                            newSupplier.groups = newGroups;
                            updatedSuppliers[supplierIndex] = newSupplier;
                            orderViewModel = orderViewModel.clone();
                            orderViewModel.suppliers = updatedSuppliers;
                            return false;
                        }
                    });
                });
            });
        }

        return [orderViewModel, orderManageModel, productsMap];
    },
}

export const {reducer} = orderSlice;
const {actions} = orderSlice;

//TODO fix me move to base (shared items) and generalize
export interface OrderRetrieve<T> extends BaseErrorsContainer {
    data: T,
    hasAccessDeniedError: boolean | undefined
}

//TODO fix me move to base (shared items) and generalize
export function processOrderErrors<T extends BaseErrorsContainer> (messages?: MessageInfo[]) : T {
    const errors: any = {};
    const globalErrors = parseErrors(messages, errors);

    return {
        success: false,
        errors,
        globalErrors
    } as T;
}

export const dataAccessProvider = {
    retrieveOrder: async (orderNumber: number | undefined, idLocation: number | undefined = undefined,
                          viewOnly: boolean = false)
        : Promise<OrderRetrieve<OrderViewModel>> => {
        const client = new OrdersClient();

        try {
            const orderResult = await (async (orderNumber, idLocation, viewOnly) => {
                if (viewOnly) {
                    return await client.getOrderView(orderNumber);
                } else {
                    if (!orderNumber) {
                        return await client.getLastOrderForLocation(idLocation);
                    } else {
                        return await client.getOrder(orderNumber);
                    }
                }
            })(orderNumber, idLocation, viewOnly);

            if (orderResult.success) {
                if (idLocation && orderResult.data?.idLocation !== idLocation) {
                    return {
                        success: false,
                        hasAccessDeniedError: true,
                        data: orderResult.data
                    } as OrderRetrieve<OrderViewModel>;
                }

                return {
                    success: true,
                    data: orderResult.data
                } as OrderRetrieve<OrderViewModel>;
            } else {
                return processOrderErrors<OrderRetrieve<OrderViewModel>>(orderResult.messages);
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);
            const orderRetrieveState = processOrderErrors<OrderRetrieve<OrderViewModel>>(appValidationException.messages);

            //TODO: 404 processing as empty result everywhere basically
            if (e.status === 403) {
                orderRetrieveState.hasAccessDeniedError = true;
            }

            return orderRetrieveState;
        }
    },
    getShortOrderInfo: async (orderNumber: number | undefined)
        : Promise<OrderRetrieve<OrderListItemModel>> => {
        try {
            const orderResult = await new OrdersClient().getShortOrderInfo(orderNumber);

            if (orderResult.success) {
                return {
                    success: true,
                    data: orderResult.data
                } as OrderRetrieve<OrderListItemModel>;
            } else {
                return processOrderErrors<OrderRetrieve<OrderListItemModel>>(orderResult.messages);
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);
            const orderRetrieveState = processOrderErrors<OrderRetrieve<OrderListItemModel>>(appValidationException.messages);

            //TODO: 404 processing as empty result everywhere basically
            if (e.status === 403) {
                orderRetrieveState.hasAccessDeniedError = true;
            }

            return orderRetrieveState;
        }
    },
    retrieveOrderList: async (filter: OrderFilter): Promise<OrderRetrieve<PagedListOfOrderListItemModel>> => {
        try {
            const orderResult = await (new OrdersClient()).getOrders(filter);

            if (orderResult.success) {
                return {
                    success: true,
                    data: orderResult.data
                } as OrderRetrieve<PagedListOfOrderListItemModel>;
            } else {
                return processOrderErrors<OrderRetrieve<PagedListOfOrderListItemModel>>(orderResult.messages);
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);
            const orderRetrieveState = processOrderErrors<OrderRetrieve<PagedListOfOrderListItemModel>>(
                appValidationException.messages);

            //TODO: 404 processing as empty result everywhere basically
            if (e.status === 403) {
                orderRetrieveState.hasAccessDeniedError = true;
            }

            return orderRetrieveState;
        }
    },
    addToOrder: async (idProduct: number, quantity: number | undefined,
                 idOrder: number | undefined, idLocation: number | undefined)
        : Promise<OrderRetrieve<OrderViewModel>> => {
        try {
            const orderOperation = new OrderOperationOfAddOrderItem();
            const orderItem = new AddOrderItem();
            orderItem.quantity = quantity ?? 1;
            orderItem.idProduct = idProduct;
            orderOperation.idLocation = idLocation;
            orderOperation.idOrder = idOrder;
            orderOperation.operation = orderItem;
            
            const orderResult = await new OrdersClient().addToOrder(orderOperation);

            if (orderResult.success) {
                return {
                    success: true,
                    data: orderResult.data
                } as OrderRetrieve<OrderViewModel>;
            } else {
                return processOrderErrors<OrderRetrieve<OrderViewModel>>(orderResult.messages);
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);
            const orderRetrieveState = processOrderErrors<OrderRetrieve<OrderViewModel>>(
                appValidationException.messages);

            //TODO: 404 processing as empty result everywhere basically
            if (e.status === 403) {
                orderRetrieveState.hasAccessDeniedError = true;
            }

            return orderRetrieveState;
        }
    }
    
    //TODO move here another data accessors
};

export const api = {
    ...actions,
    updateQuantity: (updateData: [number, number | undefined]) => (dispatch: any, getState: any) => {
        const {order}: {order: state} = getState();
        const updatedData = internalApi.updateQuantity(order, updateData);
        dispatch(actions.updateOrderManageState(updatedData));
        const orderManageModel = updatedData[1];
        if (orderManageModel) {
            dispatch(api.recalculateOrder(orderManageModel));
        }
    },
    updateNewPlacement: (updateData: [number, boolean | undefined]) => (dispatch: any, getState: any) => {
        const {order}: {order: state} = getState();
        const updatedData = internalApi.updateNewPlacement(order, updateData);
        dispatch(actions.updateOrderManageState(updatedData));
        const orderManageModel = updatedData[1];
        if (orderManageModel) {
            dispatch(api.recalculateOrder(orderManageModel));
        }
    },
    deleteItem: (updateData: number) => (dispatch: any, getState: any) => {
        const {order}: {order: state} = getState();
        const updatedData = internalApi.deleteItem(order, updateData);
        dispatch(actions.updateOrderManageState(updatedData));
        const orderManageModel = updatedData[1];
        if (orderManageModel) {
            dispatch(api.recalculateOrder(orderManageModel));
        }
    },
    requestOrder: (orderNumber: number | undefined, idLocation: number | undefined, performRecalculate: boolean | undefined = false) => async (dispatch: any) => {
        if (!orderNumber && !idLocation) {
            //need to load from saved context
            const storedState: OrderStateStored = localStorage.getItem("orderingContext")

            if (storedState) {
                orderNumber = storedState.selectedOrder;
                idLocation = storedState.selectedLocation;

                if (!orderNumber && !idLocation) {
                    return undefined;
                }
            } else {
                return undefined;
            }
        }

        dispatch(actions.requestOrder());

        const orderResult = await dataAccessProvider.retrieveOrder(orderNumber, idLocation);

        if (orderResult.success && orderResult.data && orderResult.data?.status === OrderStatus.NotSubmitted) {
            const idSkus = Enumerable.From(orderResult.data?.suppliers ?? []).SelectMany(s => s.groups ?? [])
                .SelectMany(g => g.positions ?? []).Select(p => p.idSku ?? 0).ToArray();

            dispatch(productsApi.requestProducts(orderResult.data?.id, orderResult.data?.idLocation, idSkus, productsView.order));
            dispatch(searchApi.changeOrder(orderResult.data?.id));
            
            let orderData = orderResult.data;
            
            if (performRecalculate) {
                const client = new OrdersClient();
                try {
                    const calculationResult = await client.recalculateOrder(convertToOrderManageModel(orderData));
                    if (calculationResult.success && calculationResult.data) {
                        orderData = calculationResult.data;
                    }
                } catch (e) {
                    const appValidationException: AppValidationException = processServerException(e);
                    return processOrderErrors(appValidationException.messages);
                }
            }

            return dispatch(actions.receiveOrder({order: orderData, recalculationResultsOnly: false}));
        } else {
            if (orderResult.hasAccessDeniedError) {
                dispatch(searchApi.changeOrder(undefined));

                return dispatch(actions.receiveOrder({order: undefined, recalculationResultsOnly: false}));
            }

            if (orderResult.success) {
                let msg: string;

                switch (true) {
                    case !orderResult.data?.id: msg = "Empty order data"; break;
                    case orderResult.data?.status !== OrderStatus.NotSubmitted: msg = "Unsupported order status"; break;
                    default: msg = "Unknown error";
                }

                orderResult.globalErrors = [msg];
            }

            return dispatch(actions.processErrors(orderResult));
        }
    },
    requestOrderWithRedirect: (orderNumber: number, idLocation: number) => (dispatch: any) => {
        //use change order with location when selected from UI
        dispatch(searchApi.changeLocation(idLocation, orderNumber));
        return dispatch(push("/order"));
    },
    addToOrder: (idProduct: number, quantity: number | undefined,
                 idLocation: number | undefined, idOrder: number | undefined) => async (dispatch: any) => {
        dispatch(actions.requestOrder());

        const orderResult = await dataAccessProvider.addToOrder(idProduct, quantity, idOrder, idLocation);

        if (orderResult.success) {
            const idSkus = Enumerable.From(orderResult.data?.suppliers ?? []).SelectMany(s => s.groups ?? [])
                .SelectMany(g => g.positions ?? []).Select(p => p.idSku ?? 0).ToArray();
            dispatch(productsApi.requestProducts(orderResult.data?.id, orderResult.data?.idLocation, idSkus, productsView.order));
            dispatch(searchApi.changeOrder(orderResult.data?.id));

            return dispatch(actions.receiveOrder({order: orderResult.data, recalculationResultsOnly: false}));
        } else {
            if (orderResult.hasAccessDeniedError) {
                dispatch(searchApi.changeOrder(undefined));
                dispatch(actions.receiveOrder({order: undefined, recalculationResultsOnly: false}));

                return dispatch(api.addToOrder(idProduct, quantity, idLocation, undefined));
            }

            return dispatch(actions.processErrors(orderResult));
        }
    },
    updateOrder: (order: OrderManageModel) => async (dispatch: any) => {
        dispatch(actions.requestOrder());

        const processOrderErrors = (messages?: MessageInfo[]) => {
            const errors: any = {};
            const globalErrors = parseErrors(messages, errors);
            return dispatch(actions.processErrors({errors, globalErrors}));
        }

        const client = new OrdersClient();

        try {
            //TODO move to data accessors
            const orderResult = await client.addUpdateOrder(order);
            const idSkus = Enumerable.From(orderResult.data?.suppliers ?? []).SelectMany(s => s.groups ?? [])
                .SelectMany(g => g.positions ?? []).Select(p => p.idSku ?? 0).ToArray();

            if (orderResult.success) {
                dispatch(productsApi.requestProducts(orderResult.data?.id, orderResult.data?.idLocation, idSkus, productsView.order));
                dispatch(searchApi.changeOrder(orderResult.data?.id));

                return dispatch(actions.receiveOrder({order: orderResult.data, recalculationResultsOnly: false}));
            } else {
                if (orderResult.messages && orderResult.messages.length > 0) {
                    return processOrderErrors(orderResult.messages);
                } else {
                    alert('Cannot update an Order');
                    //TODO: cannot create an order with selected location without actual error message, add error message
                }
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);
            return processOrderErrors(appValidationException.messages);
        }
    },
    recalculateOrder: (order: OrderManageModel) => async (dispatch: any) => {
        dispatch(actions.requestOrder());

        const processOrderErrors = (messages?: MessageInfo[]) => {
            const errors: any = {};
            const globalErrors = parseErrors(messages, errors);
            return dispatch(actions.processErrors({errors, globalErrors}));
        }

        const client = new OrdersClient();

        try {
            //TODO move to data accessors
            const orderResult = await client.recalculateOrder(order);
            if (orderResult.success) {
                return dispatch(actions.receiveOrder({order: orderResult.data, recalculationResultsOnly: true}));
            } else {
                if (orderResult.messages && orderResult.messages.length > 0) {
                    return processOrderErrors(orderResult.messages);
                } else {
                    //TODO: calculation error handling
                }
            }
        } catch (e) {
            const appValidationException: AppValidationException = processServerException(e);
            return processOrderErrors(appValidationException.messages);
        }
    },
    reloadOrder: (orderManageModel: OrderManageModel | undefined) => async (dispatch: any) => {
        if (orderManageModel?.id) {
            return dispatch(api.requestOrder(orderManageModel.id, undefined, true));
        }
    },
    submitOrder: (orderManageModel: OrderManageModel | undefined) => async (dispatch: any) => {
        if (orderManageModel?.id) {
            dispatch(actions.requestOrder());

            const processOrderErrors = (messages?: MessageInfo[]) => {
                const errors: any = {};
                const globalErrors = parseErrors(messages, errors);
                return dispatch(actions.processErrors({errors, globalErrors}));
            }

            const client = new OrdersClient();

            try {
                const changeStatus = new ChangeOrderStatusModel();
                changeStatus.status = OrderStatus.Submitted;
                changeStatus.id = orderManageModel.id;
                changeStatus.customAuthorizedBy = orderManageModel.customAuthorizedBy;
                changeStatus.positions = orderManageModel.positions;
                changeStatus.sections = orderManageModel.sections;
                changeStatus.suppliers = orderManageModel.suppliers;

                //TODO move to data accessors
                const orderResult = await client.changeOrderStatus(changeStatus);
                if (orderResult.success) {
                    dispatch(searchApi.changeOrder(undefined));
                    dispatch(actions.receiveOrder({order: undefined, recalculationResultsOnly: false}));
                    dispatch(actions.showConfirmation('Submitted'));
                } else {
                    if (orderResult.messages && orderResult.messages.length > 0) {
                        return processOrderErrors(orderResult.messages);
                    } else {
                        alert('Cannot update an Order');
                        //TODO: cannot create an order with selected location without actual error message, add error message
                    }
                }
            } catch (e) {
                const appValidationException: AppValidationException = processServerException(e);
                return processOrderErrors(appValidationException.messages);
            }
        }
    },
    cancelOrder: (orderManageModel: OrderManageModel | undefined) => async (dispatch: any) => {
        if (orderManageModel?.id) {
            dispatch(actions.requestOrder());

            const processOrderErrors = (messages?: MessageInfo[]) => {
                const errors: any = {};
                const globalErrors = parseErrors(messages, errors);
                return dispatch(actions.processErrors({errors, globalErrors}));
            }

            const client = new OrdersClient();

            try {

                const changeStatus = new ChangeOrderStatusModel();
                changeStatus.status = OrderStatus.Cancelled;
                changeStatus.id = orderManageModel.id;
                changeStatus.customAuthorizedBy = orderManageModel.customAuthorizedBy;
                changeStatus.positions = orderManageModel.positions;
                changeStatus.sections = orderManageModel.sections;
                changeStatus.suppliers = orderManageModel.suppliers;

                //TODO move to data accessors
                const orderResult = await client.changeOrderStatus(changeStatus);
                if (orderResult.success) {
                    dispatch(searchApi.changeOrder(undefined));
                    dispatch(actions.receiveOrder({order: undefined, recalculationResultsOnly: false}));
                    return dispatch(actions.showConfirmation('Cancelled'));
                } else {
                    if (orderResult.messages && orderResult.messages.length > 0) {
                        return processOrderErrors(orderResult.messages);
                    } else {
                        alert('Cannot update an Order');
                        //TODO: cannot create an order with selected location without actual error message, add error message
                    }
                }
            } catch (e) {
                const appValidationException: AppValidationException = processServerException(e);
                return processOrderErrors(appValidationException.messages);
            }
        }
    },
    goToHomePage: () => async (dispatch: any) => {
        dispatch(actions.resetConfirmation());
        return dispatch(push('/'));
    },
    goToCatalog: () => async (dispatch: any) => {
        dispatch(actions.resetConfirmation());
        return dispatch(push('/catalog'));
    },
    downloadPdf: (id: number, idWarehouse: number) => (dispatch: any) => {
        const downloadUrl = `/api/Orders/DownloadOrderFile?id=${id}&idWarehouse=${idWarehouse}`;
        const downloadFrame = document.createElement("iframe");
        downloadFrame.setAttribute('src', downloadUrl);
        downloadFrame.setAttribute('class', "screenReaderText");
        document.body.appendChild(downloadFrame);
        setTimeout(() => downloadFrame.setAttribute('hidden', 'true'));
    }
};