import React from 'react';
import {Link, NavLink as RRNavLink} from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as B from 'react-bootstrap';
import {api as searchApi, isSearchFilterEdited} from "../store/SearchFilter";
import {api as accountApi} from "../store/Account";
import logo from './../assets/turnpikelogo.png';
import Loading from "./Base/Loading";
import SearchPopup from "./SearchPopup";
import LoginForm from "./LoginForm";
import ClearCache from "react-clear-cache";
import AsyncSelect from 'react-select/async';

class NavMenu extends React.Component {
    initialState = {
        extendedSearch: true,
        loadLocationOptionsTimer: undefined
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.extendedSearchToggle = this.extendedSearchToggle.bind(this);
        this.changeSearchText = this.changeSearchText.bind(this);
        this.changeDistributor = this.changeDistributor.bind(this);
        this.changeWarehouse = this.changeWarehouse.bind(this);
        this.changeBrand = this.changeBrand.bind(this);
        this.changeUpc = this.changeUpc.bind(this);
        this.changeDistributorItemNumber = this.changeDistributorItemNumber.bind(this);
        this.changeLocation = this.changeLocation.bind(this);
        this.runSearch = this.runSearch.bind(this);
        this.resetFilter = this.resetFilter.bind(this);
        this.logout = this.logout.bind(this);
        this.login = this.login.bind(this);
        
        this.changeDealsAndCaseStackOnly = this.changeDealsAndCaseStackOnly.bind(this);
        this.changeCaseStack = this.changeCaseStack.bind(this);
        this.changeOi = this.changeOi.bind(this);
        this.changeMcb = this.changeMcb.bind(this);
        this.changeNp = this.changeNp.bind(this);

        this.loadLocationOptions = this.loadLocationOptions.bind(this);
    }

    componentDidMount() {
        this.props.requestFilterData(this.props.router);
    }

    extendedSearchToggle() {
        this.setState({
            extendedSearch: !this.state.extendedSearch
        });
    }

    changeSearchText(event) {
        this.props.changeSearchText(event.currentTarget.value);
    }

    changeDistributor(event) {
        this.props.changeDistributor(parseInt(event.currentTarget.value));
    }

    changeWarehouse(event) {
        this.props.changeWarehouse(parseInt(event.currentTarget.value));
    }

    changeBrand(event) {
        this.props.changeBrand(event.currentTarget.value);
    }

    changeUpc(event) {
        this.props.changeUpc(event.currentTarget.value);
    }

    changeDistributorItemNumber(event) {
        this.props.changeDistributorItemNumber(event.currentTarget.value);
    }

    changeDealsAndCaseStackOnly(event) {
        this.props.changeDealsAndCaseStackOnly(event.currentTarget.checked);
    }

    changeCaseStack(event) {
        this.props.changeCaseStack(event.currentTarget.checked);
    }

    changeOi(event) {
        this.props.changeOi(event.currentTarget.checked);
    }

    changeMcb(event) {
        this.props.changeMcb(event.currentTarget.checked);
    }

    changeNp(event) {
        this.props.changeNp(event.currentTarget.checked);
    }

    changeLocation(option, action) {
        this.props.changeLocation(parseInt(option.value));
    }

    runSearch(event) {
        event.preventDefault();

        if (this.props.router.location.pathname === '/catalog') {
            this.props.runSearch();
        } else {
            this.props.runSearchWithRedirect();
        }
    }

    resetFilter(event) {
        event.preventDefault();
        this.props.resetSearchFilter();
    }

    logout() {
        this.props.logout();
    }

    login() {
        this.props.openLoginForm();
    }

    loadLocationOptions(searchText, callback) {
        if (this.state.loadLocationOptionsTimer) {
            clearTimeout(this.state.loadLocationOptionsTimer);
        }

        const timer = setTimeout(async () => {
            const locations = await this.props.retrieveLocations(searchText);
            callback(locations?.items?.map(l => ({'value': l.id, 'label': l.name})));
        }, 300);

        this.setState({
            loadLocationOptionsTimer: timer
        })
    }

    render() {
        return <>
            <header>
                <B.Navbar className="layout-navigation flex-column" bg="light" expand="md">
                    <B.Container>
                        <B.Navbar.Toggle aria-controls="mobile-menu" className="mr-2"/>
                        <B.Navbar.Brand as={Link} to="/"><img src={logo} alt="logo" width="100"/></B.Navbar.Brand>
                        <B.Navbar.Collapse className="d-md-inline-flex flex-md-row align-self-start"
                                           id="mobile-menu">
                            <div className="menu-locations-mobile">
                                <Loading active={this.props.isLoading} className="loading-indicator"
                                         normalOverlay={true} onlyOverlay={true}>
                                    <span className="font-weight-bold mr-2">Current Location</span>
                                    <AsyncSelect className="form-control select"
                                                 defaultOptions={this.props.locationOptions}
                                                 loadOptions={this.loadLocationOptions}
                                                 value={this.props.locationOptions?.find(l => l.value === this.props.selectedLocation) }
                                                 onChange={this.changeLocation}/>
                                </Loading>
                            </div>
                            <B.Nav className="left-menu-section">
                                <B.Nav.Item key={0}>
                                    <B.Nav.Link as={RRNavLink} className="text-orange" activeClassName="active"
                                                exact={true} to="/">DEALS</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={1}>
                                    <B.Nav.Link as={RRNavLink} className="text-orange" activeClassName="active"
                                                to="/catalog">CATALOG</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={2}>
                                    <B.Nav.Link as={RRNavLink} className="text-orange" activeClassName="active"
                                                to="/content/faqs">FAQs</B.Nav.Link>
                                </B.Nav.Item>
                                {this.props.menuItems?.map((widget, index) =>
                                    <B.Nav.Item key={index + 3}>
                                        <B.Nav.Link as={RRNavLink} className="text-orange"
                                                    to={{
                                                        pathname: "/",
                                                        hash: "#area_" + encodeURIComponent(widget.id)
                                                    }} activeClassName="ignore-active">{widget.title}</B.Nav.Link>
                                    </B.Nav.Item>
                                )}
                                <Loading active={this.props.isMenuLoading} className="loading-indicator"
                                         normalOverlay={true} onlyOverlay={true}/>
                            </B.Nav>
                            <B.Nav className="menu-account-mobile">
                                <B.Nav.Item key={0}>
                                    <B.Nav.Link as={RRNavLink} className="text-orange" activeClassName="active"
                                                to="/order">
                                        <i className="fas fa-shopping-cart"/>
                                        <span className="badge badge-pill badge-primary">{this.props.currentOrderItemsQty}</span>
                                    </B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={1}>
                                    <B.Nav.Link className="text-orange"><i className="fas fa-user"/></B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={2}>
                                    <B.Nav.Link as={RRNavLink} hidden={!this.props.loggedIn} className="text-orange"
                                                activeClassName="active" to="/store">STORE DETAILS</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={3}>
                                    <B.Nav.Link as={RRNavLink} hidden={!this.props.loggedIn} className="text-orange"
                                                activeClassName="active" to="/users">USERS</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={4}>
                                    <B.Nav.Link as={RRNavLink} hidden={!this.props.loggedIn} className="text-orange"
                                                activeClassName="active" to="/locations">LOCATIONS</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={5}>
                                    <B.Nav.Link as={RRNavLink} hidden={!this.props.loggedIn} className="text-orange"
                                                activeClassName="active" to="/orders">ORDER HISTORY</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={6}>
                                    <B.Nav.Link hidden={this.props.loggedIn ||
                                        ['/registration', '/activation', '/reset-password'].every(r => r !== this.props.router.location.pathname)}
                                                as={RRNavLink} to="/" exact={true} onClick={this.login} className="text-orange">LOGIN</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={7}>
                                    <B.Nav.Link hidden={this.props.loggedIn ||
                                        ['/registration', '/activation', '/reset-password'].some(r => r === this.props.router.location.pathname)}
                                                className="text-orange" onClick={this.login}>LOGIN</B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={8}>
                                    <B.Nav.Link hidden={!this.props.loggedIn} className="text-orange" onClick={this.logout}>LOGOUT</B.Nav.Link>
                                </B.Nav.Item>
                            </B.Nav>
                            <B.Nav className="right-menu-section">
                                <B.Nav.Item key={0}>
                                    <Loading active={this.props.isLoading} className="menu-locations loading-indicator"
                                             normalOverlay={true} onlyOverlay={true}>
                                        <div className="mr-2 mb-2">Current Location</div>
                                        <AsyncSelect className="form-control select"
                                                     defaultOptions={this.props.locationOptions}
                                                     loadOptions={this.loadLocationOptions}
                                                     value={this.props.locationOptions?.find(l => l.value === this.props.selectedLocation) }
                                                     onChange={this.changeLocation}/>
                                    </Loading>
                                </B.Nav.Item>
                                <B.Nav.Item key={1} className="ml-md-1">
                                    <B.Nav.Link as={RRNavLink} className="text-orange" activeClassName="active"
                                                to="/order">
                                        <i className="fas fa-shopping-cart"/>
                                        <span className="badge badge-pill badge-primary">{this.props.currentOrderItemsQty}</span>
                                    </B.Nav.Link>
                                </B.Nav.Item>
                                <B.Nav.Item key={2} className="ml-md-1">
                                    <B.NavDropdown id="account-menu" title={<>ACCOUNT <i className="fas fa-user"/></>} className="dropdown-menu-account">
                                        <B.NavDropdown.Item as={RRNavLink} hidden={!this.props.loggedIn} to="/store">STORE DETAILS</B.NavDropdown.Item>
                                        <B.NavDropdown.Item as={RRNavLink} hidden={!this.props.loggedIn} to="/users">USERS</B.NavDropdown.Item>
                                        <B.NavDropdown.Item as={RRNavLink} hidden={!this.props.loggedIn} to="/locations">LOCATIONS</B.NavDropdown.Item>
                                        <B.NavDropdown.Item as={RRNavLink} hidden={!this.props.loggedIn} to="/orders">ORDER HISTORY</B.NavDropdown.Item>
                                        <B.NavDropdown.Item hidden={this.props.loggedIn ||
                                            ['/registration', '/activation', '/reset-password'].every(r => r !== this.props.router.location.pathname)}
                                                            as={RRNavLink} to="/" exact={true} onClick={this.login}>LOGIN</B.NavDropdown.Item>
                                        <B.NavDropdown.Item hidden={this.props.loggedIn ||
                                            ['/registration', '/activation', '/reset-password'].some(r => r === this.props.router.location.pathname)}
                                                            onClick={this.login}>LOGIN</B.NavDropdown.Item>
                                        <B.NavDropdown.Item hidden={!this.props.loggedIn} onClick={this.logout}>LOGOUT</B.NavDropdown.Item>
                                    </B.NavDropdown>
                                </B.Nav.Item>
                            </B.Nav>
                        </B.Navbar.Collapse>
                    </B.Container>
                    <B.Form className="search-form" onSubmit={this.runSearch}>
                        <B.InputGroup className="search-bar">
                            <B.InputGroup.Prepend hidden={this.props.isLoading || this.props.isCatalogLoading || this.props.isCatalogCategoriesLoading || !isSearchFilterEdited(this.props)}>
                                <B.Button type="reset" variant="secondary" onClick={this.resetFilter}><i
                                    className="fas fa-backspace"/><span className="ml-1 d-none d-sm-inline">CLEAR</span></B.Button>
                            </B.InputGroup.Prepend>
                            <B.FormControl type="text" placeholder="Product Name, Tags..."
                                           value={this.props.searchText}
                                           onChange={this.changeSearchText}/>
                            <B.InputGroup.Append>
                                <B.Button onClick={this.extendedSearchToggle} aria-controls="search-bar-extended"
                                          variant={this.state.extendedSearch ? "info" : "secondary"}><i
                                    className="fas fa-ellipsis-v"/></B.Button>
                                <B.Button type="submit" variant="primary" onClick={this.runSearch}><i
                                    className="fas fa-search"/><span className="ml-1 d-none d-sm-inline">SEARCH</span></B.Button>
                            </B.InputGroup.Append>
                        </B.InputGroup>
                        <B.Collapse className="search-bar-details" in={this.state.extendedSearch}>
                            <Loading active={this.props.isLoading} className="loading-indicator"
                                     normalOverlay={true} onlyOverlay={true}>
                                <div className="bottom-form">
                                    <B.FormGroup>
                                        <B.Form.Control as="select" value={this.props.selectedDistributor}
                                                        onChange={this.changeDistributor}>
                                            <option key={0} value={0}>All Distributors</option>
                                            {this.props.distributors?.map((dist) => 
                                                <option key={dist.id} value={dist.id}>{dist.name}</option>
                                            )}
                                        </B.Form.Control>
                                    </B.FormGroup>
                                    <B.FormGroup>
                                        <B.Form.Control as="select" value={this.props.selectedWarehouse}
                                                        onChange={this.changeWarehouse}>
                                            <option key={0} value={0}>All Warehouses</option>
                                            {this.props.warehouses?.map((wh) => 
                                                <option key={wh.id} value={wh.id}>{wh.name}</option>
                                            )}
                                        </B.Form.Control>
                                    </B.FormGroup>
                                    <B.FormGroup>
                                        <B.FormControl type="text" list="brands" placeholder="Brand..."
                                                       value={this.props.selectedBrand}
                                                       onChange={this.changeBrand}/>
                                        <datalist id="brands">
                                            {this.props.brands?.lookupVariants.map((dist) => {
                                                return <option key={dist.id} value={dist.valueVariant}/>;
                                            })}
                                        </datalist>
                                    </B.FormGroup>
                                    <B.FormGroup>
                                        <B.FormControl type="text" placeholder="UPC1, UPC2, UPC3..."
                                                       value={this.props.upc} onChange={this.changeUpc}/>
                                    </B.FormGroup>
                                    <B.FormGroup>
                                        <B.FormControl type="text" placeholder="Distributor Item #..."
                                                       value={this.props.distributorItemNumber}
                                                       onChange={this.changeDistributorItemNumber}/>
                                    </B.FormGroup>
                                </div>
                                <div className="deals-bottom-form">
                                    <B.Form.Label>
                                        <B.Form.Check id="deals" type="checkbox" checked={this.props.hasPromotion}
                                                        onChange={this.changeDealsAndCaseStackOnly} label="Deals &amp; Case Stacks Only" />
                                    </B.Form.Label>
                                    <B.Form.Label hidden={!this.props.hasPromotion}>
                                        <B.Form.Check id="cs" type="checkbox" checked={this.props.caseStack}
                                                        onChange={this.changeCaseStack} label={<><B.Badge variant="primary" className="mr-1">CS</B.Badge>Case Stack</>} />
                                    </B.Form.Label>
                                    <B.Form.Label hidden={!this.props.hasPromotion}>
                                        <B.Form.Check id="oi" type="checkbox" checked={this.props.oi}
                                                        onChange={this.changeOi} label={<><B.Badge variant="success" className="mr-1">OI</B.Badge>OI</>} />
                                    </B.Form.Label>
                                    <B.Form.Label hidden={!this.props.hasPromotion}>
                                        <B.Form.Check id="mcb" type="checkbox" checked={this.props.mcb}
                                                        onChange={this.changeMcb} label={<><B.Badge variant="danger" className="mr-1">MCB</B.Badge>MCB</>}/>
                                    </B.Form.Label>
                                    <B.Form.Label hidden={!this.props.hasPromotion}>
                                        <B.Form.Check id="np" type="checkbox" checked={this.props.np}
                                                      onChange={this.changeNp} label={<><B.Badge variant="dark" className="mr-1">NP</B.Badge>New Placement</>} />
                                    </B.Form.Label>
                                </div>
                            </Loading>
                        </B.Collapse>
                    </B.Form>
                </B.Navbar>
            </header>
            <SearchPopup/>
            <ClearCache auto={false} duration={10000}>
                {({ isLatestVersion, emptyCacheStorage }) => (
                    <B.Modal show={!isLatestVersion} size="lg" centered={true}>
                        <B.Modal.Header>
                            <B.Modal.Title>New Version Available</B.Modal.Title>
                        </B.Modal.Header>
                        <B.Modal.Body>
                            <p>The site has recently been updated. In order to provide you with the latest version we must reload your current page to finish the installation.</p>
                            <p>Upon clicking OK below the page will refresh.</p>
                        </B.Modal.Body>
                        <B.Modal.Footer>
                            <B.Button onClick={() => emptyCacheStorage()} variant="success">OK</B.Button>
                        </B.Modal.Footer>
                    </B.Modal>
                )}
            </ClearCache>
            {
                ['/registration', '/activation', '/reset-password']
                    .find(r => r === this.props.router.location.pathname) ? null :

            <B.Modal onHide={this.props.closeLoginForm} show={this.props.showLoginForm}>
                <B.Modal.Header closeButton>
                    <B.Modal.Title>Login</B.Modal.Title>
                </B.Modal.Header>
                <Loading active={this.props.isAccountLoading} className="loading-indicator"
                         normalOverlay={true} onlyOverlay={true}>
                    <LoginForm/>
                </Loading>
            </B.Modal>

            }
        </>;
    }
}

export default connect(
    state => {
        const {
            home: {menuItems, isLoading: isMenuLoading},
            account: {showLoginForm, showResetPasswordForm, loggedIn, isLoading: isAccountLoading},
            catalog: {isLoading: isCatalogLoading, isCategoriesLoading: isCatalogCategoriesLoading},
            searchFilter: {locations},
            order: {currentOrder},
            router
        } = state;

        return {
            ...state.searchFilter,
            menuItems,
            showLoginForm,
            showResetPasswordForm,
            loggedIn,
            isAccountLoading,
            isCatalogLoading,
            isCatalogCategoriesLoading,
            isMenuLoading,
            locationOptions: locations?.map(l => ({'value': l.id, 'label': l.name})),
            currentOrderItemsQty: currentOrder?.suppliers.reduce(
                (qty, supplier) => qty + supplier?.groups.reduce(
                    (qty, group) => qty + group?.positions.reduce(
                        (qty, position) => qty + position?.quantity ?? 0,
                        0
                    ) ?? 0,
                    0
                ) ?? 0,
                0
            ) ?? 0,
            router
        };
    },
    dispatch => {
        const {
            logout,
            openLoginForm,
            closeLoginForm
        } = accountApi;

        return bindActionCreators({
            ...searchApi,
            logout,
            openLoginForm,
            closeLoginForm
        }, dispatch);
    }
)(NavMenu);