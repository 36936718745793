import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api} from "../store/Profile";
import {Container, Button, Card, Alert} from "react-bootstrap";
import Loading from "./Base/Loading";
import Formsy from "formsy-react";
import ValidatedFormGroupControl from "./Base/ValidatedFormGroupControl";
import emailRegex from "./Base/EmailRegex";
import ValidatedFormGroupControlSelect from "./Base/ValidatedFormGroupControlSelect";
import {LocationNav} from "./LocationNav";

class LocationUser extends React.Component {
    initialState = {
        warehouses: undefined,
        submitted: false
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.onSupplierChange = this.onSupplierChange.bind(this);
        this.validate = this.validate.bind(this);
        this.submit = this.submit.bind(this);
        this.dismissErrors = this.dismissErrors.bind(this);
        this.resendResetPassword = this.resendResetPassword.bind(this);
        this.resendActivation = this.resendActivation.bind(this);
    }

    componentDidMount() {
        this.props.requestUser(this.props.match.params.idUser, this.props.getLocationIdFromParams(this.props.match.params));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const stateDiff = {};

        if (prevState.warehouses === undefined && nextProps.user?.preferredIdSupplier
            && nextProps.suppliers?.length > 0) {
            stateDiff.warehouses = nextProps.suppliers?.find(
                c => c.id === parseInt(nextProps.user.preferredIdSupplier)
            )?.warehouses ?? []
        }

        return stateDiff === {} ? null : stateDiff;
    }

    submit(model) {
        this.props.saveUser({...this.props.user, ...model, idLocation: this.props.getLocationIdFromParams(this.props.match.params), userType: "LocationManager"});
    }

    dismissErrors() {
        this.props.clearErrors();
    }

    validate() {
        this.setState({
            submitted: true
        });
    }

    onSupplierChange(value) {
        this.setState({
            warehouses: this.props.suppliers?.find(c => c.id === parseInt(value))?.warehouses ?? []
        })
    }

    resendResetPassword() {
        this.props.resendResetPassword(this.props.user?.publicId);
    }

    resendActivation() {
        this.props.resendActivation(this.props.user?.publicId);
    }

    render() {
        return (
            <Container className="registration-form-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    <Formsy onValidSubmit={this.submit} onSubmit={this.validate} validationErrors={this.props.errors}
                            autoComplete="off">
                        <Card className="registration-form-wrapper">
                            <Card.Header className="p-0">
                                <LocationNav idLocation={this.props.getLocationIdFromParams(this.props.match.params)}/>
                            </Card.Header>
                            <Card.Body className="registration-form">

                                {
                                    this.props.globalErrors && this.props.globalErrors.length > 0 ?
                                        <Alert variant="danger" dismissible onClose={this.dismissErrors}>
                                            <Alert.Heading>You've got an error!</Alert.Heading>
                                            <hr/>
                                            {this.props.globalErrors?.map(err => {
                                                return `${err}\n`;
                                            })}
                                        </Alert>
                                        : (!this.props.isLoading && this.props.successMessage ?
                                        <Alert variant="success">{this.props.successMessage}</Alert>
                                        : '')
                                }

                                <div className="registration-form-separator">
                                    <b/>
                                    <div className="registration-form-separator-text"><h2>Location Manager Details</h2></div>
                                    <b/>
                                </div>

                                <div className="registration-form-block">
                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="firstName"
                                                               type="text" label="First Name"
                                                               name="firstName"
                                                               value={this.props.user?.firstName}
                                                               validationError="First Name is required."
                                                               autoComplete="off" required />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="lastName"
                                                               type="text" label="Last Name"
                                                               name="lastName"
                                                               value={this.props.user?.lastName}
                                                               validationError="Last Name is required."
                                                               autoComplete="off" required />

                                    <ValidatedFormGroupControl showErrors={this.state.submitted}
                                                               id="email"
                                                               type="email" label="Email"
                                                               name="email"
                                                               value={this.props.user?.email}
                                                               validations={{matchRegexp: emailRegex}}
                                                               validationError="This is not a valid email"
                                                               autoComplete="off" required />

                                    <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                     id="preferredIdSupplier"
                                                                     label="Preferred Distributor"
                                                                     name="preferredIdSupplier"
                                                                     value={this.props.user?.preferredIdSupplier}
                                                                     onChange={this.onSupplierChange}
                                                                     autoComplete="off">
                                        <option key={0} value={0}>Select Distributor</option>
                                        {this.props.suppliers?.map((s, i) =>
                                            <option key={i+1} value={s.id}>{s.name}</option>
                                        )}
                                    </ValidatedFormGroupControlSelect>

                                    {
                                        this.state.warehouses?.length > 0 ?
                                            <ValidatedFormGroupControlSelect showErrors={this.state.submitted}
                                                                             id="preferredIdWarehouse"
                                                                             label="Preferred Warehouse"
                                                                             name="preferredIdWarehouse"
                                                                             value={this.props.user?.preferredIdWarehouse}
                                                                             autoComplete="off">
                                                <option key={0} value={0}>Select Warehouse</option>
                                                {this.state.warehouses?.map((wh, i) =>
                                                    <option key={i} value={wh.id}>{wh.name}</option>
                                                )}
                                            </ValidatedFormGroupControlSelect> : ''
                                    }
                                </div>
                            </Card.Body>
                            <Card.Footer className="registration-form-action-buttons">
                                <div><Button type="submit" variant="primary">SAVE</Button></div>
                                {!this.props.user?.publicId ? null :
                                    <div>
                                        {this.props.user?.status === 2 ?
                                            <Button variant="secondary" onClick={this.resendResetPassword}>Reset
                                                Password</Button>
                                            :
                                            <Button variant="secondary" onClick={this.resendActivation}>Resend
                                                Activation Email</Button>
                                        }
                                    </div>
                                }
                            </Card.Footer>
                        </Card>
                    </Formsy>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(api, dispatch)
)(LocationUser);