import React from "react";
import {Button, Card, Container, Form, Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import Loading from "../Base/Loading";
import {FormattedCurrency, FormattedDateOnly, FormattedPercent} from "../Base/Formatters";
import ProductDetails from "./ProductDetails";
import OrderStatusBadge from "./OrderStatusBadge";
import PopoverStickOnHover from "../Base/PopoverStickOnHover";
import {CopyToClipboard} from "react-copy-to-clipboard";

function ModalCard(props) {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide ?? (() => {
            })}
            size={props.size ?? 'xl'}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modal-container"
        >
            {props.children}
        </Modal>
    );
}

function ProductItem(props) {
    const {productView, products} = props;

    let product = undefined;
    let quantity = productView?.quantity;
    let price = undefined;
    if (productView?.idSku && products) {
        product = products[productView?.idSku];
        price = productView?.extCost / (productView.quantity ?? 1)
    }

    product = product ?? {};

    return (
        <div className="order-product-item">
            <div className="product-heading">
                <div>
                    <div className="order-brand-name text-muted">
                        {productView?.brandName}
                    </div>
                    <div className="order-product-name">
                        <button type="button" onClick={() => props.onShow(productView?.idSku)}
                                className="button-reset button-as-link">{productView?.productName}</button>
                    </div>
                </div>
            </div>
            <div className="order-product-item-line">
                <ModalCard
                    show={props.show.get(productView?.idSku)}
                    onHide={() => props.onHide(productView?.idSku)}
                >
                    <ProductDetails product={product} onHide={() => props.onHide(productView?.idSku)}/>
                </ModalCard>
                <div className="product-image" onClick={() => props.onShow(productView?.idSku)}>
                    <div className="image-container">
                        <img alt="" src={product?.thumbnail}/>
                    </div>
                </div>
                <div className="order-product-details order-field-font">
                    <div className="font-weight-bold">{productView?.skuCode}</div>
                    <div className="font-weight-bold">{productView?.supplierProductFieldValue}</div>
                    <div><span className="font-weight-bold">Size:</span> {productView?.size}</div>
                    <div><span className="font-weight-bold">UOM:</span> {productView?.uOMName}</div>
                    <div><span className="font-weight-bold">Unit Type:</span> {productView?.unitTypeName}</div>
                    <div><span className="font-weight-bold">Pack Size:</span> {productView?.packSize}</div>
                    <div><span className="font-weight-bold">SRP:</span> <FormattedCurrency
                        value={productView?.retailPrice}/></div>
                    <div><span className="font-weight-bold">WHS:</span> <FormattedCurrency
                        value={productView?.wholesalePrice}/></div>
                </div>
                <div className="order-product-line-wrapper">
                    <div className="discount-buttons">
                        {product?.hasCaseStack ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-CS">{product?.details?.csDescriptions?.map((item, index) => {
                                    return (
                                        <p key={index}>{item}</p>
                                    );
                                })}</Tooltip>}>
                                <Button variant="primary" size="sm"
                                        className="card-CS discount-button">CS</Button>
                            </OverlayTrigger>
                            : null}
                        {product?.hasNp ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-NP">{product?.details?.npDescription}</Tooltip>}>
                                <Button variant="dark" size="sm"
                                        className="card-NP discount-button">NP</Button>
                            </OverlayTrigger>
                            : null}
                        {productView?.resultOIPercent > 0 ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-OI"><p>{product?.details?.oiDescription}</p></Tooltip>}>
                                <Button variant="success" size="sm"
                                        className="card-OI discount-button">OI:<FormattedPercent
                                    value={productView?.resultOIPercent / 100}/></Button>
                            </OverlayTrigger>
                            : null}
                        {productView?.resultMCBPercent > 0 ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-MCB"><p>{product?.details?.mcbDescription}</p></Tooltip>}>
                                <Button variant="danger" size="sm"
                                        className="card-MCB discount-button">MCB:<FormattedPercent
                                    value={productView?.resultMCBPercent / 100}/></Button>
                            </OverlayTrigger>
                            : null}
                        {productView?.resultRBPPercent > 0 ?
                            <OverlayTrigger
                                placement="bottom"
                                trigger={['hover', 'click']}
                                delay={{show: 50, hide: 50}}
                                overlay={<Tooltip
                                    id="button-tooltip-RBP"><p>{product?.details?.rbpDescription}</p></Tooltip>}>
                                <Button variant="info" size="sm"
                                        className="card-RBP discount-button">RBP:<FormattedPercent
                                    value={productView?.resultRBPPercent / 100}/></Button>
                            </OverlayTrigger>
                            : null}
                    </div>
                    <div className="order-product-price-wrapper order-field-font">
                        <div className="order-product-price">
                            <Form.Group>
                                <Form.Label>Price</Form.Label>
                                <Form.Text as="span"><FormattedCurrency value={price}/></Form.Text>
                            </Form.Group>
                        </div>
                        <div className="order-product-qty">
                            <Form.Group>
                                <Form.Label>Quantity</Form.Label>
                                <Form.Text as="span" className="form-control-sm">{quantity}</Form.Text>
                            </Form.Group>
                        </div>
                        <div className="order-product-extprice">
                            <Form.Group>
                                <Form.Label>ExtCost</Form.Label>
                                <Form.Text as="span"><FormattedCurrency
                                    value={productView?.extCost}/></Form.Text>
                            </Form.Group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function WarehouseSection(props) {
    const {group, supplier, products, orderViewModel, openPdf} = props;

    return (
        <div className="order-warehouse-section">
            <div className="order-separator">
                <b/>
                <div className="order-separator-text"><h2>Start Order #{props.orderIndex}</h2></div>
                <b/>
            </div>
            <div className="order-warehouse-header">
                <div className="order-warehouse-name">
                    <div className="font-weight-bold">Distributor: <span
                        className="text-primary">{supplier.supplierName}</span></div>
                    <div className="font-weight-bold">Warehouse: <span
                        className="text-primary">{group.warehouseName}</span></div>
                </div>
                <div className="order-subtotals font-weight-bold">
                    Order Total: <FormattedCurrency value={group.totalDiscounted}/>
                    <PopoverStickOnHover
                        component={
                            <div className="d-flex">
                                <CopyToClipboard text={`https://${window.location.host}/api/Orders/DownloadOrderFile?id=${orderViewModel.id}&idWarehouse=${group.idWarehouse}`}>
                                    <Button className="badge-secondary">Copy Link&nbsp;<i className="far fa-copy" /></Button>
                                </CopyToClipboard>
                            </div>
                        }
                        placement="top"
                        onMouseEnter={() => { }}
                       delay={200}
                    >
                        <Button hidden={!group.pdfFileAvailable} className="order-pdf badge" type="button" variant="outline-danger"
                                onClick={() => openPdf(orderViewModel.id, group.idWarehouse)}><i
                            className="far fa-file-pdf"/></Button>
                    </PopoverStickOnHover>
                </div>
            </div>
            <div className="order-notice">*Totals reflect MCB and OI discounts. <wbr/>Totals may not include
                volume discounts offered by a Distributor. <wbr/>Discounts are subject to change without
                notice.
            </div>
            <div className="order-warehouse-fields">
                <div hidden={!supplier.supplierRep} className="mr-2">
                    Distributor Rep: <strong className="value">{supplier.supplierRep}</strong>
                </div>
                <div hidden={!supplier.accountNumber} className="mr-2">
                    Account: <strong className="value">{supplier.accountNumber}</strong>
                </div>
                <div hidden={!group.purchaseOrderNumber} className="mr-2">
                    PO Number: <strong className="value">{group.purchaseOrderNumber}</strong>
                </div>
            </div>
            <div className="order-warehouse-fields">
                <div hidden={!group.customerNotes}>
                    Order Notes: <div className="value">{group.customerNotes}</div>
                </div>
            </div>
            <div className="order-product-list">
                {group.positions?.map((productView, index) => {
                    return (
                        <ProductItem key={index} products={products} productView={productView} show={props.show}
                                     onHide={props.onHide} onShow={props.onShow}/>
                    );
                })}
            </div>
        </div>
    );
}

export default class OrderView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            productModalMap: new Map()
        };

        this.showProductDetails = this.showProductDetails.bind(this);
        this.setModalHide = this.setModalHide.bind(this);
        this.viewAll = this.viewAll.bind(this);
        this.editOrder = this.editOrder.bind(this);
        this.openPdf = this.openPdf.bind(this);
    }

    showProductDetails(id) {
        this.state.productModalMap.set(id, true);
        this.setState(state => state);
    }

    setModalHide(id) {
        this.state.productModalMap.set(id, false);
        this.setState(state => state);
    }

    viewAll() {
        this.props.viewOrders();
    }

    editOrder() {
        this.props.makeOrderActive(this.props.currentOrder.id);
    }

    openPdf(idOrder, idWarehouse) {
        this.props.downloadPdf(idOrder, idWarehouse);
    }

    render() {
        const {currentOrder: orderViewModel} = this.props;
        const salesRep = orderViewModel?.availableSalesReps?.filter(srep => srep.id === orderViewModel?.idSalesRep)[0]?.fullName;
        const locationAddress = orderViewModel?.locationAddress;
        let orderIndex = 0;

        return (
            <Container className="order-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    <Card className="no-border-radius">
                        <Card.Body>
                            <Form hidden={!orderViewModel}>
                                <div className="order-heading">
                                    <div className="order-section">
                                        <div className="left order-static-field-group">
                                            <div className="order-static-field"><OrderStatusBadge
                                                referenceData={this.props.referenceData}
                                                status={orderViewModel?.status}/></div>
                                            <div className="order-static-field">Order: <span
                                                className="value">{orderViewModel?.id}</span></div>
                                            <div className="order-static-field">Created Date: <span
                                                className="value"><FormattedDateOnly
                                                value={orderViewModel?.dateCreated}/></span></div>
                                            <div className="order-static-field">Account: <span
                                                className="value">{orderViewModel?.buyerName}</span>
                                            </div>
                                            <div className="order-static-field">Location: <span
                                                className="value">{orderViewModel?.locationName}</span>
                                            </div>
                                            <div className="order-static-field" hidden={!salesRep}>Sales Rep:
                                                <span className="value">{salesRep}</span>
                                            </div>
                                        </div>
                                        <div className="right order-static-field-group">
                                            <div
                                                className="order-static-field">{orderViewModel?.billingName} ({orderViewModel?.buyerEmail})
                                            </div>
                                            <div className="order-static-field">{locationAddress?.address1}</div>
                                            <div className="order-static-field"
                                                 hidden={!locationAddress?.address2}>{locationAddress?.address2}</div>
                                            <div
                                                className="order-static-field">{locationAddress?.city}, {locationAddress?.stateName} {locationAddress?.zip}</div>
                                            <div className="order-static-field"
                                                 hidden={!locationAddress?.phone}>Phone: {locationAddress?.phone}</div>
                                            <div className="order-static-field"
                                                 hidden={!locationAddress?.fax}>Fax: {locationAddress?.fax}</div>
                                        </div>
                                    </div>
                                    <div className="order-section">
                                        <div className="order-edit-fields">
                                            <div hidden={!orderViewModel?.customAuthorizedBy}>Authorized
                                                Buyer: <span
                                                    className="value">{orderViewModel?.customAuthorizedBy}</span></div>
                                            <div hidden={!orderViewModel?.startDate}>Start
                                                Date: <span className="value"><FormattedDateOnly
                                                    value={orderViewModel?.startDate}/></span></div>
                                        </div>
                                        <div className="order-subtotals mb-2">
                                            <div className="font-weight-bold">Combined Total Before
                                                Discounts*: <FormattedCurrency value={orderViewModel?.total}/></div>
                                            <div className="font-weight-bold">Combined Total After
                                                Discounts*: <FormattedCurrency
                                                    value={orderViewModel?.totalDiscounted}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    orderViewModel?.suppliers?.map(
                                        (supplier, index) => supplier.groups?.map((group) => {
                                            orderIndex++;
                                            return (
                                                <WarehouseSection key={orderIndex} products={this.props.products}
                                                                  orderViewModel={orderViewModel}
                                                                  supplier={supplier} group={group}
                                                                  show={this.state.productModalMap}
                                                                  onHide={this.setModalHide}
                                                                  onShow={this.showProductDetails}
                                                                  orderIndex={orderIndex}
                                                                  openPdf={this.openPdf}
                                                />
                                            );
                                        })
                                    )
                                }
                            </Form>
                        </Card.Body>
                        <Card.Footer className="order-action-buttons">
                            <div hidden={orderViewModel?.status !== 1}>
                                <Button variant="primary" onClick={this.editOrder}><i
                                    className="far fa-check-square"/><span
                                    className="ml-1">SELECT</span></Button>
                            </div>
                            <div>
                                <Button variant="success" onClick={this.viewAll}><i className="fas fa-th-list"/><span
                                    className="ml-1">SHOW ALL</span></Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </Loading>
            </Container>
        );
    }
}