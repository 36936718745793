import LoadingOverlay from 'react-loading-overlay'
import {Spinner} from "react-bootstrap";
import React from "react";

export default function Loading({ active, children, className, normalOverlay, fadeSpeed, onlyOverlay }) {
    return (
        <LoadingOverlay
            fadeSpeed={fadeSpeed ? fadeSpeed : 200}
            active={active}
            spinner={onlyOverlay ? undefined : <Spinner variant="info" animation="border" size="xl" />}
            className={className}
            styles={normalOverlay ? {
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(255, 255, 255, 0.6)',
                    minHeight: '100%'
                })
            } : {
                overlay: (base) => ({
                    ...base,
                    background: 'rgba(255, 255, 255, 0.6)',
                    minHeight: '100%'
                }),
                content: () => ({
                    position: 'fixed',
                    left: '50%',
                    top: '50%'
                })
            }}
        >
            {children}
        </LoadingOverlay>
    )
};
