/*eslint react/style-prop-object: "off"*/

import React from "react";
import {FormattedDate, FormattedNumber} from "react-intl";

export const FormattedCurrency = (props) => (
    <FormattedNumber value={props.value} style="currency" currency="USD" maximumFractionDigits={2}
                     minimumFractionDigits={2}/>
);

export const FormattedPercent = (props) => (
    <FormattedNumber value={props.value} style="percent" minimumFractionDigits={0} maximumFractionDigits={2}/>
);

export const FormattedDateOnly = (props) => (
    <FormattedDate value={props.value}/>
);