import React from "react";
import {animateScroll} from 'react-scroll';
import PropTypes from "prop-types";

class ScrollToTop extends React.Component {
    initialState = {
        isVisible: false,
    };

    constructor(props) {
        super(props);

        this.state = this.initialState;

        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        const scrollToTopComponent = this;
        document.addEventListener("scroll", e => scrollToTopComponent.toggleVisibility());
    }

    toggleVisibility() {
        if (window.pageYOffset > window.innerHeight / 3) {
            this.setState({
                isVisible: true
            });
        } else {
            this.setState({
                isVisible: false
            });
        }
    }

    scrollToTop() {
        animateScroll.scrollToTop({offset: 0, smooth: true});
    }

    render() {
        return (
            <div onClick={this.scrollToTop} title="Scroll to top"
                 className={"scroll-to-top" + (this.state.isVisible ? " visible" : "") + (this.props.className ? " " + this.props.className : "")}>
                <i className="fas fa-5x fa-caret-square-up"/>
            </div>
        );
    }
}

ScrollToTop.propTypes = {
    className: PropTypes.string,
};

export default ScrollToTop;