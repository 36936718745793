import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {api as profileApi, createFilterPrototype, ensureOrdersFilter} from "../store/Profile";
import {Container, Button, Card, Form, InputGroup, FormControl} from "react-bootstrap";
import {FormattedDateOnly} from "./Base/Formatters";
import OrderStatusBadge from "./Order/OrderStatusBadge";
import Loading from "./Base/Loading";
import DateInput from "./Base/DatePicker";

class OrderListItem extends React.PureComponent {
    constructor(props) {
        super(props);

        this.viewOrder = this.viewOrder.bind(this);
        this.editOrder = this.editOrder.bind(this);
    }

    viewOrder() {
        this.props.viewOrder(this.props.order.id);
    }

    editOrder() {
        this.props.editOrder(this.props.order.id);
    }

    render() {
        const o = this.props.order;
        const referenceData = this.props.referenceData;

        return (
            <div className="order-list-item">
                <div className="order-list-value id">{o.id}</div>
                <div className="order-list-value status"><OrderStatusBadge
                    referenceData={referenceData} status={o.status}/></div>
                <div className="order-list-value created">
                    <div>Created:</div>
                    <div><FormattedDateOnly
                        value={o.dateCreated}/></div>
                </div>
                <div className="order-list-value submitted">
                    <div hidden={!o.dateSubmitted}>Submitted:</div>
                    <div hidden={!o.dateSubmitted}><FormattedDateOnly
                        value={o.dateSubmitted}/></div>
                </div>
                <div className="order-list-value location d-none d-sm-flex">
                    <div>Location:</div>
                    <div>{o.locationName}</div>
                </div>
                <div className="order-list-value actions">
                    <div>
                        <Button hidden={o.status !== 1}
                                className="mr-2"
                                variant="primary"
                                onClick={this.editOrder}><i
                            className="far fa-check-square"/><span className="ml-1">Select</span>
                        </Button>
                        <Button variant="success" onClick={this.viewOrder}>
                            <i className="far fa-eye"/><span className="ml-1">View</span>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

class OrderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.viewOrder = this.viewOrder.bind(this);
        this.editOrder = this.editOrder.bind(this);
        this.findById = this.findById.bind(this);
        this.findByFilter = this.findByFilter.bind(this);
        this.changeId = this.changeId.bind(this);
        this.changeDistributor = this.changeDistributor.bind(this);
        this.changeDateFrom = this.changeDateFrom.bind(this);
        this.changeDateTo = this.changeDateTo.bind(this);
        this.changeStatus = this.changeStatus.bind(this);
    }

    componentDidMount() {
        const filter = ensureOrdersFilter(this.props.filter);
        filter.paging.pageIndex = 1;
        this.props.requestOrderList(filter);
    }

    viewOrder(id) {
        this.props.viewOrder(id);
    }

    editOrder(id) {
        this.props.makeOrderActive(id);
    }

    changeId(event) {
        this.props.filterChangeId(parseInt(event.currentTarget.value));
    }

    findById(event) {
        event.preventDefault();
        
        const filter = createFilterPrototype(false);
        filter.id = this.props.filter?.id;
        this.props.requestOrderList(filter);
    }

    findByFilter(event) {
        event.preventDefault();
        
        const filter = ensureOrdersFilter(this.props.filter);
        filter.id = undefined;
        this.props.requestOrderList(filter);
    }

    changeDistributor(event) {
        this.props.filterChangeDistributor(parseInt(event.currentTarget.value));
    }

    changeDateFrom(date) {
        this.props.filterChangeDateFrom(date);
    }

    changeDateTo(date) {
        this.props.filterChangeDateTo(date);
    }

    changeStatus(event) {
        this.props.filterChangeStatus(parseInt(event.currentTarget.value));
    }

    render() {
        return (
            <Container className="order-list-container">
                <Loading active={this.props.isLoading} normalOverlay={true}>
                    <div className="order-empty-notice">
                        <h1>Order History</h1>
                    </div>
                    <div className="order-list-filter">
                        <Form onSubmit={this.findById}>
                            <InputGroup className="order-number-search">
                                <FormControl type="number" placeholder="Order #"
                                             value={this.filter?.id}
                                             onChange={this.changeId}/>
                                <InputGroup.Append>
                                    <Button type="submit" variant="primary" onClick={this.findById} disabled={!this.props.filter?.id || this.props.filter?.id <= 0}><i
                                        className="fas fa-search"/><span className="ml-1 d-none d-sm-inline">FIND</span></Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>
                        <Form onSubmit={this.findByFilter}>
                            <Form.Group>
                                <Form.Label>From</Form.Label>
                                <DateInput value={this.props.filter?.dateFrom} onChange={this.changeDateFrom} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>To</Form.Label>
                                <DateInput value={this.props.filter?.dateTo} onChange={this.changeDateTo} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control as="select" value={this.props.filter?.status}
                                              onChange={this.changeStatus}>
                                    <option key={0} value={0}>All Statuses</option>
                                    {this.props.referenceData?.orderStatuses.map((status) => {
                                        return <option key={status.key}
                                                       value={status.key}>{status.text}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Distributor</Form.Label>
                                <Form.Control as="select" value={this.props.filter?.idSupplier}
                                              onChange={this.changeDistributor}>
                                    <option key={0} value={undefined}>All Distributors</option>
                                    {this.props.distributors?.map((dist) => {
                                        return <option key={dist.id}
                                                       value={dist.id}>{dist.name}</option>;
                                    })}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group className="d-flex justify-content-end align-items-end">
                                <Button type="submit" variant="primary" className="ml-3" onClick={this.findByFilter}><i
                                    className="fas fa-search"/><span className="ml-1">SEARCH</span></Button>
                            </Form.Group>
                        </Form>
                    </div>
                    <hr />
                    <div hidden={this.props.isLoading || !(this.props.orderList?.length === 0)} className="order-empty-notice">
                        <h1>No results.</h1>
                    </div>
                    <div className="order-list-items">
                        {this.props.orderList?.map((o, index) =>
                            <OrderListItem key={index} referenceData={this.props.referenceData} order={o}
                                           viewOrder={this.viewOrder} editOrder={this.editOrder} />
                        )}
                        <Card className="order-list-item border-0 rounded-0 p-0 m-0"
                              key={this.props.orderList?.length ?? 0} hidden={!this.props.hasMore}>
                            <Button variant="secondary" className="btn-load-more"
                                    onClick={this.props.requestOrderListNextPage}><span
                                className="baseline-text">Load More Orders</span><i
                                className="far fa-plus-square fa-2x"/></Button>
                        </Card>
                    </div>
                </Loading>
            </Container>
        );
    }
}

export default connect(
    (state) => ({
        ...state.profile,
        distributors: state.searchFilter.distributors
    }),
    dispatch => bindActionCreators(profileApi, dispatch)
)(OrderList);