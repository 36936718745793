import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {AccountClient, ForgotPasswordModel, LoginModel, ResetPasswordModel} from "../api/clients";
import {api as searchApi} from "../store/SearchFilter";
import {push} from "connected-react-router";
import {BaseErrorsContainer} from "./Base/BaseInterfaces";
import {processBackendCall, processErrors} from "./Base/Backend";
import {parse} from "query-string";
import localStorage from "./localStorage";

interface state extends BaseErrorsContainer {
    successMessage: string | undefined,
    isLoading: boolean,
    showLoginForm: boolean,
    showResetPasswordForm: boolean,
    showResendForgotPasswordEmailButton: boolean,
    token: string | undefined,
    email: string | undefined,
    loggedIn: boolean
}

const initialState: state = {
    successMessage: undefined,
    success: true,
    errors: null,
    globalErrors: undefined,
    showLoginForm: false,
    showResetPasswordForm: false,
    showResendForgotPasswordEmailButton: false,
    token: undefined,
    email: undefined,
    loggedIn: true,
    isLoading: false
};

const accountSlice = createSlice({
    name: "account",
    initialState: initialState,
    reducers: {
        requestLogin: (state: Draft<state>) => {
            state.isLoading = true;
            state.success = false;
            state.successMessage = undefined;
            state.errors = undefined;
            state.globalErrors = undefined;
        },
        receiveLoginOrResetPasswordResults: (state: Draft<state>) => {
            state.errors = undefined;
            state.globalErrors = undefined;
            state.loggedIn = true;
            state.showResetPasswordForm = false;
            state.showLoginForm = false;
            state.token = undefined;
            state.isLoading = false;        },
        receiveSuccessResult: (state: Draft<state>, action: PayloadAction<string | undefined>) => {
            state.errors = undefined;
            state.globalErrors = undefined;
            state.success = true;
            state.successMessage = action.payload;
            state.isLoading = false;
        },
        receiveErrorResult: (state: Draft<state>, action: PayloadAction<BaseErrorsContainer | undefined>) => {
            state.errors = action.payload?.errors;
            state.globalErrors = action.payload?.globalErrors;

            if (action.payload && !action.payload?.success
                && (!state.errors || state.errors.length === 0)
                && (!state.globalErrors || state.globalErrors.length === 0)) {
                state.globalErrors = ["Something unexpected happened."];
            }

            state.isLoading = false;
        },
        resetLoginState: (state: Draft<state>) => {
            state.loggedIn = false;
        },
        openLoginForm: (state: Draft<state>) => {
            state.loggedIn = false;
            state.showLoginForm = true;
        },
        closeLoginForm: (state: Draft<state>) => {
            state.showLoginForm = false;
        },
        openResetPasswordForm: (state: Draft<state>,
                                action: PayloadAction<{email: string | undefined, token: string | undefined}>) => {
            state.loggedIn = false;
            state.showResetPasswordForm = true;

            if (action.payload.email) {
                state.email = action.payload.email;

                if (action.payload.token) {
                    state.token = action.payload.token;
                } else {
                    state.showResendForgotPasswordEmailButton = true
                }
            }
        },
        closeResetPasswordForm: (state: Draft<state>) => {
            state.showResetPasswordForm = false;
            state.showResendForgotPasswordEmailButton = false;
            state.token = undefined;
            state.email = undefined;
        },
        showResendForgotPasswordEmailButtonAction: (state: Draft<state>) => {
            state.showResendForgotPasswordEmailButton = true;
        },
        clearErrors: (state: Draft<state>) => {
            state.globalErrors = undefined;
        },
        clearSuccessMessage: (state: Draft<state>) => {
            state.successMessage = undefined;
        },
        logout: (state: Draft<state>) => {
            state.loggedIn = false;
        },
    }
});

export const {reducer} = accountSlice;
const {actions} = accountSlice;

export const api = {
    ...actions,
    requestLogin: (model: LoginModel) => async (dispatch: any) => {
        dispatch(actions.requestLogin());

        return await processBackendCall(
            async _ => {
                const result = await new AccountClient().login(model);

                if (result.messages?.length === 1 && result.messages[0].code === '0') {
                    result.success = true;
                }

                return result;
            },
            result => dispatch(result.messages?.length !== 1 || result.messages[0].code !== '0' ?
                api.login() : actions.receiveSuccessResult('Account Currently Locked. ' +
                    'Account activation successful. No further action needed by you at this time. Your account is in locked pending review. Reviews generally take up to one business day. You will receive a follow-up email once this has been completed letting you know that all is good or if there is any other information we may need to activate the account.'
                )),
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages))
        );
    },
    requestForgotPasswordEmail: (model: ForgotPasswordModel) => async (dispatch: any) => {
        dispatch(actions.requestLogin());

        return await processBackendCall(
            _ => new AccountClient().forgotPassword(model),
            _ => dispatch(actions.receiveSuccessResult("Recover password email was successfully sent to your address.")),
            messages => dispatch(processErrors(data => actions.receiveErrorResult(data), messages))
        );
    },
    resetPassword: (model: ResetPasswordModel) => async (dispatch: any) => {
        dispatch(actions.requestLogin());

        return await processBackendCall(
            _ => new AccountClient().resetPassword(model),
            _ => dispatch(api.login()),
            messages => {
                if (messages?.filter(m => m.code === "1")?.length) {
                    dispatch(actions.showResendForgotPasswordEmailButtonAction());
                }

                return dispatch(processErrors(data => actions.receiveErrorResult(data), messages));
            });
    },
    receiveForgotPasswordData: (router: any) => async (dispatch: any) => {
        const params = router?.location?.search;

        if (params) {
            const paramsParsed = parse(params);

            if (paramsParsed["pid"] && paramsParsed["token"]) {
                const token = paramsParsed["token"] as string;
                dispatch(actions.requestLogin());

                return await processBackendCall(
                    async _ => {
                        const result = await new AccountClient().getForgotPasswordData(token);

                        if (result.messages?.length === 1 && result.messages[0].code === '0') {
                            result.success = true;
                        }

                        return result;
                    },
                    result => {
                        dispatch(actions.openResetPasswordForm({
                            email: result?.data?.email,
                            token: token
                        }));

                        return dispatch(actions.receiveSuccessResult(
                            result.messages?.length !== 1 || result.messages[0].code !== '0' ? '' :
                                'Account Currently Locked. ' +
                                'Account activation successful. No further action needed by you at this time. ' +
                                'Your account is in locked pending review. Reviews generally take up to one ' +
                                'business day. You will receive a follow-up email once this has been ' +
                                'completed letting you know that all is good or if there is any other ' +
                                'information we may need to activate the account.'
                        ));
                    },
                    messages => {
                        dispatch(actions.openLoginForm());

                        return dispatch(processErrors(data => actions.receiveErrorResult(data), messages)); 
                    });
            }
        }
    },
    login: (isNeedBackendSignIn: boolean = false) => async (dispatch: any) => {
        dispatch(searchApi.requestFilterData(undefined));
        dispatch(push("/"));

        return dispatch(actions.receiveLoginOrResetPasswordResults());
    },    
    logout: () => async (dispatch: any) => {
        dispatch(actions.logout());
        await new AccountClient().logout();
        localStorage.removeItem("searchFilter");
        localStorage.removeItem("orderingContext");
        window.location.reload();
    },
};